import React, { Children, useState } from "react";
import { Grid, Icon, TextField, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface SettingInfoCardProps {
  title: string;
  data: any;
  type: string;
  xs: number;
  md: number;
}

const SettingInfoCard = (props: SettingInfoCardProps) => {
  const [icon, setIcon] = useState(<RemoveRedEyeIcon />);
  const [hidePw, setHidePw] = useState("Password");
  const { title, data, type, xs, md } = props;

  const handleShowToggle = () => {
    if (type === "password") {
      const visibilityEye: any = <VisibilityOffIcon />;
      setIcon(visibilityEye);
      setHidePw(data);
    }
    if (hidePw !== "Password") {
      const removeEye: any = <RemoveRedEyeIcon />;
      setIcon(removeEye);
      setHidePw("Password");
    }
  };

  return (
    <Grid
      component="div"
      item
      xs={xs}
      md={md}
      sx={{
        width: "100%",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          type="text"
          id={title}
          label={title}
          name={title}
          defaultValue={data}
          sx={{
            width: "100%",
          }}
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default SettingInfoCard;
