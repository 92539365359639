import { MessageList } from "react-chat-elements";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useEffect, useState } from "react";
import { getUserMessages } from "../../store/thunks/messagesThunk";

const MessageBoxComponent = (props) => {
  const dispatch = useAppDispatch();
  const { sentMessage, currentUserMessageList } = useAppSelector(
    (state) => state.messages
  );

  const { userInfo } = useAppSelector((state) => state.authentication);

  const [currentMessages, setCurrentMessages] = useState([]);

  const initialMessagesSetup = (initMessageInfo) => {
    let tempMessages = [];
    let tempUserMessagePayload = {
      user_id: userInfo.user_id,
      message_list_id: initMessageInfo.id,
    };

    dispatch(getUserMessages({ get_message_payload: tempUserMessagePayload }))
      .then((result) => {
        if (result?.payload?.user_messages) {
          result?.payload?.user_messages.forEach((element) => {
            let position, title;
            if (userInfo.user_id === element.sender_id) {
              position = "left";
            } else {
              position = "right";
            }
            let temp = {
              id: element.message_id,
              position: position,
              type: "text",
              title: `${element.sender_first_name} ${element.sender_last_name}`,
              text: element.message_content,
              info: element,
            };
            tempMessages.push(temp);
          });
          setCurrentMessages(tempMessages);
        }
      })
      .catch((error) => {
        displayConsoleError("getUserMessages", error);
      });
  };

  useEffect(() => {
    initialMessagesSetup(currentUserMessageList);
  }, [currentUserMessageList, sentMessage]);

  useEffect(() => {
    let timer = setInterval(() => {
      initialMessagesSetup(currentUserMessageList);
    }, 1000 * 10);

    return () => {
      clearInterval(timer);
    };
  }, [currentUserMessageList]);

  return (
    <MessageList
      className="message-list"
      placeholder="Type here..."
      lockable={true}
      toBottomHeight={"100%"}
      dataSource={currentMessages}
    />
  );
};

export default MessageBoxComponent;
