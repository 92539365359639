import React from "react";
import { Form, Formik, useFormik } from "formik";
import { Box, Button, Grid, TextField } from "@mui/material";

import { useAppDispatch } from "../../../../hooks/hooks";
import { editUserAddress } from "../../../../store/thunks/addressThunk";
import { LVLpmThemeButton } from "../../../../utilities/constants";

interface AddressEditFormProps {
  addressInfo: any;
}

const AddressEditForm = (props: AddressEditFormProps) => {
  const dispatch = useAppDispatch();
  const { addressInfo } = props;

  const initialValues = {
    address_id: addressInfo.address_id,
    street_1: addressInfo.street_1,
    street_2: addressInfo.street_2,
    city: addressInfo.city,
    state: addressInfo.state,
    zip_code: addressInfo.zip_code,
    country: addressInfo.country,
  };
  const onSubmit = (values: any) => {
    dispatch(editUserAddress({ values })).then(() => {
      location.reload();
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="row" spacing={2} sx={{ padding: "30px" }}>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="street_1"
            label="Address 1"
            name="street_1"
            value={formik.values.street_1}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="street_2"
            label="Address 2"
            name="street_2"
            value={formik.values.street_2}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="city"
            label="City"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="state"
            label="State"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="zip_code"
            label="Zipcode"
            name="zip_code"
            value={formik.values.zip_code}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="country"
            label="Country"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            sx={{
              width: "100%",
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ padding: " 30px" }}>
        <LVLpmThemeButton type="submit" variant="contained" color="success">
          Edit Address
        </LVLpmThemeButton>
      </Box>
    </form>
  );
};

export default AddressEditForm;
