import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PieStatChart from "./PieChart";
import { Box, Grid } from "@mui/material";
import RoundChart from "./RoundChart";

interface BookStatCardProps {
  title: string;
}

const BookStatPieChart = (props: BookStatCardProps) => {
  const { title } = props;

  return (
    <Card>
      <CardContent sx={{ backgroundColor: "#42A7C3" }}>
        <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
          {title}
        </Typography>
      </CardContent>
      <CardContent>
        <RoundChart />
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FCFCFC",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#00B9D1",
                  width: "15px",
                  height: "150px",
                  borderRadius: "15px 0 0 15px",
                  marginRight: "35px",
                }}
              ></Box>
              <Box>
                <Typography variant="body1" sx={{ color: "#7A7A7A" }}>
                  Occupancy Rate
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  88.9%
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FCFCFC",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#00B9D1",
                  width: "15px",
                  height: "150px",
                  borderRadius: "15px 0 0 15px",
                  marginRight: "35px",
                }}
              ></Box>
              <Box>
                <Typography variant="body1" sx={{ color: "#7A7A7A" }}>
                  Cancellation Rate
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  6%
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BookStatPieChart;
