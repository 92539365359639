import React from "react";
import FormInput from "./FormInput";
import SelectForm from "./SelectForm";
import FormRadio from "./FormRadio";
import FormTextArea from "./FormTextArea";
import FormNumber from "./FormNumber";
import FormCheckbox from "./FormCheckbox";

const FormControl = (props: any) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <FormInput {...rest} />;
    case "select":
      return <SelectForm {...rest} />;
    case "radio":
      return <FormRadio {...rest} />;
    case "textarea":
      return <FormTextArea {...rest} />;
    case "number":
      return <FormNumber {...rest} />;
    case "checkbox":
      return <FormCheckbox {...rest} />;
    default:
      return null;
  }
};

export default FormControl;
