import React, { useContext, useEffect, useState } from "react";
import {
  Elements,
  AddressElement,
  CardElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import dayjs from "dayjs";
import {
  cancelPaymentIntent,
  paymentIntent,
} from "../../../store/thunks/paymentThunk";
import Spinner from "../../reusableComponents/spinner/spinner";
import CheckoutForm from "./checkoutForm";
import checkoutImage from "./../../../assets/checkout.png";
import { resetPayment } from "../../../store/features/payment/paymentSlice";
import { useNavigate } from "react-router-dom";
import { displayConsoleError } from "../../../utilities/helperFunctions";

interface PaymentDialogProps {
  open: boolean;
  handleClose: () => void;
  stayPrice: string;
  cleaningFees: string;
  totalPrice: string;
  nights: string;
  taxes: string;
  pricePerDay: string;
  lvlpmFee: string;
  guests: number;
}

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    open,
    handleClose,
    stayPrice,
    cleaningFees,
    totalPrice,
    nights,
    taxes,
    pricePerDay,
    lvlpmFee,
    guests,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripePromise, setStripePromise] = useState<any>(null);

  const { selectedPropertyDetails, currentDestination } = useAppSelector(
    (state: any) => state.listings
  );
  const { success } = useAppSelector((state: any) => state.payments);
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const handleClosePaymentDialog = (event: any) => {
    if (event === "" && paymentIntentId)
      dispatch(cancelPaymentIntent({ paymentIntent: paymentIntentId }));
    if (success) dispatch(resetPayment());
    setPaymentIntentId("");
    setClientSecret("");
    dispatch(resetPayment());
    handleClose();
  };

  useEffect(() => {
    if (totalPrice) {
      dispatch(
        paymentIntent({
          amount: Number(totalPrice),
          stripe_customer_id: userInfo?.stripe_customer_id,
        })
      )
        .then((result: any) => {
          if (result?.payload?.clientSecret) {
            setClientSecret(result.payload.clientSecret);
            setPaymentIntentId(result.payload.paymentIntentId);
            setStripePromise(loadStripe(result.payload.stripePromise));
          }
        })
        .catch((error) => {
          displayConsoleError("paymentIntent", error);
        });
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Confirmation & Payment
        </DialogTitle>
        {!success && (
          <IconButton
            aria-label="close"
            onClick={() => {
              if (!success) handleClosePaymentDialog("");
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
      <DialogContent>
        <Grid container item xs={12}>
          <Grid item md={6} xs={12} style={{}}>
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: "10px 20px 0px 20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6} sx={{}}>
                <TextField
                  label="Dates"
                  value={
                    dayjs(currentDestination?.checkIn).format("ddd MMM DD") +
                    " - " +
                    dayjs(currentDestination?.checkOut).format("ddd MMM DD")
                  }
                  disabled
                  size="small"
                  style={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Guests"
                  value={currentDestination?.guests}
                  disabled
                  size="small"
                />
              </Grid>
            </Grid>
            <Paper sx={{ margin: 2 }} elevation={3}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 2,
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${
                      selectedPropertyDetails?.property_images_url?.split(
                        ","
                      )[0]
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: 2,
                  }}
                ></Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 0px 0px 5px",
                  }}
                >
                  <Grid item>
                    <Typography sx={{ fontSize: 12 }}>
                      {selectedPropertyDetails?.property_type_label} in{" "}
                      {selectedPropertyDetails?.city}
                      {", "}
                      {selectedPropertyDetails?.state
                        ? selectedPropertyDetails?.state
                        : selectedPropertyDetails?.country}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {selectedPropertyDetails?.property_name}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid item>
                    <Grid item xs={12}>
                      <Typography sx={{}}>
                        {selectedPropertyDetails?.guests} guests *{" "}
                        {selectedPropertyDetails?.bedrooms} beds *{" "}
                        {selectedPropertyDetails?.bathrooms} bathrooms
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <StarIcon sx={{ color: "gold" }} />
                      {selectedPropertyDetails?.review
                        ? selectedPropertyDetails?.review
                        : 5.0}{" "}
                      (325 reviews)
                    </Grid>
                  </Grid> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", margin: "10px 0px 10px 0px" }}
                  >
                    Price Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>
                    {selectedPropertyDetails?.state === "CA"
                      ? selectedPropertyDetails?.price_per_day
                      : pricePerDay}{" "}
                    x {nights} nights
                  </Grid>
                  <Grid>$ {stayPrice}</Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>Entire Stay Cleaning Fee</Grid>
                  <Grid>${cleaningFees}</Grid>
                </Grid>
                {selectedPropertyDetails?.state === "CA" && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "0px 0px 5px 0px",
                    }}
                  >
                    <Grid>LVLPm Fee (.033%)</Grid>
                    <Grid>$ {lvlpmFee}</Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid>Taxes</Grid>
                  <Grid>$ {taxes}</Grid>
                </Grid>
                <Divider sx={{ width: "100%", margin: "0px 0px 5px 0px" }}>
                  {" "}
                </Divider>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <Grid sx={{ fontWeight: "bold" }}>Total (USD)</Grid>
                  <Grid sx={{ fontWeight: "bold" }}>$ {totalPrice}</Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Free cancelation until 3:00 PM on July 15, More info
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {!success ? (
            <Grid item md={6} xs={12} sx={{}}>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    handleClose={(event) => handleClosePaymentDialog(event)}
                    totalPrice={totalPrice}
                    taxes={taxes}
                    lvlFee={lvlpmFee}
                    stayPrice={stayPrice}
                    pricePerDayNonCa={pricePerDay}
                    paymentIntentId={paymentIntentId}
                    nights={nights}
                    guests={guests}
                  />
                </Elements>
              )}
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "5%",
              }}
            >
              <Grid sx={{}}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundImage: `url(${checkoutImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "200px",
                      zIndex: 1,
                    }}
                  ></Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    All done, you are going to {selectedPropertyDetails?.city}
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 3,
                      margin: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        dispatch(resetPayment());
                        // handleClose()
                        navigate("/dashboard");
                      }}
                    >
                      Dashboard
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        dispatch(resetPayment());
                        navigate("/listings");
                      }}
                    >
                      Listings
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default PaymentDialog;
