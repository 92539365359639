import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterInfo } from "../../types/formsTypes";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Typography,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  styled,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerUser } from "../../store/thunks/authenticationThunk";
import logo from "../../assets/logo_transparent.png";
import GoogleLogin from "react-google-login";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { reset } from "../../store";

const LVLpmThemeButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#00B9D1",
  "&:hover": {
    backgroundColor: "#0094a7",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#00a6bc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  width: "100%",
  marginTop: "10px",
});

const Register = () => {
  const { registerLoading, error, loggedIn, response, success } =
    useAppSelector((state: any) => state.authentication);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [navigate, loggedIn]);

  const validateLogin = Yup.object({
    first_name: Yup.string().required("Fist Name is Required"),
    last_name: Yup.string().required("Last name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(50, "Password is too Long - should be 50 chars maximum.")
      .required("Password is required")
      .matches(
        /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/,
        "Must contain At least One Upercase, At least One special character, At least One digit."
      ),
    passwordMatch: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
  });

  let registerInitialValues: RegisterInfo = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    passwordMatch: "",
  };

  const registerForm = useFormik({
    initialValues: registerInitialValues,
    validationSchema: validateLogin,
    onSubmit: (values: RegisterInfo) => {
      const registrationPayload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.toLowerCase(),
        password: values.password,
        passwordMatch: values.passwordMatch,
      };
      dispatch(registerUser(registrationPayload)).then((result: any) => {
        registerForm.resetForm();
      });
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          paddingTop: "5%",
        }}
      >
        <Spinner loading={registerLoading} />
        <Grid
          // item
          // xs={5}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "stretch",
            maxWidth: "1000px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              alignContent: "stretch",
            }}
          >
            <Grid item xs={6} sx={{ padding: 3 }}>
              <form onSubmit={registerForm.handleSubmit}>
                <Grid
                  container
                  sx={{
                    gap: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" sx={{}}>
                      Create an Account
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="First Name"
                      label="First Name"
                      id="first_name"
                      name="first_name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.first_name}
                      error={
                        registerForm.touched.first_name &&
                        Boolean(registerForm.errors.first_name)
                      }
                      helperText={registerForm.errors.first_name}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Last Name"
                      name="last_name"
                      label="Last Name"
                      variant="filled"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.last_name}
                      error={
                        registerForm.touched.last_name &&
                        Boolean(registerForm.errors.last_name)
                      }
                      helperText={registerForm.errors.last_name}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <TextField
                      sx={{}}
                      placeholder="Email"
                      name="email"
                      variant="filled"
                      label="Email"
                      inputProps={{}}
                      fullWidth
                      value={registerForm.values.email}
                      error={
                        registerForm.touched.email &&
                        Boolean(registerForm.errors.email)
                      }
                      helperText={registerForm.errors.email}
                      onChange={registerForm.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <Typography>Password</Typography>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      label=""
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                      name="password"
                      fullWidth
                      sx={{}}
                      value={registerForm.values.password}
                      error={
                        registerForm.touched.password &&
                        Boolean(registerForm.errors.password)
                      }
                      color={"primary"}
                      onChange={registerForm.handleChange}
                    />
                    {registerForm.touched.password &&
                      Boolean(registerForm.errors.password) && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "red",
                          }}
                        >
                          <Typography>
                            {registerForm.errors.password}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                    <Typography>Confirm Password</Typography>
                    <OutlinedInput
                      id="outlined-adornment-confirm-password"
                      type={showPassword ? "text" : "password"}
                      label=""
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Confirm Password"
                      name="passwordMatch"
                      fullWidth
                      sx={{}}
                      value={registerForm.values.passwordMatch}
                      error={
                        registerForm.touched.passwordMatch &&
                        Boolean(registerForm.errors.passwordMatch)
                      }
                      color={"primary"}
                      onChange={registerForm.handleChange}
                    />
                    {registerForm.touched.passwordMatch &&
                      Boolean(registerForm.errors.passwordMatch) && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            color: "red",
                          }}
                        >
                          <Typography>
                            {registerForm.errors.passwordMatch}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  {error && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "red",
                      }}
                    ></Grid>
                  )}
                  <Grid item xs={12} sx={{}}>
                    <LVLpmThemeButton
                      variant="contained"
                      onClick={() => registerForm.submitForm}
                      type="submit"
                    >
                      Sign Up
                    </LVLpmThemeButton>
                  </Grid>
                  <Grid item xs={12} sx={{}}></Grid>
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CBF6FF",
                height: "100%",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ maxHeight: "300px" }} src={logo} alt={"Test"} />
              </Grid>
            </Grid>
          </Grid>
          {success && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                color: "black",
                padding: "20px",
              }}
            >
              {response?.message}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Register;
