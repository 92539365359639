import * as React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import { Field } from "formik";

interface FormCheckboxProps {
  label: string;
  name: string;
}

const FormRadio = (props: FormCheckboxProps) => {
  const { label, name } = props;
  return (
    <FormGroup>
      <FormLabel
        htmlFor={name}
        sx={{ fontWeight: "bold", marginBottom: "10px" }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={name}
        name={name}
        row
        sx={{ marginBottom: "25px" }}
      >
        <FormLabel htmlFor={name}>Yes</FormLabel>
        <Field
          type="radio"
          id={name}
          name={name}
          value="yes"
          style={{ marginRight: "15px" }}
        />
        <FormLabel htmlFor={name}>No</FormLabel>
        <Field type="radio" id={name} name={name} value="no" />
      </RadioGroup>
    </FormGroup>
  );
};

export default FormRadio;
