import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Input,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchListing from "../../components/reusableComponents/searchListings/searchListings";
import PropertyFilter from "../../components/reusableComponents/propertyFilter/propertyFilter";
import SearchCard from "../../components/reusableComponents/searchCard/searchCard";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import {
  getAllPropertyTypes,
  getPropertiesListings,
} from "../../store/thunks/listingsThunk";
import ReactPaginate from "react-paginate";
import Footer from "../../components/commonComponents/footer/footer";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { prices } from "../../utilities/constants";
import RatingBox from "../../components/reusableComponents/ratingBox/RatingBox";
import styled from "styled-components";
import { setSearchPropertyName } from "../../store";

const PaginateContainer = styled.div`
  .pagination {
    margin: auto;
    padding: 25px 0;
  }

  .active {
    border-radius: 5px;
    background-color: #d5eef5;
  }
`;

const Listings = () => {
  const {
    listingLoading,
    allProperties,
    allPropertyTypes,
    currentDestination,
    searchPropertyName,
    selectedPropertyType,
    selectedPrice,
  } = useAppSelector((state: any) => state.listings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPropertiesListings({ value: currentDestination }));
  }, [currentDestination]);

  useEffect(() => {
    dispatch(getAllPropertyTypes({ value: "" }));
  }, [currentDestination]);

  useEffect(() => {
    updatePropertiesListings();
  }, [selectedPropertyType, selectedPrice, searchPropertyName]);

  useEffect(() => {
    if (allProperties?.properties_details) updatePropertiesListings();
  }, [allProperties]);

  const [allCurrentProperties, setAllCurrentProperties] = useState<any[]>([]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 4;
  const currentItems: any[] = allCurrentProperties
    ? allCurrentProperties.slice(itemOffset, endOffset)
    : [];
  const pageCount = Math.ceil(allCurrentProperties?.length / 4);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * 4) % allCurrentProperties.length;
    setItemOffset(newOffset);
  };

  const filterPropertiesByName = (searchName: string) => {
    let tempFilteredPropertyByName: any[] = [];
    if (searchName !== "") {
      allProperties?.properties_details?.forEach((property: any) => {
        if (
          property?.property_name
            ?.toLowerCase()
            .includes(searchName?.toLowerCase())
        ) {
          tempFilteredPropertyByName.push(property);
        }
      });
      return tempFilteredPropertyByName;
    } else {
      return allProperties?.properties_details;
    }
  };

  const filterPropertiesByPrice = (propertyList: any) => {
    let tempFilteredPropertyByPrice: any[] = [];
    if (selectedPrice.length > 0) {
      let tempMinPrices: any[] = [];
      let tempMaxprices: any[] = [];
      selectedPrice.forEach((filter: any) => {
        tempMinPrices.push(filter.min);
        tempMaxprices.push(filter.max);
      });
      const max = Math.max(...tempMaxprices);
      const min = Math.min(...tempMinPrices);

      propertyList.forEach((property: any) => {
        if (
          Number(property.price_per_day) > min &&
          Number(property.price_per_day) < max
        ) {
          tempFilteredPropertyByPrice.push(property);
        }
      });
      return tempFilteredPropertyByPrice;
    } else {
      return propertyList;
    }
  };

  const filterPropertiesByPropertyType = (propertyList: any) => {
    let tempFilteredPropertyByPropertyType: any[] = [];

    if (selectedPropertyType.length > 0) {
      let tempPropertyTypeFilters: any[] = [];
      selectedPropertyType.forEach((filter: any) => {
        tempPropertyTypeFilters.push(filter.property_type_name);
      });
      propertyList.forEach((property: any) => {
        if (tempPropertyTypeFilters.includes(property.property_type_name)) {
          tempFilteredPropertyByPropertyType.push(property);
        }
      });
      return tempFilteredPropertyByPropertyType;
    } else {
      return propertyList;
    }
  };

  const updatePropertiesListings = () => {
    const filtededByName = filterPropertiesByName(searchPropertyName);

    const filtededByPropertyType =
      filterPropertiesByPropertyType(filtededByName);

    const filtededByPropertyPrice = filterPropertiesByPrice(
      filtededByPropertyType
    );

    setAllCurrentProperties(filtededByPropertyPrice);
  };

  const matchesX = useMediaQuery("(min-width:900px)");
  return (
    <>
      <Spinner loading={listingLoading} />
      <Box sx={{ marginBottom: "100px" }}>
        <Container disableGutters maxWidth={false}>
          {matchesX ? (
            <Grid container sx={{ marginBottom: "100px" }}>
              <Grid
                item
                xs={12}
                sx={{
                  position: "relative",
                  height: "150px",
                  backgroundImage: "linear-gradient(#00B9D1, #313D41)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-50px",
                    width: "100%",
                  }}
                >
                  <SearchListing />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container sx={{ marginBottom: "50px" }}>
              <Grid
                item
                xs={12}
                sx={{ marginTop: "100px", position: "relative" }}
              >
                <Box
                  sx={{
                    height: "300px",
                    width: "100%",
                    position: "absolute",
                    top: "-100px",
                    zIndex: "-1",
                    backgroundImage: "linear-gradient(#00B9D1, #313D41)",
                  }}
                ></Box>
                <Box>
                  <SearchListing />
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
        <Container maxWidth={"xl"}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: "125px",
                  backgroundColor: "#D5EEF5",
                  padding: "20px",
                  marginBottom: "30px",
                  borderRadius: "15px",
                  border: "1px solid gray",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  Search by property name
                </Typography>
                <Input
                  placeholder="eg. Five55"
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    padding: "5px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onChange={(event) =>
                    dispatch(setSearchPropertyName(event.target.value))
                  }
                  value={searchPropertyName}
                />
              </Box>
              <PropertyFilter title={"Prices"} selections={prices} />
              <PropertyFilter
                title={"Property Types"}
                selections={allPropertyTypes}
              />
              {/* <Box
                sx={{
                  border: "1px solid gray",
                  borderRadius: "15px",
                  marginBottom: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "50px",
                    borderRadius: "15px 15px 0 0",
                    backgroundColor: "#D5EEF5",
                    padding: "10px 0 0 10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Rating
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "15px",
                    backgroundColor: "#fff",
                    borderRadius: "0 0 15px 15px",
                  }}
                >
                  <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                    Show only ratings more than
                  </Typography>
                  <RatingBox />
                </Box>
              </Box> */}
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Box sx={{ marginBottom: "15px" }}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {allCurrentProperties?.length} Properties found
                </Typography>
              </Box>
              <Box>
                {currentItems.map((property_detail: object, index: number) => {
                  return (
                    <SearchCard key={index} propertyDetails={property_detail} />
                  );
                })}
              </Box>
              <Box sx={{ backgroundColor: "#fff" }}>
                <PaginateContainer>
                  <ReactPaginate
                    breakLabel={
                      <Typography
                        sx={{
                          marginRight: "10px",
                        }}
                      >
                        ...
                      </Typography>
                    }
                    nextLabel={
                      <Typography
                        sx={{
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#D5EEF5",
                          padding: "10px",
                          borderRadius: "5px",
                          marginLeft: "15px",
                        }}
                      >
                        &rsaquo;
                      </Typography>
                    }
                    previousLabel={
                      <Typography
                        sx={{
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#D5EEF5",
                          padding: "10px",
                          borderRadius: "5px",
                          marginRight: "15px",
                        }}
                      >
                        &lsaquo;
                      </Typography>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName={"page-item"}
                    activeClassName={"active"}
                  />
                </PaginateContainer>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer bg={true} />
    </>
  );
};

export default Listings;
