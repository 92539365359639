import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  Snackbar,
} from "@mui/material";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { ForgotPasswordInfo } from "../../types/formsTypes";
import { reset } from "../../store";
import { forgotPassowrd } from "../../store/thunks/authenticationThunk";

const ForgotPassword = () => {
  const { forgotPassowrdLoading, error, loggedIn, previousLocation, response } =
    useAppSelector((state: any) => state.authentication);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resetError, setResetError] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
    dispatch(reset());
  }, [navigate, loggedIn]);

  const validateForgotPassword = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });

  let forgotPasswordInitialValues: ForgotPasswordInfo = {
    email: "",
  };

  const forgotPasswordForm = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: validateForgotPassword,
    onSubmit: (values) => {
      dispatch(forgotPassowrd(values)).then((result: any) => {
        if (!result?.error) {
          setResetError(true);
          forgotPasswordForm.resetForm();
        }
      });
    },
  });

  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={forgotPassowrdLoading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          open={resetError}
          message={response?.message}
          key={"topcenter"}
          onClose={() => setResetError(false)}
        />
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={forgotPasswordForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                <Grid item xs={12} sx={{}}>
                  <Typography>Email</Typography>
                  <TextField
                    sx={{}}
                    placeholder="Email"
                    name="email"
                    inputProps={{}}
                    fullWidth
                    value={forgotPasswordForm.values.email}
                    error={
                      forgotPasswordForm.touched.email &&
                      Boolean(forgotPasswordForm.errors.email)
                    }
                    helperText={forgotPasswordForm.errors.email}
                    onChange={forgotPasswordForm.handleChange}
                  />
                </Grid>
                {error && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#000000" }}
                    onClick={() => forgotPasswordForm.submitForm}
                    type="submit"
                  >
                    Send Reset Password Email
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
