import React from "react";
import { Field } from "formik";
import { FormGroup, FormLabel } from "@mui/material";

interface FormInputProps {
  label: string;
  name: string;
}

const FormNumber = (props: FormInputProps) => {
  const { label, name, ...rest } = props;
  return (
    <FormGroup sx={{ marginBottom: "15px" }}>
      <FormLabel
        htmlFor={name}
        sx={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        {label}
      </FormLabel>
      <Field
        id={name}
        name={name}
        {...rest}
        style={{
          border: "none",
          borderBottom: "1px solid #CCC",
          padding: "0 0 5px 0",
          fontSize: "1rem",
          outline: "none",
        }}
        type="number"
      />
    </FormGroup>
  );
};

export default FormNumber;
