import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const bookingsRoute = "/api/v1/bookings";

const getRenterBooking = createAsyncThunk(
  "bookings/getRenterBooking",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getRenterBooking`,
        { user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getRenterBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOwnerBooking = createAsyncThunk(
  "bookings/getOwnerBooking",
  async ({ owner_id }: { owner_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getOwnerBooking`,
        { owner_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getOwnerBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const bookingChekin = createAsyncThunk(
  "bookings/bookingChekin",
  async ({ booking_id }: { booking_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/bookingChekin`,
        { booking_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("bookingChekin", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const bookingChekout = createAsyncThunk(
  "bookings/bookingChekout",
  async ({ booking_id }: { booking_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/bookingChekout`,
        { booking_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("bookingChekout", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPropertyBookings = createAsyncThunk(
  "bookings/getPropertyBookings",
  async ({ property_id }: { property_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getPropertyBookings`,
        { property_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getPropertyBookings", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRenterCompletedBooking = createAsyncThunk(
  "bookings/getRenterCompletedBooking",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getRenterCompletedBooking`,
        { user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getRenterCompletedBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOwnerCompletedBooking = createAsyncThunk(
  "bookings/getOwnerCompletedBooking",
  async ({ owner_id }: { owner_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getOwnerCompletedBooking`,
        { owner_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getOwnerCompletedBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getSpecificBooking = createAsyncThunk(
  "bookings/getSpecificBooking",
  async (
    { booking_id }: { booking_id: string | undefined },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getSpecificBooking`,
        { booking_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getSpecificBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const cancelBooking = createAsyncThunk(
  "bookings/cancelBooking",
  async (
    { cancel_booking_payload }: { cancel_booking_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/cancelBooking`,
        { cancel_booking_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("cancelBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateBooking = createAsyncThunk(
  "bookings/updateBooking",
  async (
    { update_booking_payload }: { update_booking_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/updateBooking`,
        { update_booking_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updateBooking", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getBookingPayments = createAsyncThunk(
  "bookings/getBookingPayments",
  async ({ booking_id }: { booking_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getBookingPayments`,
        { booking_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getBookingPayments", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const updateBookingFee = createAsyncThunk(
  "bookings/updateBookingFee",
  async (
    { update_booking_fee_payload }: { update_booking_fee_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/updateBookingFee`,
        { update_booking_fee_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("updateBookingFee", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getOwnersCalendarBookings = createAsyncThunk(
  "bookings/getOwnersCalendarBookings",
  async ({ owner_id }: { owner_id: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${bookingsRoute}/getOwnersCalendarBookings`,
        { owner_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getOwnersCalendarBookings", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getOwnerBooking,
  getRenterBooking,
  bookingChekin,
  bookingChekout,
  getPropertyBookings,
  getRenterCompletedBooking,
  getOwnerCompletedBooking,
  getSpecificBooking,
  cancelBooking,
  updateBooking,
  getBookingPayments,
  updateBookingFee,
  getOwnersCalendarBookings,
};
