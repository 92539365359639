import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const data = [
  { value: 88.9, label: "Occupancy" },
  { value: 6, label: "Cancellation" },
];

const size = {
  width: 390,
  height: 200,
};

const RoundChart = () => {
  return <PieChart series={[{ data, innerRadius: 80 }]} {...size}></PieChart>;
};
export default RoundChart;
