import {
  Box,
  Checkbox,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import {
  disableUser,
  enableUser,
  getAllUsers,
} from "../../../store/thunks/userThunk";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import LockResetIcon from "@mui/icons-material/LockReset";
import Spinner from "../../../components/reusableComponents/spinner/spinner";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import UpdateUserAccountDialog from "./updateUserAccountDialog/updateUserAccountDialog";
import { getAllAccounts } from "../../../store/thunks/systemThunk";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { logout, resetMessage, setPreviousLocation } from "../../../store";

const UserManagement = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { getAllUsersLoading, disableUserLoading, enableUserLoading } =
    useAppSelector((state: any) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [userTableData, setUserTableData] = useState<any[]>([]);

  const [selectedUserData, setSelectedUserData] = useState<any>();

  const [openUpdateUserAccount, setOpenUpdateUserAccount] = useState(false);

  const submitEnableUser = (userDetails: any) => {
    dispatch(enableUser({ user_id: userDetails?.user_id }))
      .then((enableUdersResult: any) => {
        if (enableUdersResult?.payload?.status) {
          setOpenSnackbar(true);
          setSnackbarMessage(enableUdersResult?.payload?.message);
          refetch();
        } else {
          setOpenSnackbar(true);
          setSnackbarMessage(
            "Unable to enable user account. Please try again later or contact support is issues persist"
          );
        }
      })
      .catch((enableUserError: any) => {
        displayConsoleError("enableUser", enableUserError);
        setOpenSnackbar(true);
        // setSnackbarMessage(enableUdersResult?.payload?.message);
        setSnackbarMessage(
          "Unable to enable user account. Please try again later or contact support is issues persist"
        );
      });
  };

  const submitDisableUser = (userDetails: any) => {
    dispatch(disableUser({ user_id: userDetails?.user_id }))
      .then((disableUdersResult: any) => {
        if (disableUdersResult?.payload?.status) {
          setOpenSnackbar(true);
          setSnackbarMessage(disableUdersResult?.payload?.message);
          refetch();
        } else {
          setOpenSnackbar(true);
          setSnackbarMessage(
            "Unable to enable user account. Please try again later or contact support is issues persist"
          );
        }
      })
      .catch((disableUserError: any) => {
        displayConsoleError("disableUser", disableUserError);
        setOpenSnackbar(true);
        // setSnackbarMessage(enableUdersResult?.payload?.message);
        setSnackbarMessage(
          "Unable to enable user account. Please try again later or contact support is issues persist"
        );
      });
  };

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(
        getAllUsers({ account_id: userInfo.account_id })
      )
        .then((getAllUsersResult: any) => {
          if (getAllUsersResult?.error) {
            if (
              getAllUsersResult?.error?.message === "Rejected" ||
              getAllUsersResult?.error?.response?.status === 401
            ) {
              dispatch(logoutUser({ session_id: userInfo?.session_id }));
              dispatch(logout());
              dispatch(resetMessage());
              dispatch(setPreviousLocation(location.pathname));
              navigate("/login");
            }
          }
          if (getAllUsersResult?.payload) {
            setUserTableData(getAllUsersResult?.payload.all_users);
          }
        })
        .catch((getAllUsersError) => {
          displayConsoleError("getAllUsers", getAllUsersError);
          if (
            getAllUsersError.response &&
            getAllUsersError.response.status === 401
          ) {
            navigate("/login");
          }
        });
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const userColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "user_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "User Name",
      },
      {
        accessorKey: "email",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Email",
      },
      {
        accessorFn: (row: any) => `${row.first_name} ${row.last_name}`,
        id: "name",
        enableClickToCopy: true,
        header: "Full Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "account_label",
        filterVariant: "autocomplete",
        header: "Account",
      },
      {
        accessorKey: "status_label",
        filterVariant: "autocomplete",
        header: "Status",
      },
      {
        accessorKey: "full_address",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Address",
      },
      {
        accessorKey: "contact",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Phone Number",
      },
      {
        accessorFn: (row: any) =>
          row?.dob ? dayjs(row.dob).format("MMM DD, YYYY") : "",
        id: "dob",
        header: "DOB",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "verified",
        filterVariant: "autocomplete",
        header: "Verified",
        Cell: ({
          renderedCellValue,
          row,
        }: {
          renderedCellValue: any;
          row: any;
        }) => (
          <Checkbox disabled defaultChecked={row?.verified ? true : false} />
        ),
      },
    ],
    []
  );

  const userTable = useMaterialReactTable({
    columns: userColumns,
    data: userTableData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: getAllUsersLoading, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "flex",
    //       justifyContent: "space-around",
    //       left: "30px",
    //       maxWidth: "1000px",
    //       position: "sticky",
    //       width: "100%",
    //     }}
    //   >
    //     <Box sx={{ textAlign: "center" }}>
    //       <Typography variant="h4">Signature Catch Phrase:</Typography>
    //       <Typography variant="h1">
    //         &quot;{row.original.signatureCatchPhrase}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({
      closeMenu,
      row,
    }: {
      closeMenu: any;
      row: any;
    }) => [
      <MenuItem
        key={1}
        onClick={() => {
          setSelectedUserData(row.original);
          setOpenUpdateUserAccount(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <UpdateIcon />
        </ListItemIcon>
        Update User Account
      </MenuItem>,
      row?.original?.status_name !== "active" && (
        <MenuItem
          key={2}
          onClick={() => {
            submitEnableUser(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Enable User
        </MenuItem>
      ),
      row?.original?.status_name === "active" && (
        <MenuItem
          key={3}
          onClick={() => {
            submitDisableUser(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Disable User
        </MenuItem>
      ),
    ],
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("deactivating " + row.getValue("name"));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("activating " + row.getValue("name"));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("contact " + row.getValue("name"));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: "flex",
    //         gap: "0.5rem",
    //         p: "8px",
    //         justifyContent: "space-between",
    //       })}
    //     >
    //       <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
    //         {/* import MRT sub-components */}
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: "flex", gap: "0.5rem" }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
  });

  const handleCloseUpdateUserAccount = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setOpenUpdateUserAccount(false);
  };

  useEffect(() => {
    dispatch(
      getAllAccounts({
        account_id: userInfo?.account_id,
      })
    );
  }, []);

  return (
    <Grid container>
      <Spinner loading={enableUserLoading} />
      <Spinner loading={disableUserLoading} />
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <TableTemplate tableData={userTable} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
      <UpdateUserAccountDialog
        open={openUpdateUserAccount}
        handleCloseCancel={(event: any) => handleCloseUpdateUserAccount(event)}
        selectedUserDetails={selectedUserData}
      />
    </Grid>
  );
};
export default UserManagement;
