import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "../../../pages/propertiesPage/FormControl";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../utilities/constants";
import { updatePropertyDetails } from "../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import Spinner from "../../reusableComponents/spinner/spinner";

interface UpdatePropertyDetailsDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;

  selectedPropertyDetails: any;
}

interface UpdatePropertyDetail {
  property_id: string;
  property_name: string;
  access_code: string;
  property_description: string;
  property_type: any;
  listing_type: any;
  room_type: any;
  bedrooms: number;
  bathrooms: number;
  number_of_beds: number;
  guests: number;
  wifi_name: string;
  wifi_password: string;
  rules: string;
  extra: string;
}

const UpdatePropertyDetailsDialog = (
  props: UpdatePropertyDetailsDialogProps
) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const {
    allPropertyRoomTypes,
    allPropertyListingTypes,
    updatePropertyDetailsLoading,
  } = useAppSelector((state: any) => state.property);
  const { allPropertyTypes } = useAppSelector((state: any) => state.listings);

  const dispatch = useAppDispatch();

  const [enableUpdatePropertyDetails, setEnableUpdatePropertyDetails] =
    useState(true);
  const [updatePropertyDetailsError, setUpdatePropertyDetailsError] =
    useState(false);
  const [
    updatePropertyDetailsErrorMessage,
    setUpdatePropertyDetailsErrorMessage,
  ] = useState("");

  const checkEnableUpdate = () => {
    if (
      selectedPropertyDetails?.property_name ===
        propertyDetailsParameters?.property_name &&
      selectedPropertyDetails?.access_code ===
        propertyDetailsParameters?.access_code &&
      selectedPropertyDetails?.description ===
        propertyDetailsParameters?.property_description &&
      selectedPropertyDetails?.bedrooms ===
        propertyDetailsParameters?.bedrooms &&
      selectedPropertyDetails?.bathrooms ===
        propertyDetailsParameters?.bathrooms &&
      selectedPropertyDetails?.number_of_beds ===
        propertyDetailsParameters?.number_of_beds &&
      selectedPropertyDetails?.guests === propertyDetailsParameters?.guests &&
      selectedPropertyDetails?.wifi_name ===
        propertyDetailsParameters?.wifi_name &&
      selectedPropertyDetails?.wifi_password ===
        propertyDetailsParameters?.wifi_password &&
      selectedPropertyDetails?.rules === propertyDetailsParameters?.rules &&
      selectedPropertyDetails?.extra === propertyDetailsParameters?.extra &&
      selectedPropertyDetails?.property_type_id ===
        propertyDetailsParameters?.property_type?.property_type_id &&
      selectedPropertyDetails?.property_listing_type_id ===
        propertyDetailsParameters?.listing_type?.property_listing_type_id &&
      selectedPropertyDetails?.property_room_type_id ===
        propertyDetailsParameters?.room_type?.property_room_type_id
    ) {
      setEnableUpdatePropertyDetails(true);
    } else {
      setEnableUpdatePropertyDetails(false);
    }
  };

  const [propertyDetailsParameters, setPropertyDetailsParameters] = useState<
    Partial<UpdatePropertyDetail>
  >({
    property_id: selectedPropertyDetails?.property_id,
    property_name: selectedPropertyDetails?.property_name,
    access_code: selectedPropertyDetails?.access_code,
    property_description: selectedPropertyDetails?.description,
    property_type: {
      label: selectedPropertyDetails?.label,
      property_type_id: selectedPropertyDetails?.property_type_id,
      property_type_name: selectedPropertyDetails?.property_type_name,
    },
    listing_type: {
      label: selectedPropertyDetails?.listing_type_label,
      listing_type: selectedPropertyDetails?.listing_type,
      property_listing_type_id:
        selectedPropertyDetails?.property_listing_type_id,
    },
    room_type: {
      label: selectedPropertyDetails?.room_type_label,
      property_room_type_id: selectedPropertyDetails?.property_room_type_id,
      room_type: selectedPropertyDetails?.room_type,
    },
    bedrooms: selectedPropertyDetails?.bedrooms,
    bathrooms: selectedPropertyDetails?.bathrooms,
    number_of_beds: selectedPropertyDetails?.number_of_beds,
    guests: selectedPropertyDetails?.guests,
    wifi_name: selectedPropertyDetails?.wifi_name,
    wifi_password: selectedPropertyDetails?.wifi_password,
    rules: selectedPropertyDetails?.rules,
    extra: selectedPropertyDetails?.extra,
  });

  const setPropertyDetailsAtribute = (name: string, values: any) => {
    setPropertyDetailsParameters((previous) => ({
      ...previous,
      [name]: values,
    }));
  };

  useEffect(() => {
    setPropertyDetailsAtribute(
      "property_id",
      selectedPropertyDetails?.property_id
    );
    setPropertyDetailsAtribute(
      "property_name",
      selectedPropertyDetails?.property_name
    );
    setPropertyDetailsAtribute(
      "access_code",
      selectedPropertyDetails?.access_code
    );
    setPropertyDetailsAtribute(
      "property_description",
      selectedPropertyDetails?.description
    );
    setPropertyDetailsAtribute("property_type", {
      label: selectedPropertyDetails?.label,
      property_type_name: selectedPropertyDetails?.property_type_name,
      property_type_id: selectedPropertyDetails?.property_type_id,
    });
    setPropertyDetailsAtribute("listing_type", {
      label: selectedPropertyDetails?.listing_type_label,
      listing_type: selectedPropertyDetails?.listing_type,
      property_listing_type_id:
        selectedPropertyDetails?.property_listing_type_id,
    });
    setPropertyDetailsAtribute("room_type", {
      label: selectedPropertyDetails?.room_type_label,
      property_room_type_id: selectedPropertyDetails?.property_room_type_id,
      room_type: selectedPropertyDetails?.room_type,
    });
    setPropertyDetailsAtribute("bedrooms", selectedPropertyDetails?.bedrooms);
    setPropertyDetailsAtribute("bathrooms", selectedPropertyDetails?.bathrooms);
    setPropertyDetailsAtribute(
      "number_of_beds",
      selectedPropertyDetails?.number_of_beds
    );
    setPropertyDetailsAtribute("guests", selectedPropertyDetails?.guests);
    setPropertyDetailsAtribute("wifi_name", selectedPropertyDetails?.wifi_name);
    setPropertyDetailsAtribute(
      "wifi_password",
      selectedPropertyDetails?.wifi_password
    );
    setPropertyDetailsAtribute("rules", selectedPropertyDetails?.rules);
    setPropertyDetailsAtribute("extra", selectedPropertyDetails?.extra);
  }, [selectedPropertyDetails]);

  const handleCloseUpdatePropertyDetailsDialog = (event: any) => {
    setPropertyDetailsAtribute("property_name", "");
    setPropertyDetailsAtribute("access_code", "");
    setPropertyDetailsAtribute("property_description", "");
    setPropertyDetailsAtribute("property_type", null);
    setPropertyDetailsAtribute("listing_type", null);
    setPropertyDetailsAtribute("room_type", null);
    setPropertyDetailsAtribute("bedrooms", 0);
    setPropertyDetailsAtribute("bathrooms", 0);
    setPropertyDetailsAtribute("number_of_beds", 0);
    setPropertyDetailsAtribute("guests", 0);
    setPropertyDetailsAtribute("wifi_name", "");
    setPropertyDetailsAtribute("wifi_password", "");
    setPropertyDetailsAtribute("rules", "");
    setPropertyDetailsAtribute("extra", "");
    handleCloseCancel(event);
  };

  const submitUpdatePropertyDetailsDialog = () => {
    setUpdatePropertyDetailsError(false);
    setUpdatePropertyDetailsErrorMessage("");
    dispatch(
      updatePropertyDetails({
        property_detail_payload: propertyDetailsParameters,
      })
    )
      .then((updatePropertyDetailsResult) => {
        if (updatePropertyDetailsResult?.payload?.status) {
          handleCloseUpdatePropertyDetailsDialog(
            updatePropertyDetailsResult?.payload?.message
          );
        } else {
          setUpdatePropertyDetailsError(true);
          setUpdatePropertyDetailsErrorMessage(
            "Unable to update property Details, check for duplicate property name. Please try again later or contact support"
          );
        }
      })
      .catch((updatePropertyDetailsError) => {
        displayConsoleError(
          "updatePropertyDetails",
          updatePropertyDetailsError
        );
        setUpdatePropertyDetailsError(true);
        setUpdatePropertyDetailsErrorMessage(
          "Unable to update property Details, check for duplicate property name, Please try again later or contact support"
        );
      });
  };

  useEffect(() => {
    checkEnableUpdate();
  }, [propertyDetailsParameters]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updatePropertyDetailsLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update User Account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyDetailsDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="property_name"
              label="Property Name"
              name="property_name"
              value={propertyDetailsParameters.property_name}
              onChange={(event) =>
                setPropertyDetailsAtribute("property_name", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              error={
                propertyDetailsParameters.property_name === "" ? true : false
              }
              helperText={
                propertyDetailsParameters.property_name === ""
                  ? "Property Name is required."
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="access_code"
              label="Access Code"
              name="access_code"
              value={propertyDetailsParameters.access_code}
              onChange={(event) =>
                setPropertyDetailsAtribute("access_code", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              error={
                propertyDetailsParameters.access_code === "" ? true : false
              }
              helperText={
                propertyDetailsParameters.access_code === ""
                  ? "Access Code is required."
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="description"
              label="Property Description"
              name="description"
              value={propertyDetailsParameters.property_description}
              onChange={(event) =>
                setPropertyDetailsAtribute(
                  "property_description",
                  event.target.value
                )
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              error={
                propertyDetailsParameters.property_description === ""
                  ? true
                  : false
              }
              helperText={
                propertyDetailsParameters.property_description === ""
                  ? "Property Description is required."
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <Autocomplete
              options={allPropertyTypes ? allPropertyTypes : []}
              onChange={(_, value: any) =>
                setPropertyDetailsAtribute("property_type", value)
              }
              value={propertyDetailsParameters.property_type}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Property Type"}
                  id="property_type"
                  name="property_type"
                  error={propertyDetailsParameters.property_type ? false : true}
                  helperText={
                    propertyDetailsParameters.property_type
                      ? ""
                      : "Property Type is required."
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <Autocomplete
              options={allPropertyListingTypes ? allPropertyListingTypes : []}
              onChange={(_, value: any) =>
                setPropertyDetailsAtribute("listing_type", value)
              }
              value={propertyDetailsParameters.listing_type}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Listing Type"}
                  id="listing_type"
                  name="listing_type"
                  error={propertyDetailsParameters.listing_type ? false : true}
                  helperText={
                    propertyDetailsParameters.listing_type
                      ? ""
                      : "Listing Type is required."
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <Autocomplete
              options={allPropertyRoomTypes ? allPropertyRoomTypes : []}
              onChange={(_, value: any) =>
                setPropertyDetailsAtribute("room_type", value)
              }
              value={propertyDetailsParameters.room_type}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Room Type"}
                  id="room_type"
                  name="room_type"
                  error={propertyDetailsParameters.room_type ? false : true}
                  helperText={
                    propertyDetailsParameters.room_type
                      ? ""
                      : "Room Type is required."
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="bedrooms"
              label="Bedrooms"
              name="bedrooms"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              value={propertyDetailsParameters.bedrooms}
              onChange={(event) =>
                setPropertyDetailsAtribute("bedrooms", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="bathrooms"
              label="BathRooms"
              name="bathrooms"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              value={propertyDetailsParameters.bathrooms}
              onChange={(event) =>
                setPropertyDetailsAtribute("bathrooms", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="number_of_beds"
              label="Number of Beds"
              name="number_of_beds"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              value={propertyDetailsParameters.number_of_beds}
              onChange={(event) =>
                setPropertyDetailsAtribute("number_of_beds", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="guests"
              label="Maximum Guess"
              name="guests"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              value={propertyDetailsParameters.guests}
              onChange={(event) =>
                setPropertyDetailsAtribute("guests", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="wifi_name"
              label="Wifi Name (SSID"
              name="wifi_name"
              value={propertyDetailsParameters.wifi_name}
              onChange={(event) =>
                setPropertyDetailsAtribute("wifi_name", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="wifi_password"
              label="Wifi Password"
              name="wifi_password"
              value={propertyDetailsParameters.wifi_password}
              onChange={(event) =>
                setPropertyDetailsAtribute("wifi_password", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="rules"
              label="Property Rules"
              name="rules"
              value={propertyDetailsParameters.rules}
              onChange={(event) =>
                setPropertyDetailsAtribute("rules", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ padding: "1%" }}>
            <TextField
              id="extra"
              label="Additional Information "
              name="extra"
              value={propertyDetailsParameters.extra}
              onChange={(event) => {
                setPropertyDetailsAtribute("extra", event.target.value);
              }}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          {updatePropertyDetailsError && (
            <Grid
              container
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ color: "red" }}>
                {updatePropertyDetailsErrorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyDetailsDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyDetailsDialog()}
          disabled={enableUpdatePropertyDetails}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyDetailsDialog;
