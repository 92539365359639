import React, { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { logout } from "../../../store";
import dayjs from "dayjs";

const LayoutContainer = styled("div")(({ theme }) => ({}));

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timeStamp: any;
  let warningInactiveInterval: any = useRef();
  let startTimerInterval: any = useRef();

  let warningInactive = (timeString: any) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const currenttimestamp = dayjs();
      const diff = currenttimestamp.diff(dayjs(timeString), "minute", true);

      if (diff > 30) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        dispatch(logoutUser({ session_id: userInfo?.session_id }));
        dispatch(logout());
        navigate("/login");
      }
    }, 1000 * 60);
  };

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 1000 * 60 * 3);
  };

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (loggedIn) {
      timeStamp = dayjs();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [loggedIn]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  return (
    <LayoutContainer>
      <Outlet />
    </LayoutContainer>
  );
};

export default Layout;
