import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const online = [
  4000, 3000, 2000, 2780, 1890, 2390, 3490, 1890, 2390, 3490, 1890, 2390,
];
const store = [
  2400, 1398, 9800, 3908, 4800, 3800, 4300, 4800, 3800, 4300, 4800, 3800,
];
const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dev",
];

const RevenueChart = () => {
  return (
    <BarChart
      height={450}
      series={[
        { data: online, label: "ONLINE", id: "online", stack: "total" },
        { data: store, label: "STORE", id: "store", stack: "total" },
      ]}
      xAxis={[{ data: xLabels, scaleType: "band" }]}
    />
  );
};

export default RevenueChart;
