import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL } from "../../utilities/utils";

const processPayment = createAsyncThunk(
  "payment/processPayment",
  async ({ paymentInfo }: { paymentInfo: object }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/api/v1/payment/processPayment`,
        { paymentInfo },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const checkLeads = createAsyncThunk(
  "payment/checkLeads",
  async ({ leadsInfo }: { leadsInfo: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/api/v1/payment/checkLeads`,
        { leadsInfo },
        tokenInterceptor()
      );
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const paymentIntent = createAsyncThunk(
  "payment/paymentIntent",
  async (
    {
      amount,
      stripe_customer_id,
    }: { amount: number; stripe_customer_id: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/api/v1/payment/paymentIntent`,
        { amount, stripe_customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const cancelPaymentIntent = createAsyncThunk(
  "payment/cancelPaymentIntent",
  async ({ paymentIntent }: { paymentIntent: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/api/v1/payment/cancelPaymentIntent`,
        { paymentIntent },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export { processPayment, checkLeads, paymentIntent, cancelPaymentIntent };
