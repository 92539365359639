import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import Spinner from "../../reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import Webcam from "react-webcam";
import CloseIcon from "@mui/icons-material/Close";
import { LVLpmThemeButton } from "../../../utilities/constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Camera } from "react-camera-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { trustSwiftyVerification } from "../../../store/thunks/userThunk";
import { displayConsoleError } from "../../../utilities/helperFunctions";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

interface UserVerificationDialogProps {
  open: boolean;
  handleClose: (event: any) => void;
}

export const SearchErrorMessage = ({ status }: { status: any }) => {
  // Section 6.2: switch error messages in response to Google Maps server status
  return status === "" || status === "OK" ? null : (
    <div role="alert">
      {status === "ZERO_RESULTS" ||
      status === "INVALID_REQUEST" ||
      status === "NOT_FOUND" ? (
        <p>No place is found on the map. Try another search term.</p>
      ) : status === "OVER_QUERY_LIMIT" || status === "REQUEST_DENIED" ? (
        <p>
          Google Maps request is over the limit. Please try again once they fix
          the problem (usually within a few hours).
        </p>
      ) : (
        <p>
          Google Maps server is down. Please try again once they fix the problem
          (usually within a few hours).
        </p>
      )}
    </div>
  );
};

const UserVerificationDialog = (props: UserVerificationDialogProps) => {
  const { open, handleClose } = props;
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const [userVerificationError, setUserVerificationError] = useState(false);
  const [userVerificationErrorMessage, setUserVerificationErrorMessage] =
    useState("");

  const [identificationImageList, setIdentificationImageList] = useState<any>(
    []
  );
  const [selfieImageList, setISelfieImageList] = useState<any>([]);

  const handleCloseUserVerificationDialog = (event: any) => {
    if (event === "") {
      userVerificationIForm.setFieldValue("first_name", userInfo?.first_name);
      userVerificationIForm.setFieldValue("last_name", userInfo?.last_name);
      userVerificationIForm.setFieldValue(
        "dob",
        userInfo?.dob ? dayjs(userInfo.bob) : undefined
      );
      userVerificationIForm.setFieldValue("email", userInfo?.email);
      userVerificationIForm.setFieldValue(
        "phone_number",
        userInfo.contact ? userInfo?.contact : ""
      );
      userVerificationIForm.setFieldValue("country", "");
      userVerificationIForm.setFieldValue("state", "");
      userVerificationIForm.setFieldValue("city", "");
      userVerificationIForm.setFieldValue("street_1", "");
      userVerificationIForm.setFieldValue("street_2", "");
      userVerificationIForm.setFieldValue("address", null);
      userVerificationIForm.setFieldValue("selfie", null);
      userVerificationIForm.setFieldValue("identification", null);
      setSelectedAddress(null);
    }
    userVerificationIForm.resetForm();
    handleClose(event);
  };

  const validateUserVerification = Yup.object({
    first_name: Yup.string().required("First Name is Required"),
    last_name: Yup.string().required("Last Name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    dob: Yup.date().required("Date of Birth is Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number Required !"),
    identification: Yup.mixed()
      .required("Driver License/ID is required")
      .test(
        "fileSize",
        "File size must be less than or euqal 10MB",
        (value: any) => {
          if (value) {
            const filelist: any[] = Array.from(value);
            return filelist[0].size <= 10 * 1024 * 1024;
          }
          return true;
        }
      ),
    selfie: Yup.mixed()
      .required("Selfie is required")
      .test(
        "fileSize",
        "File size must be less than or euqal 10MB",
        (value: any) => {
          if (value) {
            const filelist: any[] = Array.from(value);
            return filelist[0].size <= 10 * 1024 * 1024;
          }
          return true;
        }
      ),
    address: Yup.object().required("First Name is Required"),
  });

  const userVerificationInitialValues = {
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    dob: userInfo?.dob ? dayjs(userInfo?.bob) : undefined,
    email: userInfo?.email,
    phone_number: userInfo?.contact ? userInfo.contact : "",
    country: "",
    state: "",
    city: "",
    street_1: "",
    street_2: "",
    address: null,
    selfie: null,
    identification: null,
  };

  const userVerificationIForm = useFormik({
    initialValues: userVerificationInitialValues,
    validationSchema: validateUserVerification,
    onSubmit: (values) => {
      dispatch(trustSwiftyVerification({ userVerificationPayload: values }))
        .then((result: any) => {
          if (result?.error) {
          } else {
          }
        })
        .catch((error) => {
          displayConsoleError("resetPasswordLinkVerification", error);
        });
    },
  });

  const [searchResult, setSearchResult] = useState({
    autocompleteSuggestions: [],
    status: "",
  });

  const google = window.google;
  const service = new google.maps.places.AutocompleteService();

  const sessionToken = useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    [google.maps.places.AutocompleteSessionToken]
  );

  const [selectedAddress, setSelectedAddress] = useState<any>();

  const updateFilterOptions = (filterValue: any) => {
    if (filterValue) {
      const address_details = filterValue?.value?.description
        ?.split(",")
        .map(function (value: any) {
          return value.trim();
        });
      const address_data =
        filterValue?.value?.structured_formatting?.secondary_text
          ?.split(",")
          .map(function (value: any) {
            return value.trim();
          });

      if (address_details.length === 3) {
        if (address_details[2].length === 2) {
          userVerificationIForm.setFieldValue(
            "street_1",
            filterValue?.value?.structured_formatting?.main_text
              ? filterValue?.value?.structured_formatting?.main_text
              : address_details[0]
          );
          userVerificationIForm.setFieldValue(
            "city",
            filterValue?.value?.terms?.length > 3
              ? filterValue?.value?.terms[2]?.value
              : address_data?.length[1]
          );
          userVerificationIForm.setFieldValue(
            "state",
            filterValue?.value?.terms?.length >= 4
              ? filterValue?.value?.terms[3]?.value
              : address_data[2]
          );
          userVerificationIForm.setFieldValue("country", "US");
        } else {
          userVerificationIForm.setFieldValue(
            "street_1",
            filterValue?.value?.structured_formatting?.main_text
              ? filterValue?.value?.structured_formatting?.main_text
              : address_details[0]
          );
          userVerificationIForm.setFieldValue(
            "city",
            address_data === 2 ? address_data[0] : address_details[1]
          );
          userVerificationIForm.setFieldValue("state", "");
          userVerificationIForm.setFieldValue(
            "country",
            address_data === 2 ? address_data[1] : address_details[2]
          );
        }
      } else {
        userVerificationIForm.setFieldValue(
          "street_1",
          filterValue?.value?.structured_formatting?.main_text
            ? filterValue?.value?.structured_formatting?.main_text
            : address_details[0]
            ? address_details[0]
            : filterValue?.value?.description
        );
        userVerificationIForm.setFieldValue("city", address_data[0]);
        userVerificationIForm.setFieldValue(
          "state",
          address_data?.length === 3 ? address_data[1] : ""
        );
        userVerificationIForm.setFieldValue(
          "country",
          address_data?.length === 3 ? address_data[2] : address_data[1]
        );
      }
    }
    setSelectedAddress(filterValue);
    userVerificationIForm.setFieldValue("address", filterValue?.value);
  };

  const handlePredictions = (predictions: any, status: any) => {
    if (status === "OK") {
      const autocompleteSuggestions = predictions.map((prediction: any) => {
        return {
          value: prediction,
          label: prediction.description,
        };
      });
      setSearchResult({
        autocompleteSuggestions: autocompleteSuggestions,
        status: "OK",
      });
    } else {
      setSearchResult({
        autocompleteSuggestions: [],
        status: status,
      });
    }
  };

  const handleAddressChange = (filterValue: string) => {
    if (!filterValue || filterValue === "") {
      setSelectedAddress(null);
      setSearchResult({
        autocompleteSuggestions: [],
        status: "",
      });
    } else {
      service.getPlacePredictions(
        {
          input: filterValue,
          sessionToken: sessionToken,
        },
        handlePredictions
      );
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => `${option.label}`,
  });

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={false} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Account Verification Required
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUserVerificationDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <form onSubmit={userVerificationIForm.handleSubmit}>
        <DialogContent>
          <Grid container>
            <Grid item xs={3} sx={{ padding: "1%" }}>
              <TextField
                id="first_name"
                label="First Name *"
                name="first_name"
                value={userVerificationIForm.values.first_name}
                onChange={userVerificationIForm.handleChange}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                error={
                  userVerificationIForm.errors.first_name &&
                  userVerificationIForm.touched.first_name
                    ? true
                    : false
                }
                helperText={
                  userVerificationIForm.errors.first_name &&
                  userVerificationIForm.touched.first_name
                    ? "First Name is required."
                    : ""
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ padding: "1%" }}>
              <TextField
                id="last_name"
                label="Last Name *"
                name="last_name"
                value={userVerificationIForm.values.last_name}
                onChange={userVerificationIForm.handleChange}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                error={
                  userVerificationIForm.errors.last_name &&
                  userVerificationIForm.touched.last_name
                    ? true
                    : false
                }
                helperText={
                  userVerificationIForm.errors.last_name &&
                  userVerificationIForm.touched.last_name
                    ? "Last Name is required."
                    : ""
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ padding: "1%" }}>
              <TextField
                id="email"
                label="Email *"
                name="email"
                value={userVerificationIForm.values.email}
                onChange={userVerificationIForm.handleChange}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                error={
                  userVerificationIForm.errors.email &&
                  userVerificationIForm.touched.email
                    ? true
                    : false
                }
                helperText={
                  userVerificationIForm.errors.email &&
                  userVerificationIForm.touched.email
                    ? "Property Name is required."
                    : ""
                }
              />
            </Grid>
            <Grid item xs={3} sx={{ padding: "1%" }}>
              <TextField
                id="phone_number"
                label="Phone Number *"
                name="phone_number"
                value={userVerificationIForm.values.phone_number}
                onChange={userVerificationIForm.handleChange}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                error={
                  userVerificationIForm.errors.phone_number &&
                  userVerificationIForm.touched.phone_number
                    ? true
                    : false
                }
                helperText={
                  userVerificationIForm.errors.phone_number &&
                  userVerificationIForm.touched.phone_number
                    ? "Property Name is required."
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Date Of Birth *
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="dob"
                  value={
                    userVerificationIForm.values.dob
                      ? dayjs(userVerificationIForm.values.dob)
                      : null
                  }
                  onChange={(value) => {
                    userVerificationIForm.setFieldValue("dob", value);
                  }}
                  views={["year", "month", "day"]}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              {userVerificationIForm.errors.dob &&
                userVerificationIForm.touched.dob && (
                  <Typography
                    sx={{
                      color: "red",
                      display: "flex",
                    }}
                  >
                    Date Of Birth is required
                  </Typography>
                )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <Typography
                sx={{
                  mt: 2,
                  mb: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <label>ID / Driver License *</label>
                <input
                  accept="image/*"
                  type="file"
                  onChange={(event: any) => {
                    const filelist: any[] = Array.from(event.target.files);
                    setIdentificationImageList(filelist);

                    userVerificationIForm.setFieldValue(
                      "identification",
                      event.target.files
                    );
                  }}
                />
              </Typography>
              {userVerificationIForm.errors.identification &&
                userVerificationIForm.touched.identification && (
                  <Typography
                    sx={{
                      color: "red",
                      display: "flex",
                    }}
                  >
                    {userVerificationIForm.errors.identification}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <Typography
                sx={{
                  mt: 2,
                  mb: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <label>Selfie *</label>
                <input
                  accept="image/*"
                  type="file"
                  onChange={(event: any) => {
                    const filelist: any[] = Array.from(event.target.files);
                    setISelfieImageList(filelist);
                    userVerificationIForm.setFieldValue(
                      "selfie",
                      event.target.files
                    );
                  }}
                />
              </Typography>
              {userVerificationIForm.errors.selfie &&
                userVerificationIForm.touched.selfie && (
                  <Typography
                    sx={{
                      color: "red",
                      display: "flex",
                    }}
                  >
                    {userVerificationIForm.errors.selfie}
                  </Typography>
                )}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <Autocomplete
                fullWidth
                disablePortal
                options={searchResult.autocompleteSuggestions}
                onChange={(_, option) => updateFilterOptions(option)}
                onInputChange={(_, value) => handleAddressChange(value)}
                getOptionLabel={(option) => option.label}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="address"
                    name="Search Address"
                    label="Search Address"
                    error={
                      userVerificationIForm.errors.address &&
                      userVerificationIForm.touched.address
                        ? true
                        : false
                    }
                    helperText={
                      userVerificationIForm.errors.address &&
                      userVerificationIForm.touched.address
                        ? "Address is required."
                        : ""
                    }
                  />
                )}
                value={selectedAddress}
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="street_2"
                label="Apt/Unit"
                name="street_2"
                value={userVerificationIForm.values.street_2}
                onChange={userVerificationIForm.handleChange}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
              />
            </Grid>
          </Grid>
          {selectedAddress && (
            <Grid container>
              <Grid container>
                <Grid item xs={6} sx={{ padding: "1%" }}>
                  <TextField
                    id="street_1"
                    label="Address 1"
                    name="street_1"
                    value={userVerificationIForm.values.street_1}
                    sx={{ width: "100%", marginBottom: "5px" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} sx={{ padding: "1%" }}>
                  <TextField
                    id="city"
                    label="City"
                    name="city"
                    variant="outlined"
                    value={userVerificationIForm.values.city}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sx={{ padding: "1%" }}>
                  <TextField
                    id="state"
                    label="State"
                    name="state"
                    variant="outlined"
                    value={userVerificationIForm.values.state}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} sx={{ padding: "1%" }}>
                  <TextField
                    id="country"
                    label="Country"
                    name="country"
                    variant="outlined"
                    value={userVerificationIForm.values.country}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <LVLpmThemeButton
            variant="contained"
            onClick={() => handleCloseUserVerificationDialog("")}
          >
            Cancel
          </LVLpmThemeButton>
          <LVLpmThemeButton
            variant="contained"
            onClick={() => userVerificationIForm.submitForm}
            type="submit"
          >
            Submit Request
          </LVLpmThemeButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserVerificationDialog;
