import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

interface OurValuesCardProp {
  img: string;
  title: string;
  content: string;
}

const OurValuesCard = (props: OurValuesCardProp) => {
  const { img, title, content } = props;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ height: "350px" }}>
        <CardMedia sx={{ height: 185 }} image={img} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {content}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OurValuesCard;
