import axios from "axios";
import { backendURL, config } from "../utilities/utils";
import { displayConsoleError } from "../utilities/helperFunctions";

// property type, property_name , guess, beds and bathrooms, reviews, property images

//  property_type, im

const getPropertySpecifications = async (property_id: string) => {
  try {
    const response = await axios.post(
      `${backendURL}/api/v1/property/getPropertySpecifications`,
      {
        property_id,
      },
      config
    );
    return response.data;
  } catch (error: any) {
    displayConsoleError("getPropertySpecifications", error);
    return [];
  }
};

const getPropertiesDestination = async () => {
  try {
    const response = await axios.get(
      `${backendURL}/api/v1/property/getPropertiesDestination`,
      config
    );
    return response.data;
  } catch (error: any) {
    displayConsoleError("getPropertiesDestination", error);
    return [];
  }
};

export { getPropertySpecifications, getPropertiesDestination };
