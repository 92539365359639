import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import DashboardFooter from "../../components/reusableComponents/dashboardFooter/dashboardFooter";
import RenterCard from "../../components/reusableComponents/renterCard/renterCard";
import ImgPlaceholder from "../../assets/home-1.jpg";
import dayjs from "dayjs";

const PropertyReservations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );

  const { ownerBookings } = useAppSelector((state: any) => state.bookings);

  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (loggedIn) {
    } else {
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  return (
    <Box sx={{ padding: "0 20px 0 80px", width: "100%" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0" }}>
          <Button
            onClick={() => {
              window.history.back();
            }}
            variant="contained"
          >
            Back
          </Button>
          <Box
            sx={{
              margin: "1% 1% 1% 0%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                marginBottom: "1%",
              }}
            >
              Properties Reservation
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "1%" }}>
            <Grid container spacing={2}>
              {ownerBookings?.map((renter: any, index: number) => (
                <RenterCard
                  key={index}
                  color="#00DCDC"
                  bookingDetails={renter}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <DashboardFooter />
    </Box>
  );
};

export default PropertyReservations;
