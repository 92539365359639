import { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { updatePropertyOwner } from "../../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";

interface UpdatePropertyOwnerDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;

  selectedPropertyDetails: any;
}

const UpdatePropertyOwnerDialog = (props: UpdatePropertyOwnerDialogProps) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const { allPropertyUsers } = useAppSelector((state: any) => state.system);
  const { updatePropertyOwnerLoading } = useAppSelector(
    (state: any) => state.property
  );

  const dispatch = useAppDispatch();

  const [enableUpdatePropertyOwner, setEnableUpdatePropertyOwner] =
    useState(true);
  const [selectedPropertyOwner, setSelectedPropertyOwner] = useState<any>();

  const [updatePropertyOwnerError, setUpdatePropertyOwnerError] =
    useState(false);
  const [updatePropertyOwnerErrorMessage, setUpdatePropertyOwnerErrorMessage] =
    useState("");

  const handleCloseUpdatePropertyOwnerDialog = (event: any) => {
    setSelectedPropertyOwner(null);
    handleCloseCancel(event);
  };

  const submitUpdatePropertyOwnerDialog = () => {
    setUpdatePropertyOwnerError(false);
    setUpdatePropertyOwnerErrorMessage("");
    dispatch(
      updatePropertyOwner({
        property_id: selectedPropertyDetails?.property_id,
        user_id: selectedPropertyOwner?.user_id,
      })
    )
      .then((updatePropertyStatusResult) => {
        if (updatePropertyStatusResult?.payload?.status) {
          handleCloseUpdatePropertyOwnerDialog(
            updatePropertyStatusResult?.payload?.message
          );
        } else {
          setUpdatePropertyOwnerError(true);
          setUpdatePropertyOwnerErrorMessage(
            "Unable to update property owner, Please try again later or contact support"
          );
        }
      })
      .catch((updatePropertyStatusError) => {
        displayConsoleError("updatePropertyStatus", updatePropertyStatusError);
        setUpdatePropertyOwnerError(true);
        setUpdatePropertyOwnerErrorMessage(
          "Unable to update property owner, Please try again later or contact support"
        );
      });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updatePropertyOwnerLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update User Account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyOwnerDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid sx={{ height: "300px" }}>
          <Grid container>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="property_name"
                label="Property Name"
                name="property_name"
                value={selectedPropertyDetails?.property_name}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ padding: "1%" }}>
              <TextField
                id="address"
                label="Address"
                name="address"
                value={`${selectedPropertyDetails?.first_name} ${selectedPropertyDetails?.last_name}`}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ padding: "1%" }}>
            <Autocomplete
              disablePortal
              options={allPropertyUsers}
              onChange={(_: SyntheticEvent, value: any) => {
                if (
                  value &&
                  value?.user_id !== selectedPropertyDetails.user_id
                ) {
                  setEnableUpdatePropertyOwner(false);
                } else {
                  setEnableUpdatePropertyOwner(true);
                }
                setSelectedPropertyOwner(value);
              }}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="user_account"
                  name="user_account"
                  label="User Account"
                  error={false}
                />
              )}
              value={selectedPropertyOwner}
            />
          </Grid>
          {updatePropertyOwnerError && (
            <Grid
              container
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ color: "red" }}>
                {updatePropertyOwnerErrorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyOwnerDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyOwnerDialog()}
          disabled={enableUpdatePropertyOwner}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyOwnerDialog;
