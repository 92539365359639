import { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { updatePropertyStatus } from "../../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";

interface UpdatePropertyStatusDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

const UpdatePropertyStatusDialog = (props: UpdatePropertyStatusDialogProps) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const { allPropertyStatus } = useAppSelector((state: any) => state.system);
  const { updatePropertyStatusLoading } = useAppSelector(
    (state: any) => state.property
  );

  const dispatch = useAppDispatch();

  const [enableUpdatePropertyStatus, setEnablUpdatePropertyStatus] =
    useState(true);
  const [selectedStatus, setSelectedStatus] = useState<any>();

  const [updatePropertyStatusError, setUpdatePropertyStatusError] =
    useState(false);
  const [
    updatePropertyStatusErrorMessage,
    setUpdatePropertyStatusErrorMessage,
  ] = useState("");

  const handleCloseUpdatePropertyStatusDialog = (event: any) => {
    setSelectedStatus(null);
    handleCloseCancel(event);
  };

  const submitUpdatePropertyStatusDialog = () => {
    setUpdatePropertyStatusError(false);
    setUpdatePropertyStatusErrorMessage("");
    dispatch(
      updatePropertyStatus({
        property_id: selectedPropertyDetails?.property_id,
        status_id: selectedStatus?.status_id,
      })
    )
      .then((updatePropertyStatusResult) => {
        if (updatePropertyStatusResult?.payload?.status) {
          handleCloseUpdatePropertyStatusDialog(
            updatePropertyStatusResult?.payload?.message
          );
        } else {
          setUpdatePropertyStatusError(true);
          setUpdatePropertyStatusErrorMessage(
            "Unable to update property status, Please try again later or contact support"
          );
        }
      })
      .catch((updatePropertyStatusError) => {
        displayConsoleError("updatePropertyStatus", updatePropertyStatusError);
        setUpdatePropertyStatusError(true);
        setUpdatePropertyStatusErrorMessage(
          "Unable to update property status, Please try again later or contact support"
        );
      });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} scroll="paper">
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <Spinner loading={updatePropertyStatusLoading} />
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Property Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyStatusDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid sx={{ height: "300px" }}>
          <Grid container>
            <Grid item xs={12} md={4} lg={4} sx={{ padding: "1%" }}>
              <TextField
                id="property_name"
                label="Property Name"
                name="property_name"
                value={selectedPropertyDetails?.property_name}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} sx={{ padding: "1%" }}>
              <TextField
                id="full_address"
                label="Full Address"
                name="full_address"
                value={selectedPropertyDetails?.full_address}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} sx={{ padding: "1%" }}>
              <TextField
                id="current_status"
                label="Current Status"
                name="current_status"
                value={selectedPropertyDetails?.status_label}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} sx={{ padding: "1%" }}>
              <Autocomplete
                disablePortal
                options={allPropertyStatus ? allPropertyStatus : []}
                onChange={(_: SyntheticEvent, value: any) => {
                  if (
                    value &&
                    value?.status_id !== selectedPropertyDetails.status_id
                  ) {
                    setEnablUpdatePropertyStatus(false);
                  } else {
                    setEnablUpdatePropertyStatus(true);
                  }
                  setSelectedStatus(value);
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="user_account"
                    name="user_account"
                    label="User Account"
                    error={false}
                  />
                )}
                value={selectedStatus}
              />
            </Grid>
            {updatePropertyStatusError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {updatePropertyStatusErrorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyStatusDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyStatusDialog()}
          disabled={enableUpdatePropertyStatus}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyStatusDialog;
