import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

interface ProgressChartProps {
  title: string;
  data: string;
  value: number;
}

const ProgressChart = (props: ProgressChartProps) => {
  const { title, data, value } = props;
  return (
    <Box
      sx={{
        backgroundColor: "#EFF6FD",
        marginBottom: "25px",
        padding: "15px",
        borderRadius: "15px",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "bold", margin: "10px 0" }}>
        {data}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{ width: "80%" }}
        />
        <Typography variant="body1">{value}%</Typography>
      </Box>
    </Box>
  );
};
export default ProgressChart;
