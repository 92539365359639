import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import imgPlaceHolder from "../../assets/about-1.jpeg";
import RatingStar from "../../components/reusableComponents/ratingStar/ratingStar";
import ImgPlaceholder from "../../../src/assets/about-3.jpeg";
import {
  dbKeyConverterToLabel,
  specificationsIcons,
} from "../../utilities/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setPreviousLocation } from "../../store";
import {
  getPropertySpecification,
  getSpecificProperty,
} from "../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../utilities/helperFunctions";
import { LVLpmThemeButton } from "../../utilities/constants";
import BlockPorpertyReservationDialog from "../../components/dialogComponents/blockPropertyReservationDialog/blockPropertyReservationDialog";
import UpdatePropertyPriceDialog from "../../components/dialogComponents/updatePropertyPriceDialog/updatePropertyPriceDialog";
import UpdatePropertyImagesDialog from "../../components/dialogComponents/updatePropertyImagesDialog/updatePropertyImagesDialog";
import UpdatePropertyDetailsDialog from "../../components/dialogComponents/updatePropertyDetailsDialog/updatePropertyDetailsDialog";
import UpdatePropertyAmenitiesDialog from "../../components/dialogComponents/updatePropertyAmenitiesDialog/updatePropertyAmenitiesDialog";

const PropertyDetail = () => {
  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const { property } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeAmenities, setActiveAmenities] = useState<string[]>([]);
  const [openAmenities, setOpenAmenities] = useState(false);
  const [allActiveAmenities, setAllActiveAmenities] = useState<string[]>([]);
  const [bookedPropertyDetails, setbookedPropertyDetails] = useState<any>({});
  const [selectedPropertyData, setSelectedPropertyData] = useState<any>();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (loggedIn) {
      if (property && Number(property)) {
        dispatch(
          getSpecificProperty({
            property_id: property,
          })
        )
          .then(async (propertyResult: any) => {
            if (
              propertyResult.payload?.property_details &&
              propertyResult.payload?.property_details?.length > 0
            ) {
              setbookedPropertyDetails(
                propertyResult.payload?.property_details[0]
              );
              dispatch(
                getPropertySpecification({
                  property_id: property,
                })
              )
                .then(async (propertySpecificationResult: any) => {
                  if (
                    propertySpecificationResult.payload
                      ?.property_specifications &&
                    propertySpecificationResult.payload?.property_specifications
                      ?.length > 0
                  ) {
                    activeAmenitiesView(
                      propertySpecificationResult.payload
                        ?.property_specifications[0]
                    );
                  }
                })
                .catch((error) => {
                  displayConsoleError("getPropertySpecification", error);
                });
            } else {
              navigate("/dashboard");
            }
          })
          .catch((error: any) => {
            displayConsoleError("getSpecificProperty", error);
            navigate("/dashboard");
          });
      }
    } else {
      dispatch(setPreviousLocation(location.pathname));
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  const activeAmenitiesView = (propertySpecifications: any) => {
    let tempActiveAmenities = [];
    setAllActiveAmenities(
      Object.keys(propertySpecifications).filter(
        (key) => propertySpecifications[key] === true
      )
    );

    let amenitiesKeys = Object.keys(propertySpecifications).sort(
      () => 0.5 - Math.random()
    );

    for (const key of amenitiesKeys) {
      if (propertySpecifications[key] === true) {
        if (tempActiveAmenities.length < 8) {
          tempActiveAmenities.push(key);
        }
      }
    }
    setActiveAmenities(tempActiveAmenities);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleCloseAmenities = () => {
    setOpenAmenities(false);
  };

  const handleOpenAmenities = () => {
    setOpenAmenities(true);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const matchesX = useMediaQuery("(min-width:900px)");

  const [openUpdatePropertyDetails, setOpenUpdatePropertyDetails] =
    useState(false);
  const [openUpdatePropertyPrice, setOpenUpdatePropertyPrice] = useState(false);
  const [openUpdatePropertyAmenities, setOpenUpdatePropertyAmenities] =
    useState(false);
  const [openUpdateImages, setOpenUpdateImages] = useState(false);
  const [openBlockReservation, setOpenBlockReservation] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseUpdatePropertyDetails = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyDetails(false);
  };

  const handleCloseUpdatePropertyPrice = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyPrice(false);
  };

  const handleCloseUpdatePropertyAmenities = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyAmenities(false);
  };

  const handleCloseUpdateImages = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setSelectedPropertyData(null);
    setOpenUpdateImages(false);
  };
  const handleCloseBlockReservation = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setSelectedPropertyData(null);
    setOpenBlockReservation(false);
  };

  return (
    <>
      <Box sx={{ margin: "0 15px 0 80px" }}>
        <AccountDrawer handleDrawer={handleDrawer} open={open} />
        <Button
          onClick={() => {
            if (previousLocation?.includes("login")) {
              navigate("/dashboard");
            } else {
              window.history.back();
            }
          }}
          variant="contained"
          sx={{ marginTop: "1%" }}
        >
          Back
        </Button>
        <Grid container sx={{ marginBottom: "35px" }}>
          <Grid
            item
            xs={12}
            style={{
              padding: "15px 0 0 0",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                marginBottom: "25px",
              }}
            >
              Property Information
            </Typography>
          </Grid>
          {bookedPropertyDetails &&
            bookedPropertyDetails?.property_images_url?.split(",").length >
              0 && (
              <Carousel
                containerClass="carousel-container"
                itemClass="carousel-item"
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={5000}
              >
                {bookedPropertyDetails?.property_images_url
                  ?.split(",")
                  ?.map((image_url: string, index: number) => {
                    return (
                      <Box key={index} sx={{ width: "100%" }}>
                        <img
                          src={image_url}
                          style={{ width: "100%", height: "350px" }}
                        />
                      </Box>
                    );
                  })}
              </Carousel>
            )}
        </Grid>
        <Grid
          container
          sx={{
            paddingBottom: "20px",
            position: "relative",
            margin: matchesX ? "0 -15px 0 -15px" : "0 -15px 0 -30px",
          }}
        >
          <Box
            sx={{
              height: "400px",
              width: "100%",
              zIndex: "-1",
              backgroundImage: "linear-gradient(#D5EEF5, #fff)",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></Box>
          <Grid container sx={{ padding: "1%" }}>
            <Grid item xs={12} sx={{}}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {bookedPropertyDetails?.property_name}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#00b9d1", margin: "5px 0" }}
              >
                {bookedPropertyDetails?.full_address}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RatingStar rating={4} color="gold" />
                  <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                    ** Property reviews ** (325 Reviews)
                  </Typography>
                </Box> */}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: "10px 5px 20px #e6e6e6",
                  padding: "1%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "bold" }}
                >
                  Orverview
                </Typography>
                <Grid
                  container
                  xs={12}
                  sx={{
                    overflow: "auto",
                    // height: { xs: "150px", sm: "150px", md: "150px" },
                  }}
                >
                  <Typography variant="body1" sx={{}}>
                    {bookedPropertyDetails?.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={6} sx={{ padding: "1%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "10px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  Property Details:
                </Typography>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Property Type: {bookedPropertyDetails?.label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Listing Type: {bookedPropertyDetails?.listing_type_label}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Room Type: {bookedPropertyDetails?.room_type_label}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Rooms: {bookedPropertyDetails?.rooms}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Bedrooms: {bookedPropertyDetails?.bedrooms}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Bathrooms: {bookedPropertyDetails?.bathrooms}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Daily Rate: {bookedPropertyDetails?.price_per_day}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Cleaning Fees: {bookedPropertyDetails?.cleaning_fee}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Allowed Number of guests: {bookedPropertyDetails?.guests}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Access Code: {bookedPropertyDetails?.listing_type_label}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      Wifi Name (SSID): {bookedPropertyDetails?.wifi_name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Wifi Passowrd: {bookedPropertyDetails?.wifi_password}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{}}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      House Rules: {bookedPropertyDetails?.rules}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "1%",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1" sx={{ marginRight: "5px" }}>
                      Other: {bookedPropertyDetails?.extra}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={6} sx={{ padding: "1%" }}>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: "10px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Amenities:
                  </Typography>
                  <Grid container>
                    {activeAmenities.map((value: any, index) => {
                      let AmenitiesIcon =
                        specificationsIcons[
                          value as keyof typeof specificationsIcons
                        ];
                      let label: string = dbKeyConverterToLabel(value);
                      return (
                        <Grid
                          item
                          md={6}
                          sm={12}
                          xs={12}
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ marginRight: "5px" }}
                          >
                            {<img src={`${AmenitiesIcon}`} />}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Button
                    variant="contained"
                    onClick={handleOpenAmenities}
                    sx={{ marginTop: "15px" }}
                  >
                    Show All Amenities
                  </Button>
                </Box>
              </Grid>
              <Grid container sx={{ padding: "2%" }}>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => {
                      setSelectedPropertyData(bookedPropertyDetails);
                      setOpenBlockReservation(true);
                    }}
                  >
                    Block Reservation
                  </LVLpmThemeButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => {
                      setSelectedPropertyData(bookedPropertyDetails);
                      setOpenUpdatePropertyDetails(true);
                    }}
                  >
                    Edit Porperty Details
                  </LVLpmThemeButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => {
                      setSelectedPropertyData(bookedPropertyDetails);
                      setOpenUpdatePropertyPrice(true);
                    }}
                  >
                    Edit Price
                  </LVLpmThemeButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => {
                      setSelectedPropertyData(bookedPropertyDetails);
                      setOpenUpdatePropertyAmenities(true);
                    }}
                  >
                    Edit Amenenties
                  </LVLpmThemeButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <LVLpmThemeButton
                    variant="contained"
                    onClick={() => {
                      setSelectedPropertyData(bookedPropertyDetails);
                      setOpenUpdateImages(true);
                    }}
                  >
                    Edit Pictures
                  </LVLpmThemeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmenities}
        onClose={handleCloseAmenities}
        aria-labelledby="amenities-dialog-title"
        aria-describedby="amenities-dialog"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            padding: "10px 0 10px 10px",
            backgroundColor: "#00B9D1",
            color: "#fff",
          }}
          id="amenities-dialog-title"
        >
          Property Amenities
        </Typography>
        <DialogContent>
          <Grid container spacing={2}>
            {allActiveAmenities.map((value: string, index) => {
              let AmenitiesIcon =
                specificationsIcons[value as keyof typeof specificationsIcons];
              let label: string = dbKeyConverterToLabel(value);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Typography>{<img src={`${AmenitiesIcon}`} />}</Typography>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAmenities} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <UpdatePropertyAmenitiesDialog
        open={openUpdatePropertyAmenities}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyAmenities(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyDetailsDialog
        open={openUpdatePropertyDetails}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyDetails(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyImagesDialog
        open={openUpdateImages}
        handleCloseCancel={(event: any) => handleCloseUpdateImages(event)}
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyPriceDialog
        open={openUpdatePropertyPrice}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyPrice(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <BlockPorpertyReservationDialog
        open={openBlockReservation}
        handleCloseCancel={(event: any) => handleCloseBlockReservation(event)}
        selectedPropertyDetails={selectedPropertyData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

export default PropertyDetail;
