import { useEffect, useState } from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import {
  getStateAllCities,
  getAllStates,
  getCountryAllCities,
} from "../../../../store/thunks/propertyThunk";
import { validatePropertyAddress } from "../../../../store/thunks/systemThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";

interface ValidatePropertyAddressDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

const ValidatePropertyAddressDialog = (
  props: ValidatePropertyAddressDialogProps
) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;
  const { allCountries } = useAppSelector((state: any) => state.property);
  const { validatePropertyAddressLoading } = useAppSelector(
    (state: any) => state.system
  );

  const dispatch = useAppDispatch();

  const [enableValidatePropertyAddress, setEnablValidatePropertyAddress] =
    useState(true);

  const [validatePropertyAddressError, setValidatePropertyAddressError] =
    useState(false);
  const [
    validatePropertyAddressMessage,
    setValidatePropertyAddressErrorMessage,
  ] = useState("");

  const handleCloseValidatePropertyAddressDialog = (event: any) => {
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setIsState(false);
    setStates([]);
    setIsCities(false);
    setCities([]);
    handleCloseCancel(event);
  };

  const submitValidatePropertyAddressDialog = () => {
    const validate_address_payload = {
      country_id: selectedCountry?.country_id,
      state_id: isState ? selectedState?.state_id : selectedCity?.state_id,
      city_id: selectedCity?.city_id,
      address_id: selectedPropertyDetails?.address_id,
    };

    dispatch(validatePropertyAddress({ validate_address_payload }))
      .then((validatePropertyAddressResult: any) => {
        if (validatePropertyAddressResult?.payload?.status) {
          handleCloseValidatePropertyAddressDialog(
            validatePropertyAddressResult?.payload?.message
          );
        } else {
          setValidatePropertyAddressError(true);
          setValidatePropertyAddressErrorMessage(
            "Unable to update property status, Please try again later or contact support"
          );
        }
      })
      .catch((validatePropertyAddressError: any) => {
        displayConsoleError(
          "validatePropertyAddress",
          validatePropertyAddressError
        );
        setValidatePropertyAddressError(true);
        setValidatePropertyAddressErrorMessage(
          "Unable to update property status, Please try again later or contact support"
        );
      });
  };

  const [isState, setIsState] = useState(false);
  const [states, setStates] = useState([]);
  const [isCities, setIsCities] = useState(false);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();

  const checkAddressValidation = () => {
    if (isState) {
      if (selectedCountry && selectedState && selectedCity) {
        setEnablValidatePropertyAddress(false);
      } else {
        setEnablValidatePropertyAddress(true);
      }
    } else {
      if (selectedCountry && selectedCity) {
        setEnablValidatePropertyAddress(false);
      } else {
        setEnablValidatePropertyAddress(true);
      }
    }
  };

  useEffect(() => {
    checkAddressValidation();
  }, [selectedCountry, selectedState, selectedCity]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={validatePropertyAddressLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Validate Property Address
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseValidatePropertyAddressDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container sx={{ padding: "1%" }}>
          <TextField
            id="property_name"
            label="Property Name"
            name="property_name"
            value={selectedPropertyDetails?.property_name}
            sx={{
              width: "100%",
              marginBottom: "5px",
            }}
            disabled
          />
        </Grid>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="street_1"
              label="Street"
              name="street_1"
              value={selectedPropertyDetails?.street_1}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="street_2"
              label="Apt/Unit"
              name="street_2"
              value={selectedPropertyDetails?.street_2}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} sx={{ padding: "1%" }}>
            <TextField
              id="city"
              label="City"
              name="city"
              value={selectedPropertyDetails?.city}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
          {selectedPropertyDetails?.state !== "" && (
            <Grid item xs={4} sx={{ padding: "1%" }}>
              <TextField
                id="state"
                label="State"
                name="state"
                value={selectedPropertyDetails?.state}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={4} sx={{ padding: "1%" }}>
            <TextField
              id="country"
              label="Country"
              name="country"
              value={selectedPropertyDetails?.country}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={isState && isCities ? 4 : isState || isCities ? 6 : 12}
            sx={{ padding: "1%" }}
          >
            <Autocomplete
              options={allCountries}
              freeSolo
              onChange={(_, value: any) => {
                setSelectedCountry(value);
                if (value) {
                  dispatch(
                    getAllStates({ country_id: value?.country_id })
                  ).then((allStateCities: any) => {
                    if (allStateCities.payload.states?.length > 0) {
                      setIsState(true);
                      setStates(allStateCities.payload.states);
                      setIsCities(false);
                      setSelectedCity(undefined);
                      setCities([]);
                    } else {
                      dispatch(
                        getCountryAllCities({ country_id: value?.country_id })
                      ).then((allCountryCities: any) => {
                        setIsState(false);
                        setSelectedState(undefined);
                        setIsCities(true);
                        setCities(allCountryCities.payload.cities);
                      });
                    }
                  });
                } else {
                  setIsState(false);
                  setSelectedCity(undefined);
                  setSelectedState(undefined);
                  setIsCities(false);
                  setStates([]);
                  setCities([]);
                }
              }}
              getOptionLabel={(option: any) => option.country_name}
              isOptionEqualToValue={(option, value) =>
                option?.coutnry_id === value?.country_id
              }
              value={selectedCountry}
              sx={{
                marginBottom: "25px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"LVLPM Country"}
                  // error={
                  //   formik.errors.country && formik.touched.country ? true : false
                  // }
                  // helperText={
                  //   formik.errors.country && formik.touched.country
                  //     ? "Country is required."
                  //     : ""
                  // }
                />
              )}
            />
          </Grid>
          {isState ? (
            <Grid item xs={isCities ? 4 : 6} sx={{ padding: "1%" }}>
              <Autocomplete
                options={states}
                freeSolo
                onChange={(_, value: any) => {
                  setSelectedState(value);
                  if (value) {
                    dispatch(
                      getStateAllCities({ state_id: value?.state_id })
                    ).then((res: any) => {
                      setIsCities(true);
                      setCities(res.payload.cities);
                    });
                  } else {
                    setIsCities(false);
                    setSelectedCity(undefined);
                    setCities([]);
                  }
                }}
                getOptionLabel={(option: any) =>
                  option.state_name.charAt(0).toUpperCase() +
                  option.state_name.slice(1)
                }
                isOptionEqualToValue={(option, value) =>
                  option?.state_id === value?.state_id
                }
                value={selectedState}
                sx={{
                  marginBottom: "25px",
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"LVLPM State"} />
                )}
              />
            </Grid>
          ) : (
            ""
          )}
          {isCities ? (
            <Grid item xs={isState ? 4 : 6} sx={{ padding: "1%" }}>
              <Autocomplete
                options={cities}
                freeSolo
                onChange={(_, value: any) => {
                  setSelectedCity(value);
                }}
                getOptionLabel={(option: any) =>
                  option.city_name.charAt(0).toUpperCase() +
                  option.city_name.slice(1)
                }
                isOptionEqualToValue={(option, value) =>
                  option?.city_id === value?.city_id
                }
                value={selectedCity}
                sx={{ marginBottom: "25px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"LVLPM City"}
                    // error={
                    //   formik.errors.city && formik.touched.city ? true : false
                    // }
                    // helperText={
                    //   formik.errors.city && formik.touched.city
                    //     ? "Country is required."
                    //     : ""
                    // }
                  />
                )}
              />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {validatePropertyAddressError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {validatePropertyAddressMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseValidatePropertyAddressDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitValidatePropertyAddressDialog()}
          disabled={enableValidatePropertyAddress}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default ValidatePropertyAddressDialog;
