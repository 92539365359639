import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, Link } from "@mui/material";
import Chip from "@mui/material/Chip";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HomeIcon from "@mui/icons-material/Home";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import RatingStar from "../ratingStar/ratingStar";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
  bookingChekin,
  bookingChekout,
  getRenterBooking,
} from "../../../store/thunks/bookingsThunk";
import Spinner from "../spinner/spinner";
import { useEffect, useState } from "react";
import {
  checkInTimeCheck,
  checkOutTimeCheck,
  displayConsoleError,
} from "../../../utilities/helperFunctions";
import { createUserMessageList } from "../../../store/thunks/messagesThunk";

import dayjs from "dayjs";
import { setCreatedUserMessageList } from "../../../store";

interface ImageCardProps {
  bookingDetails: any;
  color: string;
  bookingsLength: number;
  key: any;
}

const ImageCard = (props: ImageCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { bookingDetails, color, bookingsLength } = props;

  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const { checkInLoading, checkOutLoading } = useAppSelector(
    (state: any) => state.bookings
  );

  const [enableCheckIn, setEnableCheckIn] = useState(true);
  const [enableCheckOut, setEnableCheckOut] = useState(true);

  const handleCheckIn = () => {
    dispatch(bookingChekin({ booking_id: bookingDetails?.booking_id }))
      .then((result: any) => {
        if (result?.error) {
        }
        dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
      })
      .catch((error) => {
        displayConsoleError("bookingChekin", error);
      });
  };

  const handleCheckOut = () => {
    dispatch(bookingChekout({ booking_id: bookingDetails?.booking_id }))
      .then((result: any) => {
        if (result?.error) {
        }
        dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
      })
      .catch((error) => {
        displayConsoleError("handleCheckOut", error);
      });
  };
  const handleEditBooking = () => {
    navigate(`/reservations/${bookingDetails?.booking_id}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const tempCheckIn = checkInTimeCheck(
        dayjs(bookingDetails?.check_in).format("MMM DD, YYYY")
      );
      setEnableCheckIn(tempCheckIn);
      const tempCheckOut = checkOutTimeCheck(
        dayjs(bookingDetails?.check_out).format("MMM DD, YYYY")
      );
      setEnableCheckOut(tempCheckOut);
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const tempCheckIn = checkInTimeCheck(
      dayjs(bookingDetails?.check_in).format("MMM DD, YYYY")
    );
    setEnableCheckIn(tempCheckIn);
    const tempCheckOut = checkOutTimeCheck(
      dayjs(bookingDetails?.check_out).format("MMM DD, YYYY")
    );
    setEnableCheckOut(tempCheckOut);
  }, []);

  const messageHost = () => {
    const temp_mesage_list_payload = {
      subtitle: bookingDetails?.property_name,
      sender_id: userInfo.user_id,
      reciever_id: bookingDetails?.owner_id,
    };
    dispatch(
      createUserMessageList({ message_list_payload: temp_mesage_list_payload })
    )
      .then((result: any) => {
        if (result?.payload?.message_list) {
          dispatch(setCreatedUserMessageList(result?.payload?.message_list));
        }
        navigate("/messages");
      })
      .catch((error) => {
        displayConsoleError("messageHost", error);
        navigate("/messages");
      });
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Spinner loading={checkInLoading} />
      <Spinner loading={checkOutLoading} />
      <Card
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid container item xs={12} sx={{ paddingLeft: "1%" }}>
          <Grid
            item
            xs={2}
            md={2}
            style={{
              display: "flex",
              justifyContent: "normal",
              alignItems: "normal",
            }}
          >
            <CardMedia
              component="img"
              image={
                bookingDetails?.property_images_url
                  ? bookingDetails?.property_images_url.split(",")[
                      Math.floor(
                        Math.random() *
                          bookingDetails?.property_images_url.split(",").length
                      )
                    ]
                  : null
              }
              alt="green iguana"
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%" },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardContent
              sx={{
                padding: { sm: "0 0 0 10px", md: "0px 0 0 10px" },
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                {bookingDetails?.property_name}
              </Typography>
              {/* <RatingStar rating={starRating} /> */}
              <Grid
                container
                style={{
                  display: "flex",
                  padding: "1%",
                  gap: 10,
                }}
              >
                <Chip
                  size="small"
                  sx={{ fontSize: "10px" }}
                  label={dayjs(bookingDetails?.check_in).format("MMM DD, YYYY")}
                />
                <Chip
                  size="small"
                  sx={{ fontSize: "10px" }}
                  label={dayjs(bookingDetails?.check_out).format(
                    "MMM DD, YYYY"
                  )}
                />
              </Grid>
              <Typography
                gutterBottom
                variant="caption"
                component="div"
                style={{ display: "flex", alignItems: "center", color: color }}
              >
                <FlashOnIcon style={{}} />
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => messageHost()}
                  sx={{
                    textDecoration: "none",
                    color: color,
                  }}
                >
                  Message Host
                </Link>
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                component="div"
                style={{ display: "flex", alignItems: "center", color: color }}
              >
                <HomeIcon style={{}} />
                <a
                  href={`https://www.google.ca/maps/place/${bookingDetails?.full_address?.replace(
                    " ",
                    "+"
                  )}/`}
                  style={{
                    textDecoration: "none",
                    color: color,
                  }}
                  target="_black"
                >
                  {bookingDetails?.full_address}
                </a>
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                component="div"
                style={{ display: "flex", alignItems: "center", color: color }}
              >
                <LockOutlinedIcon style={{}} />
                <Typography variant="caption" style={{}}>
                  {bookingDetails?.status_id === "3"
                    ? "****"
                    : bookingDetails?.access_code}
                </Typography>
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Wifi Name:{" "}
                {bookingDetails?.status_id === "3"
                  ? "****"
                  : bookingDetails?.wifi_name}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Wifi Password:{" "}
                {bookingDetails?.status_id === "3"
                  ? "****"
                  : bookingDetails?.wifi_password}
              </Typography>
            </CardContent>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{
              display: "flex",
              justifyContent: "normal",
              alignItems: "normal",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                padding: { sm: "0 0 0 15px", md: "0px 0 0 10px" },
                borderLeft: "1px solid #DCDCDC",
                textAlign: "left",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                component="p"
                fontWeight={"bold"}
                style={{ fontSize: "0.75rem" }}
              >
                Check-in Instructions
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#DC8D3D",
                  marginBottom: "10px",
                  fontSize: "0.75rem",
                }}
              >
                Self Check-in
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", fontSize: "0.75rem" }}
              >
                {bookingDetails?.street_2 !== "" &&
                  `Unit/Apt: ${bookingDetails?.street_2}`}
                <br />
                Check In Time:{" "}
                {dayjs(bookingDetails?.check_in).format("MMM DD, YYYY")} at 3PM{" "}
                <br />
                Check Out Time:{" "}
                {dayjs(bookingDetails?.check_out).format("MMM DD, YYYY")} at
                11AM <br />
                Quiet Hours between 11pm & 7am.
              </Typography>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  gap: 2,
                }}
              >
                {bookingDetails?.status_id === "3" && (
                  <Chip
                    disabled={enableCheckIn}
                    onClick={() => handleCheckIn()}
                    label={"Check In"}
                  />
                )}
                {bookingDetails?.status_id === "4" && (
                  <Chip
                    disabled={enableCheckOut}
                    onClick={() => handleCheckOut()}
                    label={"Check Out"}
                  />
                )}
                <Chip onClick={() => handleEditBooking()} label={"View"} />
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ImageCard;
