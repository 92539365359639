import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  Snackbar,
} from "@mui/material";
import AccountDrawer from "../../../components/commonComponents/accountDrawer/accountDrawer";
import DashboardFooter from "../../../components/reusableComponents/dashboardFooter/dashboardFooter";
import {
  dbKeyConverterToLabel,
  specificationsIcons,
} from "../../../utilities/utils";
import RatingStar from "../../../components/reusableComponents/ratingStar/ratingStar";
import { setPreviousLocation } from "../../../store";
import {
  bookingChekin,
  bookingChekout,
  getBookingPayments,
  getRenterBooking,
  getSpecificBooking,
} from "../../../store/thunks/bookingsThunk";
import {
  getPropertySpecification,
  getSpecificProperty,
} from "../../../store/thunks/propertyThunk";
import { getPropertySpecifications } from "../../../apis/listingsApis";
import Carousel from "react-multi-carousel";
import Spinner from "../../../components/reusableComponents/spinner/spinner";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LVLpmThemeButtonOther } from "../../../utilities/constants";
import {
  checkInTimeCheck,
  checkOutTimeCheck,
  displayConsoleError,
} from "../../../utilities/helperFunctions";
import CancelBookingDialog from "../../../components/dialogComponents/cancelBookingDialog/cancelBookingDialog";
import UpdateBookingDialog from "../../../components/dialogComponents/updateBookingDialog/updatebookingDialog";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const ReservationDetail = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { reservation_id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openUpdateBooking, setOpenUpdateBooking] = useState(false);
  const [openCancelBooking, setOpenCancelBooking] = useState(false);

  const handleUpdateBooking = () => {
    setOpenUpdateBooking(true);
  };
  const handleCloseUpdateBooking = (event: string) => {
    if (event !== "") {
      getBookingInformation();
      setOpenSnackbar(true);
      setSnackbarMessage(event);
    }
    setOpenUpdateBooking(false);
  };
  const handleCancelBooking = () => {
    setOpenCancelBooking(true);
  };
  const handleCloseCancelBooking = (message: string) => {
    setOpenCancelBooking(false);
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    }
    getBookingInformation();
  };

  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );
  const { specificPropertyLoading } = useAppSelector(
    (state: any) => state.property
  );
  const { specificBookingLoading } = useAppSelector(
    (state: any) => state.bookings
  );
  const [bookedDetails, setbookedDetails] = useState<any>({});
  const [bookedPropertyDetails, setBookedPropertyDetails] = useState<any>({});
  const [activeAmenities, setActiveAmenities] = useState<any[]>([]);

  const [enableCheckIn, setEnableCheckIn] = useState(true);
  const [enableCheckOut, setEnableCheckOut] = useState(true);

  const handleCheckIn = () => {
    dispatch(bookingChekin({ booking_id: bookedDetails?.status_id }))
      .then((result: any) => {
        if (result?.error) {
        }
        dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
      })
      .catch((error) => {
        displayConsoleError("bookingChekin", error);
      });
  };

  const handleCheckOut = () => {
    dispatch(bookingChekout({ booking_id: bookedDetails?.status_id }))
      .then((result: any) => {
        if (result?.error) {
        }
        dispatch(getRenterBooking({ user_id: userInfo?.user_id }));
      })
      .catch((error) => {
        displayConsoleError("handleCheckOut", error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const tempCheckIn = checkInTimeCheck(bookedDetails?.check_in);
      setEnableCheckIn(tempCheckIn);
      const tempCheckOut = checkOutTimeCheck(bookedDetails?.check_out);
      setEnableCheckOut(tempCheckOut);
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const tempCheckIn = checkInTimeCheck(bookedDetails?.check_in);
    setEnableCheckIn(tempCheckIn);
    const tempCheckOut = checkOutTimeCheck(bookedDetails?.check_out);
    setEnableCheckOut(tempCheckOut);
  }, []);

  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen(!open);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const activeAmenitiesView = (selectedPropertyDetails: any) => {
    let tempActiveAmenities = [];
    setAllActiveAmenities(
      Object.keys(selectedPropertyDetails).filter(
        (key) => selectedPropertyDetails[key] === true
      )
    );

    let amenitiesKeys = Object.keys(selectedPropertyDetails).sort(
      () => 0.5 - Math.random()
    );

    for (const key of amenitiesKeys) {
      if (selectedPropertyDetails[key] === true) {
        if (tempActiveAmenities.length < 8) {
          tempActiveAmenities.push(key);
        }
      }
    }
    setActiveAmenities(tempActiveAmenities);
  };

  const getBookingInformation = () => {
    checkCancellation();
    dispatch(getSpecificBooking({ booking_id: reservation_id }))
      .then((bookingResult: any) => {
        if (
          bookingResult.payload?.renter_bookings &&
          bookingResult.payload?.renter_bookings?.length > 0
        ) {
          setbookedDetails(bookingResult.payload?.renter_bookings[0]);
          dispatch(
            getSpecificProperty({
              property_id:
                bookingResult.payload?.renter_bookings[0]?.property_id,
            })
          )
            .then(async (propertyResult: any) => {
              if (
                propertyResult.payload?.property_details &&
                propertyResult.payload?.property_details?.length > 0
              ) {
                setBookedPropertyDetails(
                  propertyResult.payload?.property_details[0]
                );
                dispatch(
                  getBookingPayments({
                    booking_id:
                      bookingResult.payload?.renter_bookings[0]?.booking_id,
                  })
                );
                dispatch(
                  getPropertySpecification({
                    property_id:
                      bookingResult.payload?.renter_bookings[0]?.property_id,
                  })
                )
                  .then(async (propertySpecificationResult: any) => {
                    if (
                      propertySpecificationResult.payload
                        ?.property_specifications &&
                      propertySpecificationResult.payload
                        ?.property_specifications?.length > 0
                    ) {
                      activeAmenitiesView(
                        propertySpecificationResult.payload
                          ?.property_specifications[0]
                      );
                    }
                  })
                  .catch((error) => {
                    displayConsoleError("getPropertySpecification", error);
                  });
              } else {
                navigate("/dashboard");
              }
            })
            .catch((error) => {
              displayConsoleError("getSpecificProperty", error);
              navigate("/dashboard");
            });
        } else {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        displayConsoleError("getSpecificBooking", error);
        navigate("/dashboard");
      });
  };

  const checkCancellation = () => {};

  useEffect(() => {
    if (loggedIn) {
      if (reservation_id && Number(reservation_id)) {
        getBookingInformation();
      }
    } else {
      dispatch(setPreviousLocation(location.pathname));
      navigate("/login");
    }
  }, [navigate, loggedIn]);

  const matchesX = useMediaQuery("(min-width:900px)");
  const [allActiveAmenities, setAllActiveAmenities] = useState<string[]>([]);
  const [openAmenities, setOpenAmenities] = useState(false);

  const handleOpenAmenities = () => {
    setOpenAmenities(true);
  };

  const handleCloseAmenities = () => {
    setOpenAmenities(false);
  };

  return (
    <Box sx={{ padding: "0 20px 0 80px", width: "100%" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Button
        onClick={() => {
          if (previousLocation?.includes("login")) {
            navigate("/dashboard");
          } else {
            window.history.back();
          }
        }}
        variant="contained"
        sx={{ margin: "1%" }}
      >
        Back
      </Button>
      <Grid item xs={12} sx={{ padding: "20px 0 0 0" }}>
        <Container sx={{ padding: "2% 0" }}>
          {bookedPropertyDetails &&
            bookedPropertyDetails?.property_images_url?.split(",").length >
              0 && (
              <Carousel
                containerClass="carousel-container"
                itemClass="carousel-item"
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={5000}
              >
                {bookedPropertyDetails?.property_images_url
                  ?.split(",")
                  ?.map((image_url: string, index: number) => {
                    return (
                      <Box key={index} sx={{ width: "100%" }}>
                        <img
                          src={image_url}
                          style={{ width: "100%", height: "350px" }}
                        />
                      </Box>
                    );
                  })}
              </Carousel>
            )}
        </Container>
        <Box
          sx={{
            paddingBottom: "20px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "400px",
              width: "100%",
              zIndex: "-1",
              backgroundImage: "linear-gradient(#D5EEF5, #fff)",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></Box>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {bookedPropertyDetails.property_name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#00b9d1", margin: "5px 0" }}
                >
                  {bookedPropertyDetails.street_1}
                  {", "}
                  {bookedPropertyDetails.street_2}
                  {bookedPropertyDetails.city}{" "}
                  {bookedPropertyDetails.state
                    ? bookedPropertyDetails.state
                    : bookedPropertyDetails.country}
                </Typography>
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <RatingStar
                    rating={bookedPropertyDetails.review}
                    color="gold"
                  />
                  <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                    {bookedPropertyDetails.review} (325 Reviews)
                  </Typography>
                </Box> */}
              </Grid>
              <Grid
                item
                md={7}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "#fff",
                  margin: "20px 0 0 17px",
                  boxShadow: "10px 5px 20px #e6e6e6",
                  padding: matchesX ? "30px 100px 30px 30px" : "15px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Orverview
                  </Typography>
                  <Typography variant="body1">
                    {bookedPropertyDetails.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4.8} sm={12} xs={12}>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Amenities:
                  </Typography>
                  <Grid container>
                    {activeAmenities.map((value: any, index) => {
                      let AmenitiesIcon =
                        specificationsIcons[
                          value as keyof typeof specificationsIcons
                        ];
                      let label: string = dbKeyConverterToLabel(value);
                      return (
                        <Grid
                          item
                          md={6}
                          sm={12}
                          xs={12}
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ marginRight: "5px" }}
                          >
                            {<img src={`${AmenitiesIcon}`} />}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <LVLpmThemeButtonOther
                    variant="contained"
                    onClick={handleOpenAmenities}
                  >
                    Show All Amenities
                  </LVLpmThemeButtonOther>
                </Box>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                >
                  {bookedDetails?.status_id === "3" && (
                    <Chip
                      disabled={enableCheckIn}
                      onClick={() => handleCheckIn()}
                      label={"Check In"}
                      sx={{ width: "100%" }}
                    />
                  )}
                  {bookedDetails?.status_id === "4" && (
                    <Chip
                      sx={{ width: "100%", fontSize: "16px" }}
                      disabled={enableCheckOut}
                      onClick={() => handleCheckOut()}
                      label={"Check Out"}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container sx={{ marginTop: "25px" }}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sx={{ marginBottom: "10px" }}>
              <Typography variant="h5">Reservation</Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Check In :
              </Typography>
              <Chip
                // size="small"
                sx={{ fontSize: "10px", marginLeft: "2%" }}
                label={dayjs(new Date(bookedDetails?.check_in))
                  .utc()
                  .format("MMM DD, YYYY")}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Check Out :
              </Typography>
              <Chip
                // size="small"
                sx={{ fontSize: "10px", marginLeft: "2%" }}
                label={dayjs(new Date(bookedDetails?.check_out))
                  .utc()
                  .format("MMM DD, YYYY")}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Guests : {bookedDetails?.number_of_guests}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                ${" "}
                {bookedPropertyDetails.state === "CA"
                  ? bookedPropertyDetails.price_per_day
                  : bookedDetails.price_per_day_ca}
                /Night
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{
            marginTop: "25px",
            marginBottom: "150px",
          }}
        >
          <Grid container>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                boxShadow: "10px 10px 20px #e6e6e6",
                padding: "0 0 30px 0",
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#313D41",
                  borderRadius: "15px 15px 0 0",
                  padding: "20px 50px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  Details
                </Typography>
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  Amount
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "20px 50px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                  }}
                >
                  <Typography variant="body1">
                    $
                    {bookedPropertyDetails?.state === "CA"
                      ? bookedPropertyDetails?.price_per_day
                      : bookedDetails?.price_per_day_ca}
                    x {bookedDetails?.nights} nights
                  </Typography>
                  <Typography variant="body1">
                    ${bookedDetails?.stay_price}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                  }}
                >
                  <Typography variant="body1">
                    Entire Stay Cleaning Fee
                  </Typography>
                  <Typography variant="body1">
                    ${bookedPropertyDetails?.cleaning_fee}
                  </Typography>
                </Box>
                {bookedPropertyDetails?.state === "CA" && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "25px",
                    }}
                  >
                    <Typography variant="body1">LVLPm Fee (.033%)</Typography>
                    <Typography variant="body1">
                      ${bookedDetails?.lvlpm_fee}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                  }}
                >
                  <Typography variant="body1">Taxes</Typography>
                  <Typography variant="body1">
                    ${bookedDetails?.taxes}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "25px",
                    borderTop: "1px solid #e6e6e6",
                    borderBottom: "1px solid #e6e6e6",
                    padding: "15px 0",
                  }}
                >
                  <Typography variant="h6">Total (USD)</Typography>
                  <Typography variant="h6">
                    {bookedDetails?.total_price}
                  </Typography>
                </Box>
                <Grid sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <LVLpmThemeButtonOther
                    variant="contained"
                    onClick={() => handleUpdateBooking()}
                  >
                    Update
                  </LVLpmThemeButtonOther>

                  <LVLpmThemeButtonOther
                    variant="contained"
                    onClick={() => handleCancelBooking()}
                  >
                    Cancel
                  </LVLpmThemeButtonOther>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}></Grid>
          </Grid>
        </Container>
      </Grid>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmenities}
        onClose={handleCloseAmenities}
        aria-labelledby="amenities-dialog-title"
        aria-describedby="amenities-dialog"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            padding: "10px 0 10px 10px",
            backgroundColor: "#00B9D1",
            color: "#fff",
          }}
          id="amenities-dialog-title"
        >
          Property Amenities
        </Typography>
        <DialogContent>
          <Grid container spacing={2}>
            {allActiveAmenities.map((value: string, index) => {
              let AmenitiesIcon =
                specificationsIcons[value as keyof typeof specificationsIcons];
              let label: string = dbKeyConverterToLabel(value);
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <Typography>{<img src={`${AmenitiesIcon}`} />}</Typography>
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseAmenities()} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CancelBookingDialog
        open={openCancelBooking}
        handleCloseCancel={(event) => handleCloseCancelBooking(event)}
        propertyDetails={bookedPropertyDetails}
        bookingDetails={bookedDetails}
      />
      <UpdateBookingDialog
        open={openUpdateBooking}
        handleCloseUpdate={(event: string) => handleCloseUpdateBooking(event)}
        propertyDetails={bookedPropertyDetails}
        bookingDetails={bookedDetails}
      />
      <DashboardFooter />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
      <Spinner loading={specificBookingLoading} />
      <Spinner loading={specificPropertyLoading} />
    </Box>
  );
};

export default ReservationDetail;
