import { useState, useRef } from "react";
import { Input } from "react-chat-elements";
import { Box, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { createUserMessage } from "../../store/thunks/messagesThunk";
import { LVLpmThemeButton } from "../../utilities/constants";
import { setSentMessage } from "../../store";

const MessageInputComponent = (props) => {
  const dispatch = useAppDispatch();
  const [messageContent, setMessageContent] = useState("");
  const inputRef = useRef(null);

  const { userInfo } = useAppSelector((state) => state.authentication);
  const { currentUserMessageList } = useAppSelector((state) => state.messages);

  const handleInput = (message) => {
    setMessageContent(message);
  };

  const handleMessageSubmit = () => {
    if (messageContent === "") {
      return;
    }

    let reciever_id;
    if (userInfo.user_id === currentUserMessageList?.info?.sender_id) {
      reciever_id = currentUserMessageList?.info?.reciever_id;
    } else if (userInfo.user_id === currentUserMessageList?.info?.reciever_id) {
      reciever_id = currentUserMessageList?.info?.sender_id;
    }

    let user_message_payload = {
      message: messageContent,
      sender_id: userInfo.user_id,
      reciever_id: reciever_id,
      message_list_id: currentUserMessageList?.id,
    };

    dispatch(createUserMessage({ message_payload: user_message_payload }))
      .then((result) => {
        dispatch(setSentMessage(messageContent));
        setMessageContent("");
        inputRef.current.value = "";
      })
      .catch((error) => {
        displayConsoleError("handleMessageSubmit", error);
      });
  };

  return (
    <Box
      sx={{
        border: "1px solid #dbdbdb",
        padding: "10px 10px 10px 0",
        backgroundColor: "#fff",
      }}
    >
      <Input
        referance={inputRef}
        placeholder="Type here..."
        multiline={true}
        onChange={(e) => handleInput(e.target.value)}
        value={messageContent}
        // onSubmit={() => handleMessageSubmit()}
        // onReset={() => handleMessageSubmit()}
        // clear={(clear) => clear()}
        rightButtons={
          <LVLpmThemeButton
            variant="contained"
            color="success"
            onClick={() => handleMessageSubmit()}
          >
            Send
          </LVLpmThemeButton>
        }
      />
    </Box>
  );
};

export default MessageInputComponent;
