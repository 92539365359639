import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../utilities/constants";
import { getPropertySpecifications } from "../../../apis/listingsApis";
import { dbKeyConverterToLabel } from "../../../utilities/utils";
import { displayConsoleError } from "../../../utilities/helperFunctions";
import { updatePropertySpecifications } from "../../../store/thunks/propertyThunk";
import Spinner from "../../reusableComponents/spinner/spinner";

interface UpdatePropertyAmenitiesDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;

  selectedPropertyDetails: any;
}

const UpdatePropertyAmenitiesDialog = (
  props: UpdatePropertyAmenitiesDialogProps
) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const { updatePropertySpecificationsLoading } = useAppSelector(
    (state: any) => state.property
  );

  const [allPropertySpecifications, setAllPropertySpecifications] =
    useState<any>();

  const [
    updatePropertySpecificationsError,
    setUpdatePropertySpecificationsError,
  ] = useState(false);
  const [
    updatePropertySpecificationsErrorMessage,
    setUpdatePropertySpecificationsErrorMessage,
  ] = useState("");

  const dispatch = useAppDispatch();

  const handleCloseUpdatePropertyAmenitiesDialog = (event: any) => {
    handleCloseCancel(event);
  };

  const submitUpdatePropertyAmenitiesDialog = () => {
    setUpdatePropertySpecificationsError(false);
    setUpdatePropertySpecificationsErrorMessage("");
    dispatch(
      updatePropertySpecifications({
        property_specifications_payload: selectedPropertySpecification,
        all_property_specification: specificationColumn,
        property_id: selectedPropertyDetails?.property_id,
      })
    )
      .then((updatePropertySpecificationsResult: any) => {
        if (updatePropertySpecificationsResult?.payload?.status) {
          handleCloseUpdatePropertyAmenitiesDialog(
            updatePropertySpecificationsResult?.payload?.message
          );
        } else {
          setUpdatePropertySpecificationsError(true);
          setUpdatePropertySpecificationsErrorMessage(
            "Unable to update property Amenities. Please try again later or contact support"
          );
        }
      })
      .catch((updatePropertySpecificationsError: any) => {
        displayConsoleError(
          "updatePropertySpecifications",
          updatePropertySpecificationsError
        );
        setUpdatePropertySpecificationsError(true);
        setUpdatePropertySpecificationsErrorMessage(
          "Unable to update property Amenities, Please try again later or contact support"
        );
      });
  };

  const [specificationColumn, setSpecificationColumn] = useState<any[]>([]);
  const [selectedPropertySpecification, setSelectedPropertySpecification] =
    useState<any[]>([]);

  useEffect(() => {
    let tempColumn: any[] = [];
    let tempSelectedColumn: any[] = [];
    getPropertySpecifications(selectedPropertyDetails?.property_id).then(
      (getPropertySpecificationsResult: any) => {
        if (
          getPropertySpecificationsResult?.property_specifications?.length > 0
        ) {
          for (const [key, value] of Object.entries(
            getPropertySpecificationsResult?.property_specifications[0]
          )) {
            if (!["property_specification_id", "property_id"].includes(key)) {
              tempColumn.push({
                label: dbKeyConverterToLabel(key),
                value: key,
              });
              if (value) {
                tempSelectedColumn.push({
                  label: dbKeyConverterToLabel(key),
                  value: key,
                });
              }
            }
          }

          setSelectedPropertySpecification(tempSelectedColumn);
          setSpecificationColumn(tempColumn);
        }
      }
    );
  }, [selectedPropertyDetails]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updatePropertySpecificationsLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Property Amenities
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyAmenitiesDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} sx={{ padding: "1%" }}>
            <TextField
              id="property_name"
              label="Property Name"
              name="property_name"
              value={selectedPropertyDetails?.property_name}
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container sx={{ padding: "1%" }}>
          <Autocomplete
            multiple
            options={specificationColumn}
            onChange={(_, value: any) =>
              setSelectedPropertySpecification(value)
            }
            slotProps={{}}
            ListboxProps={{}}
            defaultValue={selectedPropertySpecification}
            value={selectedPropertySpecification}
            sx={{ width: "100%" }}
            filterSelectedOptions={true}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ textTransform: "capitalize" }}
                label={"Select Property Amenities"}
                id="property_specification"
                name="property_specification"
              />
            )}
          />
        </Grid>
        {updatePropertySpecificationsError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {updatePropertySpecificationsErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyAmenitiesDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyAmenitiesDialog()}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyAmenitiesDialog;
