import React, { useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "./../../../assets/logo.jpg";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getSupportUsers,
  logoutUser,
} from "../../../store/thunks/authenticationThunk";
import { logout, reset, resetMessage } from "../../../store";
import Spinner from "../../reusableComponents/spinner/spinner";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedIn, userInfo, logoutLoading } = useAppSelector(
    (state: any) => state.authentication
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElManagement, setAnchorElManagement] =
    React.useState<null | HTMLElement>(null);
  const [anchorElCompany, setAnchorElCompany] =
    React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenManagementMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElManagement(event.currentTarget);
  };
  const handleOpenCompanyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCompany(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseManagementMenu = () => {
    setAnchorElManagement(null);
  };

  const handleCloseCompanyMenu = () => {
    setAnchorElCompany(null);
  };
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  function userLogout() {
    dispatch(logoutUser({ session_id: userInfo?.session_id }));
    dispatch(logout());
    dispatch(resetMessage());
    navigate("/");
  }

  return (
    <AppBar
      color="transparent"
      sx={{
        backgroundColor: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
    >
      <Container maxWidth={false}>
        <Spinner loading={logoutLoading} />
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={"/"}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
              }}
            >
              <img style={{ maxHeight: "50px" }} src={logo} alt={"LVLPM"} />
            </Typography>
          </Grid>
          {/* mobile responsive view dropdown START */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <DehazeIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              disableScrollLock={true}
            >
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/");
                }}
              >
                <Typography textAlign="center">Home</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate("/listings");
                }}
              >
                <Typography textAlign="center">Listings</Typography>
              </MenuItem>
              {false && (
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate("/management/vacationrental");
                  }}
                >
                  <Typography textAlign="center">Management</Typography>
                </MenuItem>
              )}
              {false && (
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate("/company/aboutUs");
                  }}
                >
                  <Typography textAlign="center">Company</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          {/* mobile responsive view END */}
          {/* center nav links START */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              m: 1,
            }}
            onClick={preventDefault}
          >
            <NavLink
              to={"/"}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  textUnderlineOffset: 5,
                  color: isActive ? "#00B9D1" : "black",
                  fontWeight: "bold",
                  marginRight: "5em",
                };
              }}
            >
              Home
            </NavLink>

            <NavLink
              to={"/listings"}
              style={({ isActive, isPending, isTransitioning }) => {
                return {
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  textUnderlineOffset: 5,
                  color: isActive ? "#00B9D1" : "black",
                  fontWeight: "bold",
                };
              }}
            >
              Listings
            </NavLink>
            {false && (
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  border: "1px soldid red",
                }}
                onMouseLeave={() => handleCloseManagementMenu()}
              >
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      textUnderlineOffset: 5,
                      color: isActive ? "#0000CD" : "black",
                      fontWeight: "bold",
                    };
                  }}
                  aria-controls="menu-management"
                  aria-owns={anchorElManagement ? "menu-management" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(event) => handleOpenManagementMenu(event)}
                  to={"/management"}
                >
                  Management{" "}
                  {Boolean(anchorElManagement) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </NavLink>
                <Menu
                  id="menu-management"
                  anchorEl={anchorElManagement}
                  open={Boolean(anchorElManagement)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={handleCloseManagementMenu}
                  disableRestoreFocus
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      m: 1,
                    }}
                  >
                    <NavLink
                      to={"/management/vacationrental"}
                      onClick={handleCloseManagementMenu}
                      style={({ isActive, isPending, isTransitioning }) => {
                        return {
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          textUnderlineOffset: 5,
                          color: isActive ? "#0000CD" : "black",
                          fontWeight: "bold",
                        };
                      }}
                    >
                      Vacation Rental
                    </NavLink>
                  </Box>
                </Menu>
              </Box>
            )}
            {false && (
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                }}
              >
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      textDecoration: isActive ? "underline" : "none",
                      textUnderlineOffset: 5,
                      color: isActive ? "#0000CD" : "black",
                      fontWeight: "bold",
                    };
                  }}
                  aria-controls="menu-company"
                  aria-haspopup="true"
                  onMouseOver={handleOpenCompanyMenu}
                  to={"/company"}
                >
                  Company{" "}
                  {Boolean(anchorElCompany) ? <ExpandLess /> : <ExpandMore />}
                </NavLink>
                <Menu
                  id="menu-company"
                  anchorEl={anchorElCompany}
                  open={Boolean(anchorElCompany)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={handleCloseCompanyMenu}
                  disableRestoreFocus
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      m: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/aboutUs"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        About US
                      </NavLink>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/realEstate"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        Real Estate
                      </NavLink>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/rentalProgram"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        Referal Program
                      </NavLink>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/testimonials"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        Testimonials
                      </NavLink>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/blog"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        Blog
                      </NavLink>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: 0.5,
                      }}
                    >
                      <NavLink
                        to={"/company/contactus"}
                        onClick={handleCloseCompanyMenu}
                        style={({ isActive, isPending, isTransitioning }) => {
                          return {
                            display: "flex",
                            alignItems: "center",
                            textDecoration: isActive ? "underline" : "none",
                            textUnderlineOffset: 5,
                            color: isActive ? "#0000CD" : "black",
                            fontWeight: "bold",
                          };
                        }}
                      >
                        Contact Us
                      </NavLink>
                    </Box>
                  </Box>
                </Menu>
              </Box>
            )}
          </Box>
          {/* center nav links END */}
          {/* user menu dropdown START */}
          <Grid
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Typography variant="h5" noWrap component="a" href={"/"}>
              <img style={{ maxHeight: "50px" }} src={logo} alt={"Test"} />
            </Typography>
          </Grid>
          <Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px", width: "300px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              disableScrollLock={true}
            >
              {loggedIn && (
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
              )}
              {/* {loggedIn && (
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/profile");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
              )} */}
              {!loggedIn && (
                <MenuItem
                  onClick={() => {
                    navigate("/login");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
              )}
              {!loggedIn && (
                <MenuItem
                  onClick={() => {
                    navigate("/register");
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Sign Up</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={() => navigate("/contact")}>
                <Typography textAlign="center">Help</Typography>
              </MenuItem>
              {loggedIn && (
                <MenuItem
                  onClick={() => {
                    userLogout();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          {/* user menu dropdown END */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
