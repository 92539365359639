import { createSlice } from "@reduxjs/toolkit";
import { getUserAddress } from "../../thunks/addressThunk";

interface AddressSlice {
  loading: Boolean;
  error: any;
  response: any;
  getUserAddress: Object;
}

const initialState: AddressSlice = {
  loading: false,
  error: null,
  response: null,
  getUserAddress: {},
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    resetAddress: (state) => {},
  },
  extraReducers(builder) {
    builder.addCase(getUserAddress.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getUserAddress.fulfilled, (state, action) => {
      state.loading = false;
      state.getUserAddress = action.payload;
    });
    builder.addCase(getUserAddress.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetAddress } = addressSlice.actions;
export const addressRedcuer = addressSlice.reducer;
