import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import DashboardFooter from "../../components/reusableComponents/dashboardFooter/dashboardFooter";
import HorizontalCard from "../../components/reusableComponents/horizontalCard/horizontalCard";

const Reservations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { previousLocation, loggedIn, userInfo } = useAppSelector(
    (state: any) => state.authentication
  );

  const { renterBookings } = useAppSelector((state: any) => state.bookings);

  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (loggedIn) {
    } else {
      navigate("/login");
    }
  }, [navigate, loggedIn]);
  return (
    <Box sx={{ padding: "0 20px 0 80px", width: "100%" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0" }}>
          <Button
            onClick={() => {
              window.history.back();
            }}
            variant="contained"
          >
            Back
          </Button>
          <Box
            sx={{
              margin: "1% 1% 1% 0%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                marginBottom: "1%",
              }}
            >
              Reservations
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "1%" }}>
            <Grid container spacing={2}>
              {renterBookings?.map((element: any, index: any) => {
                return (
                  <HorizontalCard
                    bookingsLength={2}
                    color={"#00DCDC"}
                    bookingDetails={element}
                    key={index}
                  />
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <DashboardFooter />
    </Box>
  );
};

export default Reservations;
