import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";

export default function AlternateTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.2rem", padding: "10px 0 0 0", maxWidth: "70%" }}
          >
            To revolutionize property management by delivering seamless,
            technology-driven experiences that create memorable stays for guests
            and provide effortless passive income for property owners,
            ultimately becoming the global leader in luxury and experiential
            accommodations.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              padding: "10px 0 0 0",
              textAlign: "left",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            Our mission at LVLpm is to provide hotel-quality stays in uniquely
            curated properties while empowering property owners with automated
            management solutions. By combining cutting-edge smart home
            techology, local partnerships, and personalized concierge services,
            we elevate the short-term rental market, ensuring exceptional guest
            experiencesand sustainable, passive income for owners.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="body1"
            sx={{ fontSize: "1.2rem", padding: "10px 0 0 0", maxWidth: "70%" }}
          >
            In 2023, Jake and Jean met for the first time during a job
            interview. Jake was interviewing Jean as a new lead developer for
            the platform. Now knowing they both shared the exact same vision, by
            the end of the call Jake, and Jean had become co-founder and
            partners.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              position: "relative",
              width: "32px",
              height: "33px",
              background: "#A6F5FFFF",
              borderRadius: "50%",
            }}
          >
            <TimelineDot
              sx={{
                position: "absolute",
                top: "-5px",
                left: "7px",
                width: "15px",
                height: "15px",
                background: "#1CE5FFFF",
                borderRadius: "50%",
              }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
              padding: "10px 0 0 0",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            2024
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              padding: "10px 0 0 0",
              textAlign: "left",
              maxWidth: "70%",
              marginLeft: "auto",
            }}
          >
            As 2024 begins, LVLpm's plans have only gotten bigger for the
            company. Currently they are building their new platform tailored to
            investors, travelers, and property managers. With ways to earn more
            for everyone!
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
