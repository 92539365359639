import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AddressEditForm from "./AddressEditForm";

interface ModalProps {
  open: boolean;
  handleClose: any;
  addressInfo: any;
}

const AddressEditModal = (props: ModalProps) => {
  const { open, handleClose, addressInfo } = props;

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1200px)");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          overflowY: matchesY && matchesX ? "none" : "scroll",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "65%",
          maxHeight: "55%",
          bgcolor: "background.paper",
          boxShadow: 30,
          p: 0,
          borderRadius: "15px",
          outline: 0,
        }}
      >
        <Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{
              fontWeight: "bold",
              padding: "10px 0 10px 10px",
              backgroundColor: "#00B9D1",
              color: "#fff",
              width: "100%",
            }}
          >
            Edit Profile
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <AddressEditForm addressInfo={addressInfo} />
      </Grid>
    </Modal>
  );
};

export default AddressEditModal;
