import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Typography,
  Paper,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { ResetPasswordInfo } from "../../types/formsTypes";
import { reset, setPreviousLocation } from "../../store";
import {
  resetPassword,
  forgotPasswordVerification,
} from "../../store/thunks/authenticationThunk";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = () => {
  const {
    forgotPasswordVerificationLoading,
    resetPasswordLoading,
    error,
    loggedIn,
    response,
    previousLocation,
  } = useAppSelector((state: any) => state.authentication);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfrim, setShowPasswordConfrim] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});

  const location = useLocation();
  const { token } = useParams();

  const resetPassowrdInitialValues: ResetPasswordInfo = {
    matchPassword: "",
    password: "",
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validateResetPassowrd = Yup.object({
    matchPassword: Yup.string().oneOf(
      [Yup.ref("password"), undefined],
      "Passwords must match"
    ),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/,
        "Must contain At least One Upercase, At least One special character, At least One digit."
      ),
  });
  const resetPasswordForm = useFormik({
    initialValues: resetPassowrdInitialValues,
    validationSchema: validateResetPassowrd,
    onSubmit: (values: ResetPasswordInfo) => {
      if (token && token !== "") {
        dispatch(setPreviousLocation(location.pathname));
        dispatch(
          resetPassword({
            password: values.password,
            user_id: userDetails.user_id,
          })
        )
          .then((result) => {
            if (result?.payload?.result) {
              navigate("/login");
            } else {
              navigate("/");
            }
          })
          .catch(() => {
            navigate("/");
          });
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfrim((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
    dispatch(reset());
    dispatch(setPreviousLocation(location.pathname));
    if (token && token !== "") {
      dispatch(forgotPasswordVerification({ token: token }))
        .then((result: any) => {
          if (result?.payload?.user_details) {
            setUserDetails(result?.payload?.user_details);
          } else {
            navigate("/");
          }
        })
        .catch(() => {
          navigate("/");
        });
    } else {
      navigate("/");
    }
    dispatch(reset());
  }, [navigate, loggedIn]);

  return (
    <Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={forgotPasswordVerificationLoading} />
        <Spinner loading={resetPasswordLoading} />
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={resetPasswordForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                <Grid item xs={12} sx={{}}>
                  <Typography>Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                    name="password"
                    fullWidth
                    value={resetPasswordForm.values.password}
                    error={
                      resetPasswordForm.touched.password &&
                      Boolean(resetPasswordForm.errors.password)
                    }
                    onChange={resetPasswordForm.handleChange}
                  />
                  {resetPasswordForm.touched.password &&
                    Boolean(resetPasswordForm.errors.password) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        <Typography>
                          {resetPasswordForm.errors.password}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Confirm Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password-match"
                    type={showPasswordConfrim ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordConfrim ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Confirm Password"
                    name="matchPassword"
                    fullWidth
                    value={resetPasswordForm.values.matchPassword}
                    error={
                      resetPasswordForm.touched.matchPassword &&
                      Boolean(resetPasswordForm.errors.matchPassword)
                    }
                    onChange={resetPasswordForm.handleChange}
                  />
                  {resetPasswordForm.touched.matchPassword &&
                    Boolean(resetPasswordForm.errors.matchPassword) && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        <Typography>
                          {resetPasswordForm.errors.matchPassword}
                        </Typography>
                      </Grid>
                    )}
                </Grid>
                {error && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#000000" }}
                    onClick={() => resetPasswordForm.submitForm}
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
function resetPasswordLinkVerification(arg0: { token: string }): any {
  throw new Error("Function not implemented.");
}
