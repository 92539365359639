import { Grid, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link } from "react-router-dom";

interface IconOptionCardProps {
  Img: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  title: string;
  route: string;
  handleSettings(title: string): void;
}

const IconOptionCard = (props: IconOptionCardProps) => {
  const { Img, title, route, handleSettings } = props;
  return (
    <Grid
      item
      xs={12}
      sm={4}
      sx={{
        border: "1px solid #D7E2EE",
        padding: "8px 0 8px 8px!important",
        marginBottom: "30px",
      }}
    >
      <Grid
        className="iconOptionCard"
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleSettings(route)}
      >
        <Grid
          sx={{
            borderRadius: "50%",
            width: "45px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Img />
        </Grid>
        {title}
      </Grid>
    </Grid>
  );
};

export default IconOptionCard;
