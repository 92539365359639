import { Box, Container, Grid, Typography } from "@mui/material";

import LVLpmLogo from "../../assets/logo.jpg";
import Footer from "../../components/commonComponents/footer/footer";

const ContactPage = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#fff", paddingBottom: "30px" }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "50px" }}>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img src={LVLpmLogo} style={{ width: "200px" }} />
              </Box>
              <Typography
                variant="body1"
                sx={{ margin: "35px 0 15px 0", fontWeight: "bold" }}
              >
                TERMS AND CONDITIONS
              </Typography>
              <Box sx={{ display: "flex", marginBottom: "15px" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginRight: "10px" }}
                >
                  Effective Date: 09/14/24
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                These Terms and Conditions (“Agreement”) govern your use of the
                services provided by{" "}
                <span style={{ fontWeight: "bold" }}>LVLpm LLC</span> (“LVLpm,”
                “we,” “us,” or “our”), including but not limited to the website
                located at [LVLpm.com], the mobile application, and any other
                related services (collectively, the "Services"). By accessing or
                using our Services, including booking or listing a property, you
                (“User,” “you,” or “your”) agree to comply with, and be bound
                by, all of the terms and conditions set forth in this Agreement.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                IF YOU DO NOT AGREE WITH ANY PART OF THIS AGREEMENT, YOU MUST
                IMMEDIATELY CEASE ACCESSING OR USING THE SERVICES. CONTINUED USE
                OF THE SERVICES CONSTITUTES ACCEPTANCE OF THIS AGREEMENT IN ITS
                ENTIRETY.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                1. Definitions
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                For the purposes of this Agreement:
                <br />
                <span style={{ fontWeight: "bold" }}>- "Account"</span> refers
                to the personalized user profile created by an individual or
                entity to access and utilize LVLpm’s services. The account
                enables users to manage bookings, properties, and other
                interactions with the platform, serving as the central point of
                all user-related activities on LVLpm.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- "Property"</span> refers
                to the accommodation, including but not limited to homes,
                apartments, or other lodging spaces, listed by property owners
                or property management companies on LVLpm’s platform. Properties
                are available for short-tem, mid-term, or long-term rentals, and
                each listing includes specific details regarding amenities,
                pricing, and availability.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- "Guest"</span> refers to
                a User of LVLpm who initiates and completes a booking to stay at
                a listed property. The term “Guest: applies to individuals or
                groups seeking accommodation through the platform, as well as
                those who benefit from LVLpm’s services during their stay.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- "Host"</span> refers to a
                User who lists a property on the LVLpm platform for the purpose
                of offering it for rental. Hosts can be individual property
                owners, property management companies, or other entities, and
                they are responsible for maintaining their listing’s accuracy
                and ensuring the property is prepared for guest stays.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- ”Booking”</span> refers
                to the formal process of securing a property for specific dates
                through the LVLpm platform. A booking encompasses the selected
                accommodation, set nights, pricing, and payment details,
                representing the guest’s commitment to the stay.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- ”Reservation”:</span>{" "}
                Refers to the finalized and confirmed booking for a guest’s
                stay, including specific dates, property details, and a unique
                reservation code. A reservation is tied to a booking and serves
                as the official record for the agreed-upon terms of the stay.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- ”Change”</span>
                <br />
                refers to any modification made to the dates or details of an
                existing reservation. Changes can include adjusting the length
                of stay, altering the check-in or check-out dates, or making
                other updates as permitted by the host and the platform’s
                policies.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  2. Eligibility to Use Services
                </span>
                <br />
                By using the Services, you represent and warrant that:
                <br />
                1. You are at least 18 years of age or the legal age of majority
                in your jurisdiction, and you have the legal capacity and
                authority to enter into this Agreement.
                <br />
                2. You will comply with all applicable laws, rules, and
                regulations.
                <br />
                3. All information that you provide to LVLpm is accurate,
                current, and complete, and you will keep such information
                updated as necessary.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  3. Account Registration
                </span>
                <br />
                To access certain features of the Services, you are required to
                create an Account. You agree to:
                <br />
                1. Provide accurate and complete information during the
                registration process. <br />
                2. Maintain the confidentiality of your Account credentials.{" "}
                <br />
                3. Be fully responsible for all activities that occur under your
                Account. <br />
                <br />
                LVLpm reserves the right, in its sole discretion, to refuse
                registration, terminate an account, or restrict access to the
                Services for any reason, including but not limited to a
                violation of this Agreement or applicable law.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                4. Bookings and Reservations
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4.1 Availability and Confirmation
                </span>{" "}
                <br />
                All bookings are subject to availability and must be confirmed
                by the Host or LVLpm, as applicable. Bookings are considered
                confirmed only when you receive a confirmation email from LVLpm.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>4.2 Payment Terms</span>{" "}
                <br />
                You agree to pay the full amount of the reservation, including
                any applicable fees (e.g., cleaning fees, booking fees, taxes),
                at the time of booking unless otherwise specified. All payments
                must be processed through LVLpm’s designated payment processing
                partner.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4.3 Cancellation and Refunds
                </span>
                <br />
                Cancellations by Guests must be made in accordance with the
                specific cancellation policy outlined in the Property listing.
                In the event of a cancellation:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  1. Guest-Initiated Cancellation:
                </span>{" "}
                Refunds (if any) will be processed in accordance with our
                cancellation policy. If no refund is due, the amount paid will
                be retained.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  2. Host-Initiated Cancellation:
                </span>{" "}
                If a Host or LVLpm cancels a confirmed booking, the Guest will
                receive a full refund, and LVLpm may assist the Guest in finding
                alternative accommodations at its sole discretion.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  4.4 Non-Refundable Fees
                </span>
                <br />
                Certain fees, such as booking fees or cleaning fees, may be
                non-refundable. The specific terms of each booking will govern
                the refundability of such fees.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                5. Use of Property and Guest Responsibilities
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>5.1 Guest Conduct</span>
                <br />
                Guests agree to: <br />
                1. Use the Property in a careful and lawful manner.
                <br />
                2. Abide by all rules, regulations, and policies communicated by
                the Host or displayed at the Property.
                <br />
                3. Not engage in any illegal activities or cause damage to the
                Property during their stay.
                <br />
                4. Refrain from using the Property for unauthorized commercial
                purposes, large gatherings, or events unless prior written
                consent is obtained from the Host.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  5.2 Damage and Security Deposit{" "}
                </span>{" "}
                <br />
                Guests may be responsible for paying a security deposit at the
                time of booking. The security deposit, if applicable, will be
                refunded after check-out, provided no damage to the Property is
                reported. Guests are responsible for any damage or excessive
                wear and tear beyond ordinary use, and the Host reserves the
                right to deduct any costs associated with such damage from the
                security deposit. If damage exceeds the security deposit, the
                Guest will be liable for any remaining amount.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                6. Property Listings and Host Responsibilities
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  6.1 Accuracy of Listings
                </span>{" "}
                <br />
                Hosts are responsible for ensuring that all Property listings
                are accurate and up to date. Hosts must provide complete
                information regarding the Property, including but not limited to
                its condition, amenities, availability, and pricing.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>6.2 Host Warranties</span>{" "}
                <br />
                By listing a Property on LVLpm, Hosts represent and warrant
                that: 1. They have all necessary rights, licenses, and authority
                to rent the Property. 2. The Property is in a safe, sanitary,
                and habitable condition and complies with all applicable laws
                and regulations.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                7. Payment Processing and Fees
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>7.1 Payment Gateway </span>{" "}
                <br />
                Payments for bookings will be processed through third-party
                payment processors designated by LVLpm. By agreeing to this
                Agreement, you consent to the use of such third-party payment
                processors and agree to be bound by their terms of service.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>7.2 Fees </span> <br />
                LVLpm may charge service fees to Hosts and Guests, including but
                not limited to booking fees, platform fees, cleaning fees, and
                upsell services. These fees will be disclosed at the time of
                booking and are non-refundable unless otherwise stated.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                8. Termination and Account Suspension
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm reserves the right to terminate or suspend your access to
                the Services at its sole discretion, without notice or
                liability, for any reason, including but not limited to:
                <br />
                1. Violation of these Terms.
                <br />
                2. Failure to pay any amounts owed.
                <br />
                3. Conduct that LVLpm believes, in its sole discretion, is
                harmful to other Users, third parties, or the Services.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                9. Disclaimer of Warranties
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                The Services are provided on an "AS IS" and "AS AVAILABLE"
                basis. LVLpm expressly disclaims all warranties of any kind,
                whether express or implied, including but not limited to the
                implied warranties of merchantability, fitness for a particular
                purpose, and non-infringement.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm makes no representations or warranties regarding:
                <br />
                1. The accuracy, reliability, or completeness of any content or
                information provided through the Services.
                <br />
                2. The condition, legality, safety, or suitability of any
                Property listed on the platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                10. Limitation of Liability
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                TO THE FULLEST EXTENT PERMITTED BY LAW, LVLPM SHALL NOT BE
                LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
                TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
                INTANGIBLE LOSSES ARISING OUT OF OR IN CONNECTION WITH THE
                SERVICES OR THIS AGREEMENT.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                11. Indemnification
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                You agree to indemnify, defend, and hold harmless LVLpm, its
                affiliates, officers, directors, employees, and agents from and
                against any claims, liabilities, damages, losses, and expenses,
                including reasonable attorneys' fees, arising out of or in any
                way connected with:
                <br />
                1. Your access to or use of the Services.
                <br />
                2. Your breach of this Agreement.
                <br />
                3. Your violation of any law or the rights of any third party.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                12. Dispute Resolution{" "}
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                Any dispute, controversy, or claim arising out of or relating to
                this Agreement or the breach thereof shall be settled by binding
                arbitration in the state of Delaware, in accordance with the
                Commercial Arbitration Rules of the American Arbitration
                Association. The arbitration award shall be final and binding
                upon the parties and may be enforced in any court of competent
                jurisdiction.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                13. Governing Law
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                This Agreement shall be governed by and construed in accordance
                with the laws of the State of Delaware, without regard to its
                conflict of laws principles.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                14. Amendments and Modifications
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                LVLpm reserves the right to modify or amend this Agreement at
                any time and will provide notice of any changes by updating the
                date at the top of this Agreement. Your continued use of the
                Services following the posting of changes constitutes your
                acceptance of such changes.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                15. Severability
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                If any provision of this Agreement is found to be unlawful,
                void, or unenforceable, that provision will be severed from this
                Agreement and will not affect the validity and enforceability of
                any remaining provisions.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                16. Entire Agreement
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                This Agreement constitutes the entire understanding between you
                and LVLpm regarding the use of the Services and supersedes all
                prior and contemporaneous agreements, proposals, or
                representations, written or oral, concerning its subject matter.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                17. Contact Information
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                If you have any questions or concerns about these Terms, please
                contact us at:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- Email:</span>{" "}
                info@lvlpm.com
                <br />
                <span style={{ fontWeight: "bold" }}>- Phone:</span>{" "}
                213-597-9392
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- Mailing Address:</span>{" "}
                800 N King st, unit 204 3040 Wilmington De 19801
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                BY CONTINUING TO USE OUR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE
                READ, UNDERSTOOD, AND AGREED TO THESE TERMS AND CONDITIONS IN
                THEIR ENTIRETY.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer bg={true} />
    </>
  );
};

export default ContactPage;
