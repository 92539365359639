import { Box, Container, Grid, Typography } from "@mui/material";

import Logo from "../../assets/logo.jpg";
import TimeLine from "./TimeLine";
import rightDottedLine from "../../assets/rightDottedLine.svg";
import leftDottedLine from "../../assets/leftDottedLine.svg";
import OurValuesCard from "./OurValuesCard";
import TransparencyImg from "../../assets/Image 249.svg";
import AdaptabilityImg from "../../assets/Image 247.svg";
import IntegrityImg from "../../assets/Image 248.svg";
import MountainImg from "../../assets/Image 296.svg";
import PhoneImg from "../../assets/Group 36.jpg";
import AskImg from "../../assets/Container 594.svg";
import MsgImg from "../../assets/Button 281.svg";
import Footer from "../../components/commonComponents/footer/footer";
import FAQComponent from "./FAQComponent";

const ourValues = [
  {
    id: 1,
    img: TransparencyImg,
    title: "Transparency",
    content:
      "Open communication fosters trust, building lasting relationships with clients and stakeholders.",
  },
  {
    id: 2,
    img: AdaptabilityImg,
    title: "Adaptability & Innovation",
    content:
      "Embrace innovation, adapt to change, and constantly enhance service quality.",
  },
  {
    id: 3,
    img: IntegrityImg,
    title: "Integrity",
    content:
      "Uphold unwavering integrity, earning trust through ethical, fair, and honest practices.",
  },
];

const faqLists = [
  {
    id: 1,
    title: "HOW DOES IT WORK?",
    content:
      "The first step is to get in touch with our Homeowner Consultant team—they’ll walk through our services, see if your home’s a good fit...",
  },
  {
    id: 2,
    title: "WHY CHOOSE LVLPM?",
    content:
      "The first step is to get in touch with our Homeowner Consultant team—they’ll walk through our services, see if your home’s a good fit...",
  },
  {
    id: 3,
    title: "ARE THERE RESTRICTIONS ON HOW OFTEN I CAN USE MY HOME?",
    content:
      "The first step is to get in touch with our Homeowner Consultant team—they’ll walk through our services, see if your home’s a good fit...",
  },
  {
    id: 4,
    title: "DO I HAVE TO SIGN A LONG TERM CONTRACT?",
    content:
      "The first step is to get in touch with our Homeowner Consultant team—they’ll walk through our services, see if your home’s a good fit...",
  },
];

const AboutUsPage = () => {
  return (
    <>
      <Box sx={{ textAlign: "center", padding: "50px 0" }}>
        <img src={Logo} style={{ width: "60%" }} />
      </Box>
      <Container
        maxWidth={false}
        sx={{
          padding: "50px 0",
          backgroundColor: "#F8F9FA",
          marginBottom: "50px",
          position: "relative",
        }}
      >
        <img
          src={rightDottedLine}
          style={{ position: "absolute", right: "-15px" }}
        />
        <img
          src={leftDottedLine}
          style={{ position: "absolute", bottom: "0", left: "-15px" }}
        />
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "35px" }}
        >
          About Us
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TimeLine />
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          padding: "50px 0",
          backgroundColor: "#F8F9FA",
          marginBottom: "100px",
        }}
      >
        <Container maxWidth={"md"}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            Our Values
          </Typography>
          <Grid container spacing={3}>
            {ourValues.map((value) => (
              <OurValuesCard
                key={value.id}
                img={value.img}
                title={value.title}
                content={value.content}
              />
            ))}
          </Grid>
        </Container>
      </Container>
      <Container maxWidth={"lg"} sx={{ marginBottom: "100px" }}>
        <Grid container spacing={4} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: "30px" }}
            >
              PROVIDING EXCEPTIONAL STAYS FOR BOTH THE OWNER AND RENTER
            </Typography>
            <Typography variant="h6" sx={{ maxWidth: "75%" }}>
              At LVLpm the customer always comes first.. That is one of the main
              issues we saw when we used other companies ourselves. We pride
              ourselves in providing exceptional stays for renters and owners
              alike.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={MountainImg} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={"lg"} sx={{ marginBottom: "100px" }}>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} md={4.6}>
            <Box sx={{ position: "relative", maxWidth: "330px" }}>
              <Box sx={{ width: "330px", height: "590px" }}>
                <img src={PhoneImg} style={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  width: "214px",
                  height: "65px",
                  position: "absolute",
                  left: "-25px",
                  top: "65px",
                }}
              >
                <img src={AskImg} style={{ width: "100%" }} />
              </Box>
              <Box
                sx={{
                  width: "72px",
                  height: "72px",
                  position: "absolute",
                  bottom: "140px",
                  right: "-20px",
                }}
              >
                <img src={MsgImg} style={{ width: "100%" }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7.4} sx={{ paddingLeft: "50px" }}>
            <Typography
              variant="body2"
              sx={{ color: "#00BDD6", marginBottom: "5px" }}
            >
              FAQ's
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginBottom: "25px" }}
            >
              MOST COMMON QUESTIONS ASKED
            </Typography>
            {faqLists.map((faq) => (
              <FAQComponent
                key={faq.id}
                title={faq.title}
                content={faq.content}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer bg={false} />
    </>
  );
};

export default AboutUsPage;
