import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

interface LoginRequiredDialogProps {
  open: boolean;
  handleClose: () => void;
}

const LVLpmThemeButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#00B9D1",
  "&:hover": {
    backgroundColor: "#0094a7",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#00a6bc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  marginTop: "23px",
});

const LoginRequiredDialog = (props: LoginRequiredDialogProps) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  return (
    <Dialog open={open} maxWidth="md">
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Login Required
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Grid>
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontSize: "18px" }}>
            {" "}
            Login is required in order to proceeed to the next step. Please
            login or register in order to complete your reservation
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          textAlign: "center",
          padding: "20px",
        }}
      >
        <LVLpmThemeButton
          onClick={() => navigate("/register")}
          style={{}}
          variant="contained"
        >
          Register
        </LVLpmThemeButton>
        <LVLpmThemeButton
          autoFocus
          style={{}}
          variant="contained"
          onClick={() => navigate("/login")}
        >
          Login
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};
export default LoginRequiredDialog;
