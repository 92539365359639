import { createSlice } from "@reduxjs/toolkit";

import {
  getUserSettings,
  updateUserSettings,
  trustSwiftyVerification,
  getAllUsers,
  disableUser,
  enableUser,
  updateUserAccount,
  getUserPw,
  checkPw,
  changePw,
} from "../../thunks/userThunk";

interface UserSlice {
  getUserSettingsLoading: Boolean;
  updateUserSettingsLoading: Boolean;
  trustSwiftyVerificationLoading: Boolean;
  getAllUsersLoading: Boolean;
  disableUserLoading: Boolean;
  enableUserLoading: Boolean;
  updateUserAccountLoading: Boolean;
  getUserPwLoading: Boolean;
  checkPwLoading: Boolean;
  changePwLoading: Boolean;
  error: any;
  response: any;
  currentUser: Object;
  updatedUser: Object;
  allUsers: any;
  getUserPw: Object;
  checkPw: Object;
  changePw: Object;
}

const initialState: UserSlice = {
  getUserSettingsLoading: false,
  updateUserSettingsLoading: false,
  trustSwiftyVerificationLoading: false,
  getAllUsersLoading: false,
  disableUserLoading: false,
  enableUserLoading: false,
  updateUserAccountLoading: false,
  getUserPwLoading: false,
  checkPwLoading: false,
  changePwLoading: false,
  error: null,
  response: null,
  currentUser: {},
  updatedUser: {},
  allUsers: null,
  getUserPw: {},
  checkPw: {},
  changePw: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserSettings.pending, (state) => {
      state.getUserSettingsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action) => {
      state.getUserSettingsLoading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(getUserSettings.rejected, (state, action) => {
      state.getUserSettingsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateUserSettings.pending, (state) => {
      state.updateUserSettingsLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      state.updateUserSettingsLoading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(updateUserSettings.rejected, (state, action) => {
      state.updateUserSettingsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(trustSwiftyVerification.pending, (state) => {
      state.trustSwiftyVerificationLoading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(trustSwiftyVerification.fulfilled, (state, action) => {
      state.trustSwiftyVerificationLoading = false;
      state.response = action.payload;
    });
    builder.addCase(trustSwiftyVerification.rejected, (state, action) => {
      state.trustSwiftyVerificationLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getAllUsers.pending, (state) => {
      state.getAllUsersLoading = true;
      state.error = null;
      state.allUsers = null;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.getAllUsersLoading = false;
      state.allUsers = action.payload.all_users;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.getAllUsersLoading = false;
      state.error = action.payload;
    });

    builder.addCase(disableUser.pending, (state) => {
      state.disableUserLoading = true;
      state.error = null;
    });
    builder.addCase(disableUser.fulfilled, (state, action) => {
      state.disableUserLoading = false;
      state.response = action.payload.all_users;
    });
    builder.addCase(disableUser.rejected, (state, action) => {
      state.disableUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(enableUser.pending, (state) => {
      state.enableUserLoading = true;
      state.error = null;
    });
    builder.addCase(enableUser.fulfilled, (state, action) => {
      state.enableUserLoading = false;
      state.response = action.payload.all_users;
    });
    builder.addCase(enableUser.rejected, (state, action) => {
      state.enableUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(updateUserAccount.pending, (state) => {
      state.updateUserAccountLoading = true;
      state.error = null;
    });
    builder.addCase(updateUserAccount.fulfilled, (state, action) => {
      state.updateUserAccountLoading = false;
      state.response = action.payload.all_users;
    });
    builder.addCase(updateUserAccount.rejected, (state, action) => {
      state.updateUserAccountLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getUserPw.pending, (state) => {
      state.getUserPwLoading = true;
      state.error = null;
    });
    builder.addCase(getUserPw.fulfilled, (state, action) => {
      state.getUserPwLoading = false;
      state.response = action.payload.getUserPw;
    });
    builder.addCase(getUserPw.rejected, (state, action) => {
      state.getUserPwLoading = false;
      state.error = action.payload;
    });
    builder.addCase(checkPw.pending, (state) => {
      state.checkPwLoading = true;
      state.error = null;
    });
    builder.addCase(checkPw.fulfilled, (state, action) => {
      state.checkPwLoading = false;
      state.response = action.payload.checkPw;
    });
    builder.addCase(checkPw.rejected, (state, action) => {
      state.checkPwLoading = false;
      state.error = action.payload;
    });
    builder.addCase(changePw.pending, (state) => {
      state.changePwLoading = true;
      state.error = null;
    });
    builder.addCase(changePw.fulfilled, (state, action) => {
      state.changePwLoading = false;
      state.response = action.payload.changePw;
    });
    builder.addCase(changePw.rejected, (state, action) => {
      state.changePwLoading = false;
      state.error = action.payload;
    });
  },
});

export const {} = userSlice.actions;

export const userReducer = userSlice.reducer;
