import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import RatingStar from "../ratingStar/ratingStar";
import { Link } from "react-router-dom";

interface PropertiesCardProps {
  img: any;
  title: string;
  address: string;
  stays: number;
  rating: number;
  to: string;
}

const PropertiesCard = (props: PropertiesCardProps) => {
  const { img, title, address, stays, rating, to } = props;

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{}}>
      <Link to={to} style={{ textDecoration: "none" }}>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "normal",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CardMedia
                  component="img"
                  height="200"
                  image={
                    img
                      ? img.split(",")[
                          Math.floor(Math.random() * img.split(",").length)
                        ]
                      : null
                  }
                  alt={img}
                  style={{
                    width: "100%",
                    padding: "15px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  minHeight: "100%",
                }}
              >
                <CardContent
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    padding: { xs: "0 15px 15px 15px", md: "15px 0 0 0" },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    fontWeight={"bold"}
                    sx={{ fontSize: "18px" }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    sx={{ madWidth: "max-content" }}
                  >
                    {address}
                  </Typography>
                  <Grid
                    sx={{
                      color: "#AEAEAE",
                    }}
                  >
                    {/* <Box sx={{ display: "flex" }}>
                      <RatingStar rating={rating} color="gold" />
                      <Typography variant="body1" sx={{ marginRight: "5px" }}>
                        {rating}
                      </Typography>
                    </Box> */}
                    <Typography variant="body1" sx={{ paddingRight: "15px" }}>
                      {stays} Stays
                    </Typography>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Link>
    </Grid>
  );
};

export default PropertiesCard;
