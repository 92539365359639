import { ChatList } from "react-chat-elements";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { setCurrentUserMessageList } from "../../store/index";

const MessageListComponent = (props) => {
  const { messageList } = props;
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.authentication);

  const { currentUserMessageList } = useAppSelector((state) => state.messages);

  const handleSelectMessageList = (messageListInfo) => {
    if (currentUserMessageList) {
      if (messageListInfo.id === currentUserMessageList.id) {
        return;
      }
    }

    let testinginfo = messageList.find((messageListElement) => {
      return messageListElement.id === messageListInfo.id;
    });

    dispatch(
      setCurrentUserMessageList(
        messageList.find((messageListElement) => {
          return messageListElement.id === messageListInfo.id;
        })
      )
    );
  };

  return (
    <ChatList
      onClick={(info) => handleSelectMessageList(info)}
      className="chat-list"
      dataSource={messageList}
    />
  );
};

export default MessageListComponent;
