import { Grid } from "@mui/material";
import React from "react";
import SettingInfoCard from "../../../../components/reusableComponents/settingInfoCard/settingInfoCard";
import moment from "moment";

interface ProfileInfoPageProps {
  userInfo: any;
}

const ProfileInfoPage = (props: ProfileInfoPageProps) => {
  const { userInfo } = props;
  return (
    <Grid
      component="div"
      container
      sx={{
        width: "100%",
        padding: "15px 15px 15px 0 ",
      }}
    >
      <SettingInfoCard
        title="First Name"
        data={userInfo?.first_name}
        type="userInfo"
        xs={12}
        md={4}
      />
      <SettingInfoCard
        title="Last Name"
        data={userInfo?.last_name}
        type="userInfo"
        xs={12}
        md={4}
      />
      <SettingInfoCard
        title="Email"
        data={userInfo?.email}
        type="userInfo"
        xs={12}
        md={4}
      />
      <SettingInfoCard
        title="Gender"
        data={"Male"}
        type="userInfo"
        xs={12}
        md={4}
      />
      <SettingInfoCard
        title="Date Of Birth"
        data={
          userInfo?.dob
            ? moment(userInfo?.dob).format("MM/DD/YYYY")
            : "DaTe Of Birth"
        }
        type="userInfo"
        xs={12}
        md={4}
      />
      <SettingInfoCard
        title="Phone Number"
        data={userInfo?.contact ? userInfo?.contact : "Phone"}
        type="userInfo"
        xs={12}
        md={4}
      />
    </Grid>
  );
};

export default ProfileInfoPage;
