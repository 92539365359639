import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Chip, FormControlLabel, FormGroup } from "@mui/material";

function createData(
  property: boolean,
  guest: string,
  platform: string,
  checkInOut: string,
  grossBooking: number,
  discount: number,
  tax: number,
  fee: number,
  netBooking: number
) {
  return {
    property,
    guest,
    platform,
    checkInOut,
    grossBooking,
    discount,
    tax,
    fee,
    netBooking,
  };
}

const rows = [
  createData(
    false,
    "Jack Hall",
    "Airbnb",
    "10/12/23 - 12/18/24",
    10000,
    1000,
    326,
    1210,
    8543
  ),
  createData(
    false,
    "James Taylor",
    "LVLpm",
    "10/12/23 - 12/18/24",
    10000,
    1000,
    326,
    1210,
    8543
  ),
  //   createData("Frozen yoghurt", "159", "6.0", 24, 4.0, 4.0, 4.0, 4.0),
  //   createData("Frozen yoghurt", "159", "6.0", 24, 4.0, 4.0, 4.0, 4.0),
  //   createData("Frozen yoghurt", "159", "6.0", 24, 4.0, 4.0, 4.0, 4.0),
];

const GuestListChart = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#E2F6FC" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Property</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Guest
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Platform
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Check-In/Out
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Gross Booking
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Discount
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Tax
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              LVLpm Fee
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Net Booking
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" align="center" scope="row">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox value={row.property} />}
                    label=""
                  />
                </FormGroup>
              </TableCell>
              <TableCell align="right" scope="row">
                {row.guest}
              </TableCell>
              <TableCell align="right">
                <Chip
                  label={row.platform}
                  sx={{
                    color:
                      row.platform === "Airbnb"
                        ? "#DC0D0D"
                        : row.platform === "LVLpm"
                        ? "#00B9D1"
                        : row.platform === "VRBO"
                        ? "#0146F8"
                        : "",
                  }}
                />
              </TableCell>
              <TableCell align="right">{row.checkInOut}</TableCell>
              <TableCell align="right">
                ${row.grossBooking.toLocaleString()}
              </TableCell>
              <TableCell align="right">
                ${row.discount.toLocaleString()}
              </TableCell>
              <TableCell align="right">${row.tax.toLocaleString()}</TableCell>
              <TableCell align="right">${row.fee.toLocaleString()}</TableCell>
              <TableCell align="right">
                ${row.netBooking.toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GuestListChart;
