import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import SettingInfoCard from "../../../../components/reusableComponents/settingInfoCard/settingInfoCard";
import { getUserPw } from "../../../../store/thunks/userThunk";
import LVLpmButton from "../../../../components/reusableComponents/LVLpmButton/LVLpmButton";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import CheckPwModal from "./CheckPwModal";

interface SecurityProps {
  userInfo: Object;
}

const SettingsSecurityPage = (props: SecurityProps) => {
  const { userInfo } = props;
  const dispatch = useAppDispatch();
  const [pw, setPw] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserPw({ userInfo })).then((res) => {
        setPw(res.payload.userPw.password);
      });
    }
  }, [userInfo]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <CheckPwModal
            userInfo={userInfo}
            handleClose={handleClose}
            open={open}
            pw={pw}
          />
          <LVLpmThemeButton onClick={handleOpen}>
            Change Password
          </LVLpmThemeButton>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsSecurityPage;
