import { Box, Typography } from "@mui/material";
import React from "react";

const MessageProfileComponent = () => {
  return (
    <Box sx={{ padding: "15px" }}>
      <Typography
        variant="h6"
        sx={{ borderBottom: "1px solid #dbdbdb", marginBottom: "15px" }}
      >
        User Details
      </Typography>
      <Box>
        <img
          src={"https://avatars.githubusercontent.com/u/80540635?v=4"}
          alt="profile img"
          style={{ width: "150px", height: "150px" }}
        />
      </Box>
      <Box>
        <Box>User Name</Box>
        <Box>User Rating</Box>
        <Box>View User Profile</Box>
      </Box>
    </Box>
  );
};

export default MessageProfileComponent;
