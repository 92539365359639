import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

interface ListItemProps {
  title: string;
  icon: any;
  location: string;
}

const DrawerListItem = (prop: ListItemProps) => {
  const { title, icon, location } = prop;
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState<boolean>(false);

  const navigate = useNavigate();
  const currentLocation = useLocation().pathname;

  const handleActive = (location: string) => {
    navigate(location);
    setActive(!active);
  };

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        background:
          currentLocation === location ||
          currentLocation.includes(`${location}/`)
            ? "#00B9D1"
            : "",
        marginBottom: "5px",
      }}
      onClick={() => handleActive(location)}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: "20px",
            justifyContent: "center",
            color:
              currentLocation === location ||
              currentLocation.includes(`${location}/`)
                ? "white"
                : "",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            opacity: open ? 1 : 0,
            color:
              currentLocation === location ||
              currentLocation.includes(`${location}/`)
                ? "white"
                : "",
          }}
        >
          {title}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerListItem;
