import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL, config } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const systemRoute = "/api/v1/system";

const getAllProperties = createAsyncThunk(
  "system/getAllProperties",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${systemRoute}/getAllProperties`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllProperties", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllBookings = createAsyncThunk(
  "system/getAllBookings",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${systemRoute}/getAllBookings`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("systemthunk getAllBookings", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllAccounts = createAsyncThunk(
  "system/getAllAccounts",
  async ({ account_id }: { account_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${systemRoute}/getAllAccounts`,
        { account_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllAccounts", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyStatus = createAsyncThunk(
  "system/getAllPropertyStatus",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${systemRoute}/getAllPropertyStatus`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyStatus", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const validatePropertyAddress = createAsyncThunk(
  "system/validatePropertyAddress",
  async (
    { validate_address_payload }: { validate_address_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${systemRoute}/validatePropertyAddress`,
        { validate_address_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("validatePropertyAddress", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPropertyUsers = createAsyncThunk(
  "system/getAllPropertyUsers",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${systemRoute}/getAllPropertyUsers`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPropertyUsers", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAllPlatforms = createAsyncThunk(
  "system/getAllPlatforms",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${backendURL}${systemRoute}/getAllPlatforms`,
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getAllPlatforms", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getAllProperties,
  getAllBookings,
  getAllAccounts,
  getAllPropertyStatus,
  validatePropertyAddress,
  getAllPropertyUsers,
  getAllPlatforms,
};
