import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../utilities/constants";
import Spinner from "../../reusableComponents/spinner/spinner";
import { updatePropertyPrices } from "../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../utilities/helperFunctions";

interface UpdatePropertyPriceDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedPropertyDetails: any;
}

interface UpdatePropertyPrice {
  property_id: string;
  property_name: string;
  extra_guest_fee: number;
  price_per_day: number;
  security_deposit: number;
  cleaning_fee: number;
}

const UpdatePropertyPriceDialog = (props: UpdatePropertyPriceDialogProps) => {
  const { open, handleCloseCancel, selectedPropertyDetails } = props;

  const { updatePropertyPricesLoading } = useAppSelector(
    (state: any) => state.property
  );

  const dispatch = useAppDispatch();

  const [enableUpdatePropertyPrice, setEnableUpdatePropertyPrice] =
    useState(true);
  const [updatePropertyPriceError, setUpdatePropertyPriceError] =
    useState(false);
  const [updatePropertyPriceErrorMessage, setUpdatePropertyPriceErrorMessage] =
    useState("");

  const checkEnableUpdate = () => {
    if (
      Number(selectedPropertyDetails?.price_per_day) ===
        Number(propertyPriceParameters?.price_per_day) &&
      Number(selectedPropertyDetails?.extra_guest_fee) ===
        Number(propertyPriceParameters?.extra_guest_fee) &&
      Number(selectedPropertyDetails?.security_deposit) ===
        Number(propertyPriceParameters?.security_deposit) &&
      Number(selectedPropertyDetails?.cleaning_fee) ===
        Number(propertyPriceParameters?.cleaning_fee)
    ) {
      setEnableUpdatePropertyPrice(true);
    } else {
      setEnableUpdatePropertyPrice(false);
    }
  };

  const [propertyPriceParameters, setPropertyPriceParameters] = useState<
    Partial<UpdatePropertyPrice>
  >({
    property_id: selectedPropertyDetails?.property_id,
    property_name: selectedPropertyDetails?.property_name,
    price_per_day: selectedPropertyDetails?.price_per_day,
    security_deposit: selectedPropertyDetails?.security_deposit,
    cleaning_fee: selectedPropertyDetails?.cleaning_fee,

    extra_guest_fee: selectedPropertyDetails?.extra_guest_fee,
  });

  const setPropertyPriceAtribute = (name: string, values: any) => {
    setPropertyPriceParameters((previous) => ({
      ...previous,
      [name]: values,
    }));
  };

  useEffect(() => {
    setPropertyPriceAtribute(
      "property_id",
      selectedPropertyDetails?.property_id
    );
    setPropertyPriceAtribute(
      "property_name",
      selectedPropertyDetails?.property_name
    );
    setPropertyPriceAtribute(
      "price_per_day",
      selectedPropertyDetails?.price_per_day
    );
    setPropertyPriceAtribute(
      "security_deposit",
      selectedPropertyDetails?.security_deposit
    );
    setPropertyPriceAtribute(
      "cleaning_fee",
      selectedPropertyDetails?.cleaning_fee
    );
    setPropertyPriceAtribute(
      "extra_guest_fee",
      selectedPropertyDetails?.extra_guest_fee
    );
  }, [selectedPropertyDetails]);

  const handleCloseUpdatePropertyPriceDialog = (event: any) => {
    setPropertyPriceAtribute("property_name", "");
    setPropertyPriceAtribute("extra_guest_fee", 0);
    setPropertyPriceAtribute("price_per_day", 0);
    setPropertyPriceAtribute("security_deposit", 0);
    setPropertyPriceAtribute("cleaning_fee", 0);
    handleCloseCancel(event);
  };

  const submitUpdatePropertyPriceDialog = () => {
    setUpdatePropertyPriceError(false);
    setUpdatePropertyPriceErrorMessage("");
    dispatch(
      updatePropertyPrices({
        property_price_payload: propertyPriceParameters,
      })
    )
      .then((updatePropertyPriceResult) => {
        if (updatePropertyPriceResult?.payload?.status) {
          handleCloseUpdatePropertyPriceDialog(
            updatePropertyPriceResult?.payload?.message
          );
        } else {
          setUpdatePropertyPriceError(true);
          setUpdatePropertyPriceErrorMessage(
            "Unable to update property Price, check for duplicate property name. Please try again later or contact support"
          );
        }
      })
      .catch((updatePropertyPriceError) => {
        displayConsoleError("updatePropertyPrice", updatePropertyPriceError);
        setUpdatePropertyPriceError(true);
        setUpdatePropertyPriceErrorMessage(
          "Unable to update property Price, check for duplicate property name, Please try again later or contact support"
        );
      });
  };

  useEffect(() => {
    checkEnableUpdate();
  }, [propertyPriceParameters]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Spinner loading={updatePropertyPricesLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update Property Prices
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdatePropertyPriceDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ padding: "1%" }}>
            <TextField
              id="property_name"
              label="Property Name"
              name="property_name"
              value={propertyPriceParameters.property_name}
              onChange={(event) =>
                setPropertyPriceAtribute("property_name", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: "1%" }}>
            <TextField
              id="price_per_day"
              label="Price Per Day"
              name="price_per_day"
              value={propertyPriceParameters.price_per_day}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={(event) =>
                setPropertyPriceAtribute("price_per_day", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
              error={propertyPriceParameters.price_per_day === 0 ? true : false}
              helperText={
                propertyPriceParameters.price_per_day === 0
                  ? "Price Per Day must be greater than 0."
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="security_deposit"
              label="Security Deposit"
              name="security_deposit"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={propertyPriceParameters.security_deposit}
              onChange={(event) =>
                setPropertyPriceAtribute("security_deposit", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="cleaning_fee"
              label="Cleaning Fee"
              name="cleaning_fee"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={propertyPriceParameters.cleaning_fee}
              onChange={(event) =>
                setPropertyPriceAtribute("cleaning_fee", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ padding: "1%" }}>
            <TextField
              id="extra_guest_fee"
              label="Number of Beds"
              name="extra_guest_fee"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={propertyPriceParameters.extra_guest_fee}
              onChange={(event) =>
                setPropertyPriceAtribute("extra_guest_fee", event.target.value)
              }
              sx={{
                width: "100%",
                marginBottom: "5px",
              }}
            />
          </Grid>
        </Grid>
        {updatePropertyPriceError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {updatePropertyPriceErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdatePropertyPriceDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdatePropertyPriceDialog()}
          disabled={enableUpdatePropertyPrice}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePropertyPriceDialog;
