import React, { useContext, useEffect } from "react";
import { Box } from "@mui/system";
import management from "./../../assets/management.jpeg";
import management1 from "./../../assets/management-1.jpeg";
import management2 from "./../../assets/management-2.jpeg";
import management3 from "./../../assets/management-3.jpeg";
import management4 from "./../../assets/management-4.jpeg";
import management5 from "./../../assets/management-5.jpeg";
import management6 from "./../../assets/management-6.jpeg";
import management7 from "./../../assets/management-7.jpeg";
import management8 from "./../../assets/management-8.jpeg";
import management9 from "./../../assets/management-9.jpeg";
import management10 from "./../../assets/management-10.jpeg";
import management11 from "./../../assets/management-11.jpeg";
import management12 from "./../../assets/management-12.jpeg";
import management13 from "./../../assets/management-13.jpeg";
import { Grid, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Footer from "../../components/commonComponents/footer/footer";

const Management = () => {
  return (
    <Grid>
      <Grid sx={{ mx: "10%" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundImage: `url(${management})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "450px",
              borderRadius: "0px 0px 1000px 0px / 0px 0px 200px 0px;",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "white", fontWeight: "bold", m: 15 }}
            >
              VACATION RENTAL MANAGEMENT
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              fontWeight: "bold",
              // border: "1px solid red",
              alignItems: "center",
              justifyContent: "center",
              height: 300,
            }}
          >
            <Box>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: "bold",
                  m: 2,
                }}
              >
                WHY US
              </Typography>
            </Box>

            <Box>
              <Typography>
                We are serious in our quest to take on the industry and
                transform it. To do that we have to offer a high level of
                quality and service that we ourselves as property owners
                understand. We create a passive income stream for our owners
                while providing hotel-like quality stays for guests.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundImage: `url(${management1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "450px",
              borderRadius: "0px 0px 1000px 0px / 0px 0px 200px 0px;",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "white", fontWeight: "bold", m: 15 }}
            >
              VACATION RENTAL MANAGEMENT
            </Typography>
          </Box>
          <Box>
            <Typography>WHAT’S INCLUDED</Typography>{" "}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                bgcolor: "background.paper",
                maxWidth: 950,
                color: "black",
                border: "1px solid green",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  maxWidth: 300,
                  color: "black",
                  border: "1px solid green",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    24/7 GUEST SUPPORT
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "black", textAlign: "center" }}>
                    Never take a guest’s late-night phone call again—our
                    Customer Experience team has it covered.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  maxWidth: 300,
                  color: "black",
                  border: "1px solid green",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    TECH THAT WORKS FOR YOU
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ color: "black", textAlign: "center" }}>
                    Stay connected with a digital revenue dashboard, booking
                    notifications, our new Homeowner app ( coming soon) &
                    more!!!
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  maxWidth: 300,
                  color: "black",
                  border: "1px solid green",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    24/7 GUEST SUPPORT
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Never take a guest’s late-night phone call again—our
                    Customer Experience team has it covered.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  maxWidth: 300,
                  color: "black",
                  border: "1px solid green",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    24/7 GUEST SUPPORT
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Never take a guest’s late-night phone call again—our
                    Customer Experience team has it covered.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "flex-start",
                  maxWidth: 300,
                  color: "black",
                  border: "1px solid green",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    24/7 GUEST SUPPORT
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Never take a guest’s late-night phone call again—our
                    Customer Experience team has it covered.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>Test</Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              // border: "1px solid red",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                bgcolor: "background.paper",
                maxWidth: 950,
                color: "black",
                border: "1px solid green",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 300,
                  border: "1px solid purple",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${management1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: "1px solid yellow",
                    width: 300,
                    height: 300,
                  }}
                ></Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    WE <span style={{ color: "black" }}>MANAGE</span>.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "black", m: 2 }}>
                    100% hands off management at the click of a button.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 300,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${management})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: 300,
                    height: 300,
                  }}
                ></Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    WE <span style={{ color: "black" }}>MANAGE</span>.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "black", m: 2 }}>
                    100% hands off management at the click of a button.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 300,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${management})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: 300,
                    height: 300,
                  }}
                ></Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    WE <span style={{ color: "black" }}>MANAGE</span>.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "black", m: 2 }}>
                    100% hands off management at the click of a button.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 300,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${management})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: 300,
                    height: 300,
                  }}
                ></Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    WE <span style={{ color: "black" }}>MANAGE</span>.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "black", m: 2 }}>
                    100% hands off management at the click of a button.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 300,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundImage: `url(${management})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: 300,
                    height: 300,
                  }}
                ></Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    WE <span style={{ color: "black" }}>MANAGE</span>.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "black", m: 2 }}>
                    100% hands off management at the click of a button.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", m: 2 }}>
                  <NavLink
                    style={({ isActive }) => ({
                      display: "flex",
                      color: isActive ? "white" : "black",
                      border: "1px solid",
                      backgroundColor: isActive ? "black" : "white",
                      borderRadius: "50px",
                      width: "170px",
                      height: "60px",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      p: 5,
                    })}
                    to={"/management/vacationrental"}
                  >
                    <Typography>Learn More</Typography>
                  </NavLink>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
        </Box>
      </Grid>
      <Footer bg={true} />
    </Grid>
  );
};

export default Management;
