import {
  Box,
  Checkbox,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import {
  getAllPlatforms,
  getAllProperties,
  getAllPropertyStatus,
  getAllPropertyUsers,
} from "../../../store/thunks/systemThunk";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import LockResetIcon from "@mui/icons-material/LockReset";
import ValidatePropertyAddressDialog from "./validatePropertyAddressDialog/validatePropertyAddressDialog";
import UpdatePropertyAmenitiesDialog from "../../../components/dialogComponents/updatePropertyAmenitiesDialog/updatePropertyAmenitiesDialog";
import UpdatePropertyDetailsDialog from "../../../components/dialogComponents/updatePropertyDetailsDialog/updatePropertyDetailsDialog";
import UpdatePropertyImagesDialog from "../../../components/dialogComponents/updatePropertyImagesDialog/updatePropertyImagesDialog";
import UpdatePropertyOwnerDialog from "./updatePropertyOwnerDialog/updatePropertyOwnerDialog";
import UpdatePropertyPriceDialog from "../../../components/dialogComponents/updatePropertyPriceDialog/updatePropertyPriceDialog";
import UpdatePropertyStatusDialog from "./updatePropertyStatusDialog/updatePropertyStatusDialog";
import {
  getAllCountries,
  getAllPropertyListingType,
  getAllPropertyRoomType,
  getAllPropertyTypes,
} from "../../../store/thunks/propertyThunk";
import { displayConsoleError } from "../../../utilities/helperFunctions";

const PropertyManagement = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { getAllPropertiesLoading } = useAppSelector(
    (state: any) => state.system
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [allProperties, setAllProperties] = useState([]);

  const [selectedPropertyData, setSelectedPropertyData] = useState<any>();

  const [openValidateAddress, setOpenValidateAddress] = useState(false);
  const [openUpdatePropertyOwner, setOpenUpdatePropertyOwner] = useState(false);
  const [openUpdatePropertyDetails, setOpenUpdatePropertyDetails] =
    useState(false);
  const [openUpdatePropertyPrice, setOpenUpdatePropertyPrice] = useState(false);
  const [openUpdatePropertyAmenities, setOpenUpdatePropertyAmenities] =
    useState(false);
  const [openUpdateImages, setOpenUpdateImages] = useState(false);
  const [openUpdatePropertyStatus, setOpenUpdatePropertyStatus] =
    useState(false);

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getAllProperties({}))
        .then((getAllPropertiesResult: any) => {
          if (getAllPropertiesResult?.payload) {
            setAllProperties(
              getAllPropertiesResult?.payload?.properties_details
            );
          }
        })
        .catch((getAllPropertiesError) => {
          displayConsoleError(
            "propertyManagement getAllProperties",
            getAllPropertiesError
          );
          if (
            getAllPropertiesError.response &&
            getAllPropertiesError.response.status === 401
          ) {
            navigate("/login");
          }
        });
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const propertyColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "property_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Property Name",
      },
      {
        accessorKey: "label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Property Type",
      },
      {
        accessorKey: "listing_type_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Listing Type",
      },
      {
        accessorKey: "room_type_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Room Type",
      },
      {
        accessorKey: "full_address",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Address",
      },
      {
        accessorKey: "city_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "LVL City",
      },
      {
        accessorKey: "state_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "LVL State",
      },
      {
        accessorKey: "country_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "LVL Country",
      },
      {
        accessorFn: (row: any) => `${row.first_name} ${row.last_name}`,
        id: "name",
        enableClickToCopy: true,
        header: "Owner Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "instant_booking",
        header: "Instant Booking",
        Cell: ({
          renderedCellValue,
          row,
        }: {
          renderedCellValue: any;
          row: any;
        }) => (
          <Checkbox
            disabled
            defaultChecked={row?.instant_booking ? true : false}
          />
        ),
      },
      {
        accessorKey: "bedrooms",
        header: "Bedrooms",
      },
      {
        accessorKey: "bathrooms",
        header: "Bathrooms",
      },
      {
        accessorKey: "cleaning_fee",
        header: "Cleaning Fee",
      },
      {
        accessorFn: (row: any) => row?.description?.substring(0, 50) + "...",
        id: "description",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Description",
      },
      {
        accessorKey: "extra",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "House Rules",
      },
      {
        accessorKey: "guests",
        header: "Number of Guest",
      },
      {
        accessorKey: "price_per_day",
        header: "Price Per Day",
      },
      {
        accessorFn: (row: any) => row?.property_images_url?.split(",")?.length,
        id: "property_images_url",
        header: "Number of Images",
      },
      {
        accessorKey: "status_label",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Status",
      },
      {
        accessorKey: "wifi_name",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Wifi Namme (SSID)",
      },
      {
        accessorKey: "wifi_password",
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Wifi Password",
      },
      {
        accessorFn: (row: any) => dayjs(row.create_date).format("MMM DD, YYYY"),
        id: "booking_create_date",
        header: "Created Date",
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
    ],
    []
  );

  const propertyTable = useMaterialReactTable({
    columns: propertyColumns,
    data: allProperties ? allProperties : [],
    state: {
      isLoading: getAllPropertiesLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={1}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdatePropertyStatus(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Porperty Status
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenValidateAddress(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Validate Address
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdatePropertyOwner(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Property Owner
      </MenuItem>,
      <MenuItem
        key={4}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdatePropertyDetails(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Property Details
      </MenuItem>,
      <MenuItem
        key={5}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdatePropertyPrice(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Property Price
      </MenuItem>,
      <MenuItem
        key={6}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdatePropertyAmenities(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Property Amenenties
      </MenuItem>,

      <MenuItem
        key={7}
        onClick={() => {
          setSelectedPropertyData(row.original);
          setOpenUpdateImages(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Property Images
      </MenuItem>,
    ],
  });

  const handleCloseValidateAddress = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenValidateAddress(false);
  };

  const handleCloseUpdatePropertyOwner = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyOwner(false);
  };

  const handleCloseUpdatePropertyDetails = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyDetails(false);
  };

  const handleCloseUpdatePropertyPrice = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyPrice(false);
  };

  const handleCloseUpdatePropertyAmenities = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyAmenities(false);
  };

  const handleCloseUpdateImages = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdateImages(false);
  };

  const handleCloseUpdatePropertyStatus = (event: any) => {
    if (event !== "") {
      setOpenSnackbar(true);
      setSnackbarMessage(event);
      refetch();
    }
    setSelectedPropertyData(null);
    setOpenUpdatePropertyStatus(false);
  };

  useEffect(() => {
    dispatch(getAllPropertyStatus({}));
    dispatch(getAllCountries({}));
    dispatch(getAllPropertyUsers({}));
    dispatch(getAllPropertyListingType({}));
    dispatch(getAllPropertyTypes({}));
    dispatch(getAllPropertyRoomType({}));
    dispatch(getAllPlatforms({}));
  }, []);

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ padding: "20px 10px 20px 10px" }}>
        <TableTemplate tableData={propertyTable} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
      <ValidatePropertyAddressDialog
        open={openValidateAddress}
        handleCloseCancel={(event: any) => handleCloseValidateAddress(event)}
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyAmenitiesDialog
        open={openUpdatePropertyAmenities}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyAmenities(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyDetailsDialog
        open={openUpdatePropertyDetails}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyDetails(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyImagesDialog
        open={openUpdateImages}
        handleCloseCancel={(event: any) => handleCloseUpdateImages(event)}
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyOwnerDialog
        open={openUpdatePropertyOwner}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyOwner(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
      <UpdatePropertyPriceDialog
        open={openUpdatePropertyPrice}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyPrice(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />

      <UpdatePropertyStatusDialog
        open={openUpdatePropertyStatus}
        handleCloseCancel={(event: any) =>
          handleCloseUpdatePropertyStatus(event)
        }
        selectedPropertyDetails={selectedPropertyData}
      />
    </Grid>
  );
};
export default PropertyManagement;
