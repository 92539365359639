import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utilities/authMidleware";
import { backendURL } from "../../utilities/utils";
import { displayConsoleError } from "../../utilities/helperFunctions";

const messagesRoute = "/api/v1/messages";

const createUserMessageList = createAsyncThunk(
  "messages/createUserMessageList",
  async (
    { message_list_payload }: { message_list_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${messagesRoute}/createUserMessageList`,
        { message_list_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("createUserMessageList", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const createUserMessage = createAsyncThunk(
  "messages/createUserMessage",
  async (
    { message_payload }: { message_payload: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${messagesRoute}/createUserMessage`,
        { message_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("createUserMessage", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getUserMessageList = createAsyncThunk(
  "messages/getUserMessageList",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${backendURL}${messagesRoute}/getUserMessageList`,
        { user_id: user_id },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getUserMessageList", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getUserMessages = createAsyncThunk(
  "messages/getUserMessages",
  async (
    { get_message_payload }: { get_message_payload: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${backendURL}${messagesRoute}/getUserMessages`,
        { get_message_payload },
        tokenInterceptor()
      );
      return response.data;
    } catch (error: any) {
      displayConsoleError("getUserMessages", error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  createUserMessageList,
  createUserMessage,
  getUserMessageList,
  getUserMessages,
};
