import React from "react";
import { Field } from "formik";
import { FormGroup, FormLabel } from "@mui/material";

interface FormInputProps {
  label: string;
  name: string;
}

const FormInput = (props: FormInputProps) => {
  const { label, name, ...rest } = props;
  return (
    <FormGroup sx={{ marginBottom: "20px" }}>
      <FormLabel htmlFor={name} sx={{ fontWeight: "bold" }}>
        {label}
      </FormLabel>
      <Field
        id={name}
        name={name}
        {...rest}
        style={{
          border: "none",
          borderBottom: "1px solid #CCC",
          padding: "10px 0 5px 0",
          fontSize: "1rem",
          outline: "none",
        }}
      />
    </FormGroup>
  );
};

export default FormInput;
