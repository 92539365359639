import { useCallback, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
import { Field, useFormikContext } from "formik";

interface FormCheckboxProps {
  label: string;
  name: string;
}

const FormCheckbox = (props: FormCheckboxProps) => {
  const { label, name } = props;
  const { setFieldValue } = useFormikContext();
  const handleCheck = useCallback(
    (event: any) => {
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue]
  );
  return (
    <Grid sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <FormLabel htmlFor={name} sx={{ fontWeight: "bold", width: "100%" }}>
        {label}
      </FormLabel>
      <Field
        type="checkbox"
        id={name}
        name={name}
        onChange={handleCheck}
        style={{ marginRight: "15px" }}
      />
    </Grid>
  );
};

export default FormCheckbox;
