import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import {
  setSelectedPropertiesType,
  setSelectedPrices,
} from "../../../store/index";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/hooks";

interface PropertyFilterProps {
  title: string;
  selections: any[];
}

const PropertyFilter = (prop: PropertyFilterProps) => {
  const { title, selections } = prop;

  const [selectedSections, setSelectedSections] = useState<any[]>([]);

  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selection: any
  ) => {
    let current_selected = [...selectedSections];

    if (event.target.checked) {
      current_selected.push(selection);
      setSelectedSections(current_selected);
    } else {
      current_selected = current_selected.filter((element: any) => {
        return element.label !== event.target.name;
      });
      setSelectedSections(current_selected);
    }

    if (title.includes("Property")) {
      dispatch(setSelectedPropertiesType(current_selected));
    } else if (title.includes("Prices")) {
      dispatch(setSelectedPrices(current_selected));
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid gray",
        borderRadius: "15px",
        marginBottom: "30px",
      }}
    >
      <Box
        sx={{
          height: "50px",
          borderRadius: "15px 15px 0 0",
          backgroundColor: "#D5EEF5",
          padding: "10px 0 0 10px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ marginBottom: "10px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "15px",
          backgroundColor: "#fff",
          borderRadius: "0 0 15px 15px",
        }}
      >
        <FormGroup>
          {selections &&
            selections?.length > 0 &&
            selections.map((selection, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selection.active}
                      onChange={(event) => handleChange(event, selection)}
                      name={selection.label}
                    />
                  }
                  label={selection.label}
                />
              );
            })}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default PropertyFilter;
