import { SyntheticEvent, useEffect, useState } from "react";
// import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { updateUserAccount } from "../../../../store/thunks/userThunk";
import { displayConsoleError } from "../../../../utilities/helperFunctions";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";

interface UpdateUserAccountDialogProps {
  open: boolean;
  handleCloseCancel: (event: any) => void;
  selectedUserDetails: any;
}

const UpdateUserAccountDialog = (props: UpdateUserAccountDialogProps) => {
  const { open, handleCloseCancel, selectedUserDetails } = props;

  const { allAccounts } = useAppSelector((state: any) => state.system);
  const { updateUserAccountLoading } = useAppSelector(
    (state: any) => state.user
  );
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const [enableUpdateUserAccount, setEnablUpdateUserAccount] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<any>();

  const [updateUserAccountError, setUpdateUserAccountError] = useState(false);
  const [updateUserAccountErrorMessage, setUpdateUserAccountErrorMessage] =
    useState("");

  const dispatch = useAppDispatch();

  const handleCloseUpdateUserAccountDialog = (event: any) => {
    setSelectedAccount(null);
    handleCloseCancel(event);
  };

  const submitUpdateUserAccountDialog = () => {
    setUpdateUserAccountError(false);
    setUpdateUserAccountErrorMessage("");
    dispatch(
      updateUserAccount({
        user_id: selectedUserDetails?.user_id,
        account_id: selectedAccount?.account_id,
      })
    )
      .then((updateUserAccountResult) => {
        if (updateUserAccountResult?.payload?.status) {
          handleCloseUpdateUserAccountDialog(
            updateUserAccountResult?.payload?.message
          );
        } else {
          setUpdateUserAccountError(true);
          setUpdateUserAccountErrorMessage(
            "Unable to update user account, Please try again later or contact support"
          );
        }
      })
      .catch((updateUserAccountError) => {
        displayConsoleError("updateUserAccount", updateUserAccountError);
        setUpdateUserAccountError(true);
        setUpdateUserAccountErrorMessage(
          "Unable to update user account, Please try again later or contact support"
        );
      });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} scroll="paper">
      <Spinner loading={updateUserAccountLoading} />
      <Grid container style={{ backgroundColor: "#00B9D1" }}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          style={{ color: "white", fontWeight: "bold" }}
        >
          Update User Account
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseUpdateUserAccountDialog("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent dividers>
        <Grid sx={{ height: "300px" }}>
          <Grid container sx={{ display: "flex" }}>
            <Grid item xs={12} md={6} lg={6} sx={{ padding: "1%" }}>
              <TextField
                id="user_name"
                label="User Name"
                name="user_name"
                value={selectedUserDetails?.user_name}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ padding: "1%" }}>
              <TextField
                id="account"
                label="Current Account"
                name="account"
                value={selectedUserDetails?.account_label}
                sx={{
                  width: "100%",
                  marginBottom: "5px",
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ padding: "1%" }}>
              <Autocomplete
                disablePortal
                options={allAccounts}
                onChange={(_: SyntheticEvent, value: any) => {
                  if (
                    value &&
                    value?.account_id !== selectedUserDetails.account_id
                  ) {
                    setEnablUpdateUserAccount(false);
                  } else {
                    setEnablUpdateUserAccount(true);
                  }
                  setSelectedAccount(value);
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="user_account"
                    name="user_account"
                    label="User Account"
                    error={false}
                  />
                )}
                value={selectedAccount}
              />
            </Grid>
            {updateUserAccountError && (
              <Grid container item xs={12}>
                <Typography sx={{ color: "red" }}>
                  {updateUserAccountErrorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => handleCloseUpdateUserAccountDialog("")}
        >
          Cancel
        </LVLpmThemeButton>
        <LVLpmThemeButton
          variant="contained"
          onClick={() => submitUpdateUserAccountDialog()}
          disabled={enableUpdateUserAccount}
        >
          Submit
        </LVLpmThemeButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUserAccountDialog;
