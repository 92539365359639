import dayjs from "dayjs";

export const dateRangeOverlap = (resRange: any, existingResRange: any) => {
  return (
    (resRange[0] < existingResRange[0] && resRange[1] <= existingResRange[0]) ||
    (resRange[0] >= existingResRange[1] && resRange[1] > existingResRange[1])
  );
};

export const checkInTimeCheck = (checkin: any) => {
  const today = new Date();
  const checkInDate = new Date(checkin);

  if (
    dayjs(today).format("MM-DD-YYYY") ===
    dayjs(checkInDate).format("MM-DD-YYYY")
  ) {
    if (today.getHours() >= 14) {
      return false;
    } else {
      return true;
    }
  } else if (
    dayjs(today).format("MM-DD-YYYY") > dayjs(checkInDate).format("MM-DD-YYYY")
  ) {
    return false;
  } else {
    return true;
  }
};

export const checkOutTimeCheck = (checkout: any) => {
  const today = new Date();
  const checkOutDate = new Date(checkout);

  if (
    dayjs(today).format("MM-DD-YYYY") ===
    dayjs(checkOutDate).format("MM-DD-YYYY")
  ) {
    if (today.getHours() >= 11) {
      return false;
    } else {
      return true;
    }
  } else if (
    dayjs(today).format("MM-DD-YYYY") > dayjs(checkOutDate).format("MM-DD-YYYY")
  ) {
    return false;
  } else {
    return true;
  }
};

export const cancelTimeCheck = (checkin: any) => {
  const today: any = dayjs(new Date());
  const checkingDate: any = dayjs(new Date(checkin));

  const day_to_checking = Math.floor(
    (checkingDate - today) / (1000 * 60 * 60 * 24)
  );
  return day_to_checking;
};

export const bookingTimeCheck = (booking_date: any) => {
  const today: any = dayjs(new Date());
  const bookingDate: any = dayjs(new Date(booking_date));

  const booking_day_checking = Math.floor(
    (today - bookingDate) / (1000 * 60 * 60 * 24)
  );
  return booking_day_checking;
};

export const updateBookingCheckDates = (
  currentDateRange: any,
  newDateRange: any
) => {
  let updateBooking: any = {};

  let current_days_diff = Math.floor(
    (currentDateRange[1] - currentDateRange[0]) / (1000 * 60 * 60 * 24)
  );

  let new_days_diff = Math.floor(
    (newDateRange[1] - newDateRange[0]) / (1000 * 60 * 60 * 24)
  );

  if (current_days_diff === new_days_diff) {
    updateBooking = {
      refund: false,
      charge: false,
      update: true,
      days_difference: 0,
      total_days: new_days_diff,
    };
  } else if (current_days_diff > new_days_diff) {
    updateBooking = {
      refund: true,
      charge: false,
      update: false,
      days_difference: current_days_diff - new_days_diff,
      total_days: new_days_diff,
    };
  } else {
    updateBooking = {
      refund: false,
      charge: true,
      update: false,
      days_difference: new_days_diff - current_days_diff,
      total_days: current_days_diff,
    };
  }

  if (
    currentDateRange[0] <= newDateRange[0] &&
    currentDateRange[1] >= newDateRange[1]
  ) {
    updateBooking = {
      ...updateBooking,
      lead_check: false,
      lead_check_range: [],
    };
  } else if (
    newDateRange[0] <= currentDateRange[0] &&
    newDateRange[1] >= currentDateRange[1]
  ) {
    let temp_lead_check_range: any = [];
    if (
      currentDateRange[0].format("MM-DD-YYYY") !==
      newDateRange[0].format("MM-DD-YYYY")
    ) {
      temp_lead_check_range.push({
        check_in: newDateRange[0],
        check_out: currentDateRange[0],
      });
    }
    if (
      currentDateRange[1].format("MM-DD-YYYY") !==
      newDateRange[1].format("MM-DD-YYYY")
    ) {
      temp_lead_check_range.push({
        check_in: currentDateRange[1],
        check_out: newDateRange[1],
      });
    }
    updateBooking = {
      ...updateBooking,
      lead_check: true,
      lead_check_range: temp_lead_check_range,
    };
  } else if (
    newDateRange[0] < currentDateRange[0] &&
    newDateRange[1] <= currentDateRange[0]
  ) {
    updateBooking = {
      ...updateBooking,
      lead_check: true,
      lead_check_range: [
        {
          check_in: newDateRange[0],
          check_out: newDateRange[1],
        },
      ],
    };
  } else if (
    newDateRange[0] >= currentDateRange[1] &&
    newDateRange[1] > currentDateRange[1]
  ) {
    updateBooking = {
      ...updateBooking,
      lead_check: true,
      lead_check_range: [
        {
          check_in: newDateRange[0],
          check_out: newDateRange[1],
        },
      ],
    };
  } else {
    if (newDateRange[0] < currentDateRange[0]) {
      let temp_days_diff = Math.floor(
        (currentDateRange[0] - newDateRange[0]) / (1000 * 60 * 60 * 24)
      );
      updateBooking = {
        ...updateBooking,
        lead_check: true,
        lead_check_range: [
          {
            check_in: newDateRange[0],
            check_out: currentDateRange[0],
          },
        ],
      };
    } else {
      let temp_days_diff = Math.floor(
        (newDateRange[1] - currentDateRange[1]) / (1000 * 60 * 60 * 24)
      );
      updateBooking = {
        ...updateBooking,
        lead_check: true,
        lead_check_range: [
          {
            check_in: currentDateRange[1],
            check_out: newDateRange[1],
          },
        ],
      };
    }
  }

  return updateBooking;
};

export const processBookingFees = (
  propertyDetails: any,
  nights: number,
  change_fee: boolean
) => {
  let tempBookingFees: any = {};
  let total_price;

  if (propertyDetails.state === "CA") {
    const stay_price = nights * Number(propertyDetails.price_per_day);
    const lvl_fee = Math.round(stay_price * 0.03 * 100) / 100;

    const stay_price_with_fee = stay_price + lvl_fee;

    const taxes = Math.round(stay_price_with_fee * 0.078 * 100) / 100;

    if (change_fee) {
      total_price = Math.round((stay_price_with_fee + taxes + 50) * 100) / 100;
    } else {
      total_price = Math.round((stay_price_with_fee + taxes) * 100) / 100;
    }

    tempBookingFees = {
      lvlpmFee: lvl_fee,
      stayPrice: stay_price,
      taxes: taxes,
      newTotalPrice: total_price,
      pricePerDay: 0,
    };
  } else {
    const price_per_day =
      Math.round(propertyDetails.price_per_day * 1.033 * 100) / 100;

    const stay_price = nights * price_per_day;
    const lvl_fee = Math.round(stay_price * 0.03 * 100) / 100;
    const taxes = Math.round(stay_price * 0.078 * 100) / 100;

    if (change_fee) {
      total_price = Math.round((stay_price + taxes + 50) * 100) / 100;
    } else {
      total_price = Math.round((stay_price + taxes) * 100) / 100;
    }

    tempBookingFees = {
      lvlpmFee: lvl_fee,
      stayPrice: stay_price,
      taxes: taxes,
      newTotalPrice: total_price,
      pricePerDay: price_per_day,
    };
  }

  return tempBookingFees;
};

export const applyCancelationPolicy = (
  change_fee: boolean,
  day_to_checking: any,
  booking_date_check: number,
  total_price: number
) => {
  let total = 0,
    message: string = "",
    refund: string = "",
    policy_fee: string = "";

  let temp_change_fee = false;

  if (day_to_checking >= 30 || booking_date_check < 1) {
    message = `You will be recieving a full refund of ${total_price} within 10 business days as per our change and cancellation policies`;
    total = total_price;
    refund = "full";
  } else if (day_to_checking < 30 && day_to_checking >= 15) {
    total = Math.round(total_price * 0.8 * 100) / 100;

    if (change_fee && total > 50) {
      temp_change_fee = true;
      total = Math.round((total - 50) * 100) / 100;
    }
    refund = "partial";
    policy_fee = "20%";
    message = `You will be recieving a partial refund of ${total} within 10 business days as per our change and cancellation policies`;
  } else if (day_to_checking < 15 && day_to_checking >= 10) {
    total = Math.round(total_price * 0.75 * 100) / 100;
    if (change_fee && total > 50) {
      temp_change_fee = true;
      total = Math.round((total - 50) * 100) / 100;
    }
    refund = "partial";
    policy_fee = "25%";
    message = `You will be recieving a partial refund of ${total} within 10 business days as per our change and cancellation policies`;
  } else if (day_to_checking < 10 && day_to_checking >= 5) {
    total = Math.round(total_price * 0.5 * 100) / 100;
    if (change_fee && total > 50) {
      temp_change_fee = true;
      total = Math.round((total - 50) * 100) / 100;
    }
    refund = "partial";
    policy_fee = "50%";
    message = `You will be recieving a partial refund of ${refund} within 10 business days as per our change and cancellation policies`;
  } else if (day_to_checking < 5) {
    refund = "";
    policy_fee = "100%";
    message = `No refund is available at this time. Please contact out customer service if you have any question or need assistance with the cancellation.`;
  }

  return {
    total_refund: total,
    refund_type: refund,
    policy_fee: policy_fee,
    change_fee: temp_change_fee,
    message: message,
  };
};

export const displayConsoleError = (function_name: string, error: any) => {
  console.log(function_name, error);
};
