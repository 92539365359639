import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Grid, styled, Typography } from "@mui/material";
import Spinner from "../../reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { validatCard } from "../../../resources/payments";
import { processPayment } from "../../../store/thunks/paymentThunk";
import dayjs from "dayjs";
import { LVLpmThemeButton } from "../../../utilities/constants";
import { displayConsoleError } from "../../../utilities/helperFunctions";

interface CheckoutFormProps {
  handleClose: (event: any) => void;
  totalPrice: string;
  taxes: string;
  lvlFee: string;
  stayPrice: string;
  pricePerDayNonCa: string;
  paymentIntentId: string;
  nights: string;
  guests: number;
}

const CheckoutForm = (props: CheckoutFormProps) => {
  const {
    handleClose,
    totalPrice,
    paymentIntentId,
    taxes,
    lvlFee,
    stayPrice,
    pricePerDayNonCa,
    nights,
    guests,
  } = props;
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const { selectedPropertyDetails, currentDestination } = useAppSelector(
    (state: any) => state.listings
  );

  const [submited, setSubmitted] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [address, setAddress] = useState<any>({});

  const [state, setState] = useState<any>({
    cardNumber: "",
    cardExpiration: "",
    cardCvc: "",
    cardName: "",
    cardIssuer: "",
    zipCode: "",
  });

  const [message, setMessage] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { paymentLoading } = useAppSelector((state: any) => state.payments);

  const cardInfoVerification = () => {
    const cardErros = validatCard(state);

    setErrors(cardErros);

    if (cardErros.isValid) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    const check = cardInfoVerification();

    if (check === false) return;

    // dispatch(processPayment({ paymentInfo: paymentPaylod }));

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}`,
        },
        redirect: "if_required",
      })
      .then(function (confirmPyamentResult) {
        if (confirmPyamentResult.error) {
          if (
            confirmPyamentResult.error?.type === "card_error" ||
            confirmPyamentResult.error?.type === "validation_error"
          ) {
            setMessage(confirmPyamentResult.error?.message);
          } else {
            setMessage("Unable to process payment. Please try again later");
          }
        } else {
          const paymentPaylod = {
            user_id: userInfo?.user_id,
            email: userInfo?.email,
            stripe_customer_id: userInfo?.stripe_customer_id,
            property_name: selectedPropertyDetails.property_name,
            property_id: selectedPropertyDetails.property_id,
            hostfully_id: selectedPropertyDetails.hostfully_property_id,
            check_in: dayjs(currentDestination?.checkIn).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            check_out: dayjs(currentDestination?.checkOut).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            cardAddres: address,
            totalPrice: totalPrice,
            taxes: taxes,
            lvlpm_fee: lvlFee,
            price_per_day_ca: pricePerDayNonCa,
            stay_price: stayPrice,
            paymentIntentId: paymentIntentId,
            nights: nights,
            guests: guests,
            stripe_payment_method:
              confirmPyamentResult?.paymentIntent?.payment_method,
          };
          dispatch(processPayment({ ...{ paymentInfo: paymentPaylod } }))
            .then(async (paymentResult: any) => {
              setIsProcessing(false);
            })
            .catch((error) => {
              displayConsoleError("processPayment", error);
            });
        }
        return confirmPyamentResult;
      });
  };

  return (
    <Grid item xs={12} sx={{ padding: "15px" }}>
      <Spinner loading={paymentLoading} />
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <AddressElement
          className={"text-sm text-gray-700 border rounded"}
          options={{ mode: "billing" }}
          onChange={(event) => {
            setAddress(event.value);
            setState((prev: any) => ({
              ...prev,
              cardName: event.value.name ? event.value.name : "",
              zipCode: event.value.address.postal_code
                ? event.value.address.postal_code
                : "",
            }));
          }}
        />
        {submited && !errors?.isValid && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography style={{ color: "red" }}>{errors.message}</Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px 10px 0px",
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={() => handleClose("")}>
            Edit Trip
          </Button>
          <LVLpmThemeButton
            disabled={isProcessing || !stripe || !elements}
            variant="contained"
            onClick={(e) => handleSubmit(e)}
          >
            Confirm Payment
          </LVLpmThemeButton>
        </Grid>
      </form>
    </Grid>
  );
};

export default CheckoutForm;
