import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../../../hooks/hooks";
import { getUserAddress } from "../../../../store/thunks/addressThunk";
import SettingInfoCard from "../../../../components/reusableComponents/settingInfoCard/settingInfoCard";
import AddressAddModal from "./AddressAddModal";
import AddressEditModal from "./AddressEditModal";
import { LVLpmThemeButton } from "../../../../utilities/constants";
import { useJsApiLoader } from "@react-google-maps/api";

interface AddressInfoPageProps {
  addressInfo: any;
  address: boolean;
}

const AddressInfoPage = (props: AddressInfoPageProps) => {
  const { addressInfo, address } = props;
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const { user_id } = userInfo;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBr_kgPXvcnTQ1w4DP_UvSy-fVUa1QuT8s",
    libraries: ["places"],
  });

  return (
    <>
      <>
        {address ? (
          <>
            <Grid
              component="div"
              container
              sx={{
                width: "100%",
                padding: "15px 15px 15px 0 ",
              }}
            >
              <SettingInfoCard
                title="Address 1"
                data={addressInfo?.street_1}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Address 2"
                data={addressInfo?.street_2}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="City"
                data={addressInfo?.city}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="State"
                data={addressInfo?.state}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Zip"
                data={addressInfo?.zip_code}
                type="addressInfo"
                xs={12}
                md={4}
              />
              <SettingInfoCard
                title="Country"
                data={addressInfo?.country}
                type="addressInfo"
                xs={12}
                md={4}
              />
            </Grid>
            <LVLpmThemeButton
              variant="contained"
              color="success"
              onClick={handleOpenEdit}
              sx={{ marginBottom: "20px" }}
            >
              Edit Address
            </LVLpmThemeButton>
          </>
        ) : (
          <Grid item md={8} sm={12}>
            <LVLpmThemeButton
              variant="contained"
              color="success"
              onClick={handleOpenAdd}
              sx={{ marginBottom: "20px" }}
            >
              Add Address
            </LVLpmThemeButton>
          </Grid>
        )}
      </>
      {isLoaded && (
        <AddressAddModal
          open={openAdd}
          handleClose={handleCloseAdd}
          user_id={user_id}
        />
      )}
      <AddressEditModal
        open={openEdit}
        handleClose={handleCloseEdit}
        addressInfo={addressInfo}
      />
    </>
  );
};

export default AddressInfoPage;
