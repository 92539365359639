import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

interface RecommendCardProps {
  image: string;
  location: string;
  title: string;
  description: string;
}

const RecommendCard = (props: RecommendCardProps) => {
  const { image, location, title, description } = props;
  return (
    <Grid container spacing={2} sx={{ paddingRight: "10px" }}>
      <Grid item xs={12} sx={{ paddingBottom: "5px" }}>
        <Card sx={{ borderRadius: "25px" }}>
          <CardMedia sx={{ height: 220 }} image={image} title={image} />
          <CardContent sx={{ height: "150px", overflow: "auto" }}>
            <Typography variant="body2" color="text.secondary">
              {location}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              fontWeight={"bold"}
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Share</Button>
            <Button size="small">Book Now</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RecommendCard;
