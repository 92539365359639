import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createUserAddress } from "../../../../store/thunks/addressThunk";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../hooks/hooks";
import { LVLpmThemeButton } from "../../../../utilities/constants";

interface ModalProps {
  open: boolean;
  handleClose: any;
  user_id: number;
}

const AddressAddModal = (props: ModalProps) => {
  const { open, handleClose, user_id } = props;
  const dispatch = useAppDispatch();
  const initialValues = {
    user_id: user_id,
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
  };
  const [selectedAddress, setSelectedAddress] = useState<any>();

  const onSubmit = (values: any) => {
    dispatch(createUserAddress({ values })).then((res) => {
      alert("New Address Added.");
      location.reload();
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const updateFilterOptions = (filterValue: any) => {
    if (filterValue) {
      const address_details = filterValue?.value?.description
        ?.split(",")
        .map(function (value: any) {
          return value.trim();
        });
      const address_data =
        filterValue?.value?.structured_formatting?.secondary_text
          ?.split(",")
          .map(function (value: any) {
            return value.trim();
          });

      if (address_details.length === 3) {
        if (address_details[2].length === 2) {
          formik.setFieldValue(
            "street_1",
            filterValue?.value?.structured_formatting?.main_text
              ? filterValue?.value?.structured_formatting?.main_text
              : address_details[0]
          );
          formik.setFieldValue(
            "city",
            filterValue?.value?.terms?.length > 3
              ? filterValue?.value?.terms[2]?.value
              : address_data[0]
          );
          formik.setFieldValue(
            "state",
            filterValue?.value?.terms?.length >= 4
              ? filterValue?.value?.terms[3]?.value
              : address_data[2]
          );
          formik.setFieldValue("country", "US");
        } else {
          formik.setFieldValue(
            "street_1",
            filterValue?.value?.structured_formatting?.main_text
              ? filterValue?.value?.structured_formatting?.main_text
              : address_details[0]
          );
          formik.setFieldValue(
            "city",
            address_data === 2 ? address_data[0] : address_details[1]
          );
          formik.setFieldValue("state", "");
          formik.setFieldValue(
            "country",
            address_data === 2 ? address_data[1] : address_details[2]
          );
        }
      } else {
        formik.setFieldValue(
          "street_1",
          filterValue?.value?.structured_formatting?.main_text
            ? filterValue?.value?.structured_formatting?.main_text
            : address_details[0]
            ? address_details[0]
            : filterValue?.value?.description
        );
        formik.setFieldValue("city", address_data[0]);
        formik.setFieldValue(
          "state",
          address_data?.length === 3 ? address_data[1] : ""
        );
        formik.setFieldValue(
          "country",
          address_data?.length === 3 ? address_data[2] : address_data[1]
        );
      }
    }
    setSelectedAddress(filterValue);
    formik.setFieldValue("address", filterValue?.value);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => `${option.label}`,
  });

  const [searchResult, setSearchResult] = useState({
    autocompleteSuggestions: [],
    status: "",
  });
  const google = window.google;
  const service = new google.maps.places.AutocompleteService();
  const sessionToken = useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    [google.maps.places.AutocompleteSessionToken]
  );

  const handlePredictions = (predictions: any, status: any) => {
    if (status === "OK") {
      const autocompleteSuggestions = predictions.map((prediction: any) => {
        return {
          value: prediction,
          label: prediction.description,
        };
      });
      setSearchResult({
        autocompleteSuggestions: autocompleteSuggestions,
        status: "OK",
      });
    } else {
      setSearchResult({
        autocompleteSuggestions: [],
        status: status,
      });
    }
  };

  const handleAddressChange = (filterValue: string) => {
    if (!filterValue || filterValue === "") {
      setSelectedAddress(null);
      setSearchResult({
        autocompleteSuggestions: [],
        status: "",
      });
    } else {
      service.getPlacePredictions(
        {
          input: filterValue,
          sessionToken: sessionToken,
        },
        handlePredictions
      );
    }
  };

  const matchesX = useMediaQuery("(min-width:1100px)");
  const matchesY = useMediaQuery("(min-height:1200px)");

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          overflowY: matchesY && matchesX ? "none" : "scroll",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "65%",
          maxHeight: "55%",
          bgcolor: "background.paper",
          boxShadow: 30,
          p: 0,
          outline: 0,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: "bold",
            padding: "10px 0 10px 10px",
            backgroundColor: "#00B9D1",
            color: "#fff",
            width: "100%",
          }}
        >
          Add Address
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="row" spacing={2} sx={{ padding: "30px" }}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                options={searchResult.autocompleteSuggestions}
                onChange={(_, option) => updateFilterOptions(option)}
                onInputChange={(_, value) => handleAddressChange(value)}
                getOptionLabel={(option) => option.label}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="address"
                    name="Search Address"
                    label="Search Address"
                  />
                )}
                value={selectedAddress}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                type="text"
                id="street_2"
                label="Address 2"
                name="street_2"
                value={formik.values.street_2}
                onChange={formik.handleChange}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            {selectedAddress && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="street_1"
                    label="Address 1"
                    name="street_1"
                    value={formik.values.street_1}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="city"
                    label="City"
                    name="city"
                    variant="outlined"
                    value={formik.values.city}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="state"
                    label="State"
                    name="state"
                    variant="outlined"
                    value={formik.values.state}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="country"
                    label="Country"
                    name="country"
                    variant="outlined"
                    value={formik.values.country}
                    sx={{ width: "100%" }}
                    disabled
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="text"
                    id="zip_code"
                    label="Zipcode"
                    name="zip_code"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ padding: " 30px" }}>
            <LVLpmThemeButton type="submit" variant="contained" color="success">
              Add Address
            </LVLpmThemeButton>
          </Box>
        </form>
      </Grid>
    </Modal>
  );
};

export default AddressAddModal;
