import { Box, Container, Grid, Typography } from "@mui/material";

import LVLpmLogo from "../../assets/logo.jpg";
import Footer from "../../components/commonComponents/footer/footer";

const ContactPage = () => {
  return (
    <>
      <Box sx={{ backgroundColor: "#fff", paddingBottom: "30px" }}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "50px" }}>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img src={LVLpmLogo} style={{ width: "200px" }} />
              </Box>
              <Typography
                variant="body1"
                sx={{ margin: "35px 0 15px 0", fontWeight: "bold" }}
              >
                CANCELLATION & CHANGE POLICY
              </Typography>
              <Box sx={{ display: "flex", marginBottom: "15px" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", marginRight: "10px" }}
                >
                  Effective Date: 09/14/2024
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                At <span style={{ fontWeight: "bold" }}>LVLpm</span>, we
                understand that plans can change. The following Cancellation &
                Change Policy outlines the conditions under which cancellations
                and booking modifications are handled. By making a reservation
                through LVLpm, you agree to the terms outlined below.
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                1. Cancellation Policy
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                If you need to cancel your booking, the amount of your refund
                will depend on the timing of your cancellation in relation to
                your scheduled check-in date. Refunds will be processed as
                follows:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  - Cancellations made 30 days or more before the check-in date:
                </span>{" "}
                Full refund (100% of the booking amount).
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  - Cancellations made between 15 and 29 days before the
                  check-in date:
                </span>{" "}
                Full refund (100% of the booking amount).
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  - Cancellations made between 10 and 14 days before the
                  check-in date:
                </span>{" "}
                Full refund (100% of the booking amount).
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  - Cancellations made less than 5-10 days before the check-in
                  date:
                </span>{" "}
                Full refund (100% of the booking amount).
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  - Cancellations made less than 0-5 days before the check-in
                  date:
                </span>{" "}
                Full refund (100% of the booking amount).
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  1.1 Non-Refundable Fees
                </span>
                <br />
                Certain fees, including but not limited to cleaning fees and
                service fees, may be non-refundable regardless of the
                cancellation date. These fees will be specified at the time of
                booking.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>1.2 Refund Process</span>
                <br />
                Refunds will be processed to the original form of payment within
                [Insert Processing Time, e.g., 7-14 business days] of receiving
                the cancellation request. Processing times may vary depending on
                your bank or payment provider.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>2. Change Policy</span>
                <br />
                Within 24 hours of booking guests may make changes to their
                bookings freely. If you wish to modify your reservation any time
                after the 24 hours, including changing the dates of your stay,
                the following conditions apply:
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- Date Changes:</span>{" "}
                Guests may request to change the dates of their stay, subject to
                property availability and approval by LVLpm or the Host.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>- Change Fee:</span> A{" "}
                <span style={{ fontWeight: "bold" }}>$50 change fee</span> will
                be applied for each modification to the booking dates.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>
                  2.1 Conditions for Changes
                </span>
                <br />- Changes to booking dates are subject to availability and
                may result in changes to the total booking price. Any difference
                in pricing between the original reservation and the new dates
                will be the responsibility of the Guest.
                <br />- Guests must notify LVLpm at least [Insert Timeframe,
                e.g., 10 days] prior to the original check-in date for all
                change requests. If a change request is made within this period
                but is not approved due to lack of availability or other
                restrictions, the Guest will have the option to proceed with the
                original booking or cancel the reservation, subject to the terms
                of the Cancellation Policy.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>3. No-Show Policy</span>
                <br />
                If you fail to check in on your scheduled arrival date without
                prior notice (a "no-show"), the full cost of the booking will be
                charged, and no refunds will be issued.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                <span style={{ fontWeight: "bold" }}>4. Force Majeure</span>
                <br />
                If you fail to check in on your scheduled arrival date without
                prior notice (a "no-show"), the full cost of the booking will be
                charged, and no refunds will be issued.
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "15px" }}>
                In the event of unforeseen circumstances beyond the control of
                the Guest or LVLpm, such as natural disasters, governmental
                restrictions, or other force majeure events that prevent the
                Guest from traveling or staying at the booked property, LVLpm
                may, at its sole discretion, offer alternative solutions,
                including rescheduling or partial refunds. Each case will be
                evaluated individually.
              </Typography>
              <br />
              <br />

              <Typography
                variant="body1"
                sx={{ marginBottom: "15px", fontWeight: "bold" }}
              >
                BY MAKING A RESERVATION THROUGH LVLPM, YOU ACKNOWLEDGE THAT YOU
                HAVE READ, UNDERSTOOD, AND AGREED TO THIS CANCELLATION & CHANGE
                POLICY.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer bg={true} />
    </>
  );
};

export default ContactPage;
