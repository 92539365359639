import AcUnitIcon from "../assets/icons/AirConditioner.png";
import Conditioner from "../assets/icons/Shampoo.png";
import Shampoo from "../assets/icons/Shampoo.png";
import BalconyIcon from "../assets/icons/Balcony.png";
import BathtubIcon from "../assets/icons/Bathtub.png";
import BlenderIcon from "../assets/icons/Blender.png";
import SoapIcon from "../assets/icons/Soap.png";
import CleaningServicesIcon from "../assets/icons/Housekeeping.png";
import CleanHandsIcon from "../assets/icons/Eco-friendly Cleaning.png";
import CoffeeIcon from "../assets/icons/Coffee cup.png";
import CoffeeMakerIcon from "../assets/icons/Coffee Maker.png";
import DeckIcon from "../assets/icons/Dining Table Light.png";
import CableIcon from "../assets/icons/Network Cable.png";
import BedIcon from "../assets/icons/Empty Bed.png";
import FireplaceIcon from "../assets/icons/Ditch.png";
import Stir from "../assets/icons/Stir.png";
import Dishwasher from "../assets/icons/Dishwasher.png";
import Essentials from "../assets/icons/Hamper.png";
import Firstaid from "../assets/icons/Organ Transplantation.png";
import FreeParkingOnPremises from "../assets/icons/Carpark.png";
import Freezer from "../assets/icons/Fridge.png";
import CeilingFan from "../assets/icons/Ceiling Fan on.png";
import HairDryer from "../assets/icons/Hair Dryer.png";
import Hanger from "../assets/icons/Hanger.png";
import Hotwater from "../assets/icons/Hot Springs.png";
import Iron from "../assets/icons/Iron.png";
import Kitchen from "../assets/icons/Kitchen Room.png";
import Microwave from "../assets/icons/Toaster Oven.png";
import Oven from "../assets/icons/Bake.png";
import Dispenser from "../assets/icons/Dispenser.png";
import ShowerGel from "../assets/icons/Tube.png";
import SoundSystem from "../assets/icons/Electronic Music.png";
import Stove from "../assets/icons/Gas Stove.png";
import Toaster from "../assets/icons/Toaster.png";
import TV from "../assets/icons/Retro TV.png";
import Washer from "../assets/icons/Washing.png";
import WineGlasses from "../assets/icons/Champagne.png";
import Backyard from "../assets/icons/Treehouse.png";
import BarbequeUtensils from "../assets/icons/Big Fork.png";
import BbqGrill from "../assets/icons/BBQ Grill.png";
import BeachAccess from "../assets/icons/Beach.png";
import BedLinens from "../assets/icons/Blankie.png";
import Sensor from "../assets/icons/Sensor.png";
import DishesAndSilverware from "../assets/icons/Dishes Shelf.png";
import Dryer from "../assets/icons/Tumble Dryer.png";
import HeatingCentralAir from "../assets/icons/Thermometer Up.png";
import HotWaterKettle from "../assets/icons/Electric Teapot.png";
import LongTermStays from "../assets/icons/Person at Home.png";
import LuggageDropoff from "../assets/icons/Pack Luggage.png";
import OutdoorDining from "../assets/icons/Restaurant Table.png";
import OutdoorFurniture from "../assets/icons/Sun Lounger.png";
import Patio from "../assets/icons/Soil.png";
import Refridgerator from "../assets/icons/Fridge.png";
import SingleHome from "../assets/icons/Country House.png";
import SmokeAlarm from "../assets/icons/Smoke Detector.png";
import Wifi from "../assets/icons/Wi-Fi.png";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import AirIcon from "@mui/icons-material/Air";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AttractionsIcon from "@mui/icons-material/Attractions";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import CribIcon from "@mui/icons-material/Crib";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import WavesIcon from "@mui/icons-material/Waves";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PoolIcon from "@mui/icons-material/Pool";
import TabUnselectedIcon from "@mui/icons-material/TabUnselected";
import AccessibleIcon from "@mui/icons-material/Accessible";
import WaterIcon from "@mui/icons-material/Water";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import CasinoIcon from "@mui/icons-material/Casino";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import TimerIcon from "@mui/icons-material/Timer";
import AlbumIcon from "@mui/icons-material/Album";
import CheckIcon from "@mui/icons-material/Check";
import ToysIcon from "@mui/icons-material/Toys";
import NoStrollerIcon from "@mui/icons-material/NoStroller";
import MovieIcon from "@mui/icons-material/Movie";
import ComputerIcon from "@mui/icons-material/Computer";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import FlatwareIcon from "@mui/icons-material/Flatware";
import NoEncryptionIcon from "@mui/icons-material/NoEncryption";
import DeskIcon from "@mui/icons-material/Desk";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ElevatorIcon from "@mui/icons-material/Elevator";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import EvStationIcon from "@mui/icons-material/EvStation";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FenceIcon from "@mui/icons-material/Fence";
import FireExtinguisherIcon from "../assets/icons/Fire Extinguisher.png";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import KitchenIcon from "@mui/icons-material/Kitchen";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import ExtensionIcon from "@mui/icons-material/Extension";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import HvacIcon from "@mui/icons-material/Hvac";
import HotTubIcon from "@mui/icons-material/HotTub";

export const backendURL =
  process.env.NODE_ENV !== "production" ? "http://localhost:5003" : "";

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const dbKeyConverterToLabel = (value: string) => {
  return value
    .replaceAll("0", " ")
    .replaceAll("1", "/")
    .replaceAll("3", "(")
    .replaceAll("5", ")")
    .replaceAll("7", "&")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const specificationsIcons = {
  a1c: AcUnitIcon,
  air0conditioning: AcUnitIcon,
  air0filter: AcUnitIcon,
  alarm0system: SmokeAlarm,
  allow0children: CleanHandsIcon,
  allow0pets: OutdoorFurniture,
  allows0smoking: SmokeAlarm,
  amenities: SoundSystem,
  baby0bath: CleanHandsIcon,
  baby0high0chair: OutdoorFurniture,
  baby0monitor: LongTermStays,
  baby0travel0bed: LongTermStays,
  babysitter0recommendations: LongTermStays,
  backyard: Backyard,
  baking0sheet: AcUnitIcon,
  balcony: BalconyIcon,
  barbecue0utensils: BarbequeUtensils,
  basketball0court: Stir,
  bathtub: BathtubIcon,
  bbq0grill: BbqGrill,
  beach: Patio,
  beach0access: BeachAccess,
  beach0essentials: BeachAccessIcon,
  beach0front: Patio,
  beach0view: Patio,
  bed0linens: BedLinens,
  bidet: AcUnitIcon,
  bikes: BikeScooterIcon,
  blender: BlenderIcon,
  board0games: CasinoIcon,
  body0soap: SoapIcon,
  bread0maker: BreakfastDiningIcon,
  breakfast1coffee1tea: FreeBreakfastIcon,
  buzzer: SoundSystem,
  carbon0monoxide0alarm: Sensor,
  carbon0monoxide0detector: TimerIcon,
  cd1dvd0player: AlbumIcon,
  ceiling0fan: CeilingFan,
  changing0table: CleanHandsIcon,
  checkin0240hour: BedIcon,
  childcare: LongTermStays,
  children0books7toys: ToysIcon,
  children0dinnerware: ToysIcon,
  children0not0allowed: NoStrollerIcon,
  cinema0room: MovieIcon,
  cleaning0available0during0stay: CleaningServicesIcon,
  cleaning0products: CleanHandsIcon,
  coffee: CoffeeIcon,
  coffee0maker: CoffeeMakerIcon,
  communal0pool: PoolIcon,
  computer0monitor: ComputerIcon,
  concierge: RoomServiceIcon,
  conditioner: Conditioner,
  contactless0checkin: HowToRegIcon,
  cooking0basics: Stir,
  crockery0and0cutlery: FlatwareIcon,
  deadbolt0lock: NoEncryptionIcon,
  dehumidifier: AcUnitIcon,
  desk: DeskIcon,
  desk0chair: ChairAltIcon,
  dining0table: DeckIcon,
  dishes0and0silverware: DishesAndSilverware,
  dishwasher: Dishwasher,
  doorman: MeetingRoomIcon,
  downtown: LocationCityIcon,
  drawers: AcUnitIcon,
  dryer: Dryer,
  drying0rack0for0clothing: LocalLaundryServiceIcon,
  elevator: ElevatorIcon,
  emergency0exit: MedicalServicesIcon,
  enhanced0cleaning: CleaningServicesIcon,
  essentials: Essentials,
  ethernet0connection: CableIcon,
  ev0car0charger: EvStationIcon,
  event0friendly: CelebrationIcon,
  exercise0equipment: FitnessCenterIcon,
  extra0pillows0and0blankets: BedIcon,
  family0friendly: CleanHandsIcon,
  fenced0pool: FenceIcon,
  fenced0yard: FenceIcon,
  fire0extinguisher: FireExtinguisherIcon,
  fire0pit: FireplaceIcon,
  fireplace0guards: AcUnitIcon,
  first0aid0kit: Firstaid,
  free0parking03on0premises5: FreeParkingOnPremises,
  free0parking03on0street5: FreeParkingOnPremises,
  freezer: Freezer,
  fridge: KitchenIcon,
  game0console: VideogameAssetIcon,
  garden0deck: ExtensionIcon,
  gated0property: AssistantDirectionIcon,
  golf0course0front: GolfCourseIcon,
  golf0course0view: GolfCourseIcon,
  gym: FitnessCenterIcon,
  hair0dryer: HairDryer,
  hammock: AcUnitIcon,
  hangers: Hanger,
  has0cat: OutdoorFurniture,
  has0dog: OutdoorFurniture,
  has0other0pet: OutdoorFurniture,
  heated0pool: PoolIcon,
  heating: HvacIcon,
  heating03central0air5: HeatingCentralAir,
  helipad: AcUnitIcon,
  hot0tub: HotTubIcon,
  hot0water: Hotwater,
  hot0water0kettle: HotWaterKettle,
  in0person0checkin: HowToRegIcon,
  indoor0fireplace: AcUnitIcon,
  indoor0pool: AcUnitIcon,
  indoor0pool03seasonal5: AcUnitIcon,
  indoor0pool03year0round5: AcUnitIcon,
  inens0provided: AcUnitIcon,
  infant0not0allowed: AcUnitIcon,
  invoices0issued0on0request: AcUnitIcon,
  ipod0station: AcUnitIcon,
  iron: Iron,
  ironing0facilities: AcUnitIcon,
  jacuzzi: AcUnitIcon,
  kayak: AcUnitIcon,
  kitchen: Kitchen,
  kitchen0island: AcUnitIcon,
  kitchenette: AcUnitIcon,
  lake: AcUnitIcon,
  lake0access: AcUnitIcon,
  lake0front: AcUnitIcon,
  lake0view: AcUnitIcon,
  laptop: AcUnitIcon,
  laundromat0nearby: AcUnitIcon,
  library: AcUnitIcon,
  lock0on0bedroom: AcUnitIcon,
  long0term0stays0allowed: LongTermStays,
  luggage0droppoff0allowed: LuggageDropoff,
  massage0room: AcUnitIcon,
  meal0delivery: AcUnitIcon,
  microwave: Microwave,
  mini0fridge: AcUnitIcon,
  mosquito0net: AcUnitIcon,
  mountain: AcUnitIcon,
  mountain0view: AcUnitIcon,
  near0ocean: AcUnitIcon,
  ocean0front: AcUnitIcon,
  office1desk: AcUnitIcon,
  outdoor0dining0area: OutdoorDining,
  outdoor0furniture: OutdoorFurniture,
  outdoor0kitchen: AcUnitIcon,
  outdoor0lighting: AcUnitIcon,
  outdoor0play0area: AcUnitIcon,
  outdoor0seating: AcUnitIcon,
  outlet0covers: AcUnitIcon,
  oven: Oven,
  pack0n0play0travel0crib: AcUnitIcon,
  paid0parking03Off0premises5: AcUnitIcon,
  paid0parking03On0premises5: AcUnitIcon,
  pans: AcUnitIcon,
  patio: Patio,
  piano: AcUnitIcon,
  ping0pong: AcUnitIcon,
  pool: PoolIcon,
  pool0table: AcUnitIcon,
  pool03seasonal5: AcUnitIcon,
  pool05year0round5: AcUnitIcon,
  pots: AcUnitIcon,
  printer: AcUnitIcon,
  private0entrance: AcUnitIcon,
  property_id: AcUnitIcon,
  rain0shower: AcUnitIcon,
  record0player: AcUnitIcon,
  refridgerator: Refridgerator,
  resort: AcUnitIcon,
  rice0maker: AcUnitIcon,
  river: AcUnitIcon,
  room0darkening0shades: AcUnitIcon,
  rrsort0access: AcUnitIcon,
  ruralTown: AcUnitIcon,
  safe: AcUnitIcon,
  safe0box: AcUnitIcon,
  safety0card: AcUnitIcon,
  sea0view: AcUnitIcon,
  shampoo: Shampoo,
  shower0gel: ShowerGel,
  single0level0home: SingleHome,
  ski0in1ski0out: AcUnitIcon,
  smoke0alarm: SmokeAlarm,
  smoke0detector: AcUnitIcon,
  soccer0field: AcUnitIcon,
  sound0system: SoundSystem,
  stair0gates: AcUnitIcon,
  steam0room: AcUnitIcon,
  stove: Stove,
  surveillance: AcUnitIcon,
  table0corner0guards: AcUnitIcon,
  tennis: AcUnitIcon,
  toaster: Toaster,
  toiletries: AcUnitIcon,
  towels0provided: AcUnitIcon,
  trash0compactor: AcUnitIcon,
  tv: TV,
  uses0disinfectants: AcUnitIcon,
  uses0disinfectants0for0high0touch0surfaces: AcUnitIcon,
  utensils: AcUnitIcon,
  ventilation0fan: AcUnitIcon,
  village: AcUnitIcon,
  washer: Washer,
  washer1dryer0Shared: LocalLaundryServiceIcon,
  water0front: WaterIcon,
  water0kettle: KitesurfingIcon,
  water0view: WaterIcon,
  wheelchair0accessible: AccessibleIcon,
  wifi: Wifi,
  window0guards: TabUnselectedIcon,
  wine0cellar: AcUnitIcon,
  wine0glasses: WineGlasses,
};
