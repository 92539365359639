import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { LVLpmThemeButton } from "../../utilities/constants";

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  comment: string;
}

const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

const ContactPage = () => {
  return (
    <Box>
      <Box
        sx={{
          height: "500px",
          width: "100%",
          position: "absolute",
          top: "0",
          zIndex: "-1",
          backgroundImage: "linear-gradient(#D5EEF5, #D5EEF5,white)",
        }}
      ></Box>
      <Container
        maxWidth="md"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={5} sx={{ margin: "35px auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                sx={{ margin: "0 0 25px 0", fontWeight: "bold" }}
              >
                Contact LVLpm
              </Typography>
              <Typography variant="body1">
                Need to get in touch with us? Either fill out the form, email us
                at help@lvlpm.com, or call us at 213-597-9392.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={10} md={6} sx={{ margin: "auto" }}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                comment: "",
              }}
              onSubmit={async (values: Values) => {
                await sleep(500);
                alert(JSON.stringify(values, null, 2));
              }}
            >
              {(formik) => (
                <Form>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      width: "100%",
                      marginBottom: "15px",
                      backgroundColor: "#fff",
                    }}
                    error={
                      formik.errors.firstName && formik.touched.firstName
                        ? true
                        : false
                    }
                  />

                  <TextField
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      width: "100%",
                      marginBottom: "15px",
                      backgroundColor: "#fff",
                    }}
                    error={
                      formik.errors.lastName && formik.touched.lastName
                        ? true
                        : false
                    }
                  />

                  <TextField
                    id="email"
                    label="E-mail"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      width: "100%",
                      marginBottom: "15px",
                      backgroundColor: "#fff",
                    }}
                    error={
                      formik.errors.email && formik.touched.email ? true : false
                    }
                  />
                  <TextField
                    id="comment"
                    label="Comment"
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      width: "100%",
                      marginBottom: "15px",
                      backgroundColor: "#fff",
                    }}
                    error={
                      formik.errors.comment && formik.touched.comment
                        ? true
                        : false
                    }
                    multiline
                    maxRows={4}
                    minRows={3}
                  />

                  <LVLpmThemeButton type="submit" variant="contained" fullWidth>
                    Submit
                  </LVLpmThemeButton>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactPage;
