import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Grid, Link } from "@mui/material";
import Chip from "@mui/material/Chip";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import RatingStar from "../ratingStar/ratingStar";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { getPropertyImages } from "../../../store/thunks/propertyThunk";
import { createUserMessageList } from "../../../store/thunks/messagesThunk";

import dayjs from "dayjs";
import { setCreatedUserMessageList } from "../../../store";
import { displayConsoleError } from "../../../utilities/helperFunctions";

interface RenterCardProps {
  color: string;
  bookingDetails: any;
  key: any;
}

const RenterCard = (props: RenterCardProps) => {
  const { bookingDetails, color } = props;

  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [propertyImage, setPropertyImage] = React.useState("");

  useEffect(() => {
    dispatch(
      getPropertyImages({ property_id: bookingDetails?.property_id, count: 1 })
    )
      .then((result: any) => {
        if (result.payload?.property_images?.length > 0) {
          setPropertyImage(result.payload?.property_images[0].image_url);
        }
      })
      .catch((error) => {
        displayConsoleError("getPropertyImages", error);
      });
  }, []);

  const messageRenter = () => {
    const temp_mesage_list_payload = {
      subtitle: bookingDetails?.property_name,
      sender_id: bookingDetails.user_id,
      reciever_id: userInfo.user_id,
    };
    dispatch(
      createUserMessageList({ message_list_payload: temp_mesage_list_payload })
    )
      .then((result: any) => {
        if (result?.payload?.message_list) {
          dispatch(setCreatedUserMessageList(result?.payload?.message_list));
        }
        navigate("/messages");
      })
      .catch((error) => {
        displayConsoleError("messageHost", error);
        navigate("/messages");
      });
  };

  return (
    <Grid item xs={6} md={4} xl={3}>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "450px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={4} sx={{ padding: "14px 14px 0 14px" }}>
            <CardMedia
              component="img"
              image={propertyImage}
              alt={propertyImage}
              sx={{
                width: "100%",
                height: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} sx={{}}>
            <CardContent sx={{ paddingLeft: "0px" }}>
              <Typography
                gutterBottom
                variant="h5"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                {bookingDetails?.property_name}
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                sx={{ fontWeight: "bold", fontSize: "12px" }}
              >
                Guest:{" "}
                {`${bookingDetails?.first_name}, ${bookingDetails?.last_name}`}
              </Typography>
              {/* <RatingStar rating={rating} /> */}
              <Box
                style={{
                  display: "flex",
                  margin: "10px 0px",
                }}
              >
                <Chip
                  size="small"
                  label={dayjs(bookingDetails?.check_in).format("MMM DD, YYYY")}
                  sx={{ fontSize: "10px" }}
                />
                <Chip
                  size="small"
                  sx={{ fontSize: "10px" }}
                  label={dayjs(bookingDetails?.check_out).format(
                    "MMM DD, YYYY"
                  )}
                />
              </Box>
              <Typography
                gutterBottom
                variant="caption"
                component="div"
                style={{ display: "flex", alignItems: "center", color: color }}
              >
                <FlashOnIcon style={{}} />

                <Link
                  component="button"
                  variant="body2"
                  onClick={() => messageRenter()}
                  sx={{
                    textDecoration: "none",
                    color: color,
                  }}
                >
                  Message Guest
                </Link>
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default RenterCard;
