import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import AccountDrawer from "../../components/commonComponents/accountDrawer/accountDrawer";
import OverviewCard from "./OverviewCard";
import GrossProfit from "../../assets/icons/grossProfit.png";
import Expenses from "../../assets/icons/expenses.png";
import NetProfit from "../../assets/icons/netProfit.png";
import RevenueChart from "./RevenueChart";
import ProgressChart from "./ProgressChart";
import GuestListChart from "./GuestListChart";
import BookStatCard from "./BookStatPieChart";
import OccupancyRatesPieChart from "./OccupancyRatesPieChart";

const Reports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { loading, error, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);
  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ margin: "0 15px 0 80px" }}>
      <AccountDrawer handleDrawer={handleDrawer} open={open} />
      <Grid container>
        <Grid item xs={12} sx={{ padding: "20px 0 0 0" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            Reports
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Overview
          </Typography>
        </Grid>
        <OverviewCard
          title="Gross Profit"
          icon={GrossProfit}
          profit="$11,098.88"
        />
        <OverviewCard title="Expenses" icon={Expenses} profit="$2,234.45" />
        <OverviewCard title="Net Profit" icon={NetProfit} profit="$8,864.43" />
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Revenue
          </Typography>
          <RevenueChart />
        </Grid>
        <Grid item xs={12} md={4}>
          <ProgressChart title="ADR" data="$285" value={25} />
          <ProgressChart title="Occupancy Rate" data="62%" value={62} />
          <ProgressChart title="Cancellation Rate" data="13%" value={13} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
        <Grid item xs={12}>
          <GuestListChart />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
        <Grid item xs={12} md={6}>
          <BookStatCard title={"Booking Statistics"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <OccupancyRatesPieChart title={"Occupancy Rates"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
