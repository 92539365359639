import React, { useState } from "react";
import { FormGroup, FormLabel, TextField } from "@mui/material";
import { Field } from "formik";

const SelectForm = (props: any) => {
  const { label, name, values } = props;

  return (
    <FormGroup sx={{ marginBottom: "20px" }}>
      <FormLabel
        htmlFor={name}
        sx={{ fontWeight: "bold", marginBottom: "10px" }}
      >
        {label}
      </FormLabel>
      <Field
        as="select"
        id={name}
        name={name}
        style={{
          border: "none",
          borderBottom: "1px solid #CCC",
          padding: "10px 0 5px 0",
          fontSize: "1rem",
          outline: "none",
        }}
      >
        <option value=""></option>
        {values.map((value: any, i: any) => (
          <option
            key={i}
            value={
              value.property_type_name || value.country_id || value.state_code
            }
          >
            {value.label || value.country_name || value.state_name}
          </option>
        ))}
      </Field>
    </FormGroup>
  );
};

export default SelectForm;
