import { Button, Grid, styled, Typography } from "@mui/material";

export const prices = [
  { label: "Below $50", min: 0, max: 50 },
  { label: "From $50 to $99", min: 50, max: 100 },
  { label: "From $100 to $200", min: 100, max: 200 },
  { label: "Above $200", min: 200, max: 10000000 },
];

export const LVLpmThemeButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  color: "white",
  backgroundColor: "#00B9D1",
  "&:hover": {
    backgroundColor: "#0094a7",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#00a6bc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export const LVLpmThemeButtonOther = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#00B9D1",
  "&:hover": {
    backgroundColor: "#0094a7",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#00a6bc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  width: "100%",
  height: "55px",
  marginTop: "10px",
});
