import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import SpecificInfoForm from "./SpecificInfoForm";
import PropertyAmenities from "./PropertyAmenities";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import FormControl from "./FormControl";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { LVLpmThemeButton } from "../../utilities/constants";
import {
  addOwnerProperty,
  uploadPropertyImages,
} from "../../store/thunks/propertyThunk";
import LVLpmButton from "../../components/reusableComponents/LVLpmButton/LVLpmButton";
import { displayConsoleError } from "../../utilities/helperFunctions";
import { dbKeyConverterToLabel } from "../../utilities/utils";

const steps = [
  "Basic Information",
  "Specific Information",
  "Property Amenities",
  "Property Images",
];

interface HorizontalLinearStepperProps {
  handleOnClose: any;
}

export default function HorizontalLinearStepper(
  prop: HorizontalLinearStepperProps
) {
  const { handleOnClose } = prop;
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const {
    allPropertyRoomTypes,
    allPropertyListingTypes,
    addPropertyLoading,
    propertySpecificationCulumn,
  } = useAppSelector((state: any) => state.property);
  const { allPropertyTypes } = useAppSelector((state: any) => state.listings);
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const [imageList, setImageList] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [searchResult, setSearchResult] = useState({
    autocompleteSuggestions: [],
    status: "",
  });
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const service = new google.maps.places.AutocompleteService();
  const sessionToken = useMemo(
    () => new google.maps.places.AutocompleteSessionToken(),
    [google.maps.places.AutocompleteSessionToken]
  );

  const handleNext = (e: any) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const closeCreateListing = () => {
    handleOnClose();
  };

  const propertySchema = Yup.object().shape({
    property_name: Yup.string()
      .min(5, "Property name Must be at least 5 characters.")
      .max(100, "Property name Must be at most 100 characters.")
      .required("Property Name is required."),
    property_type: Yup.object().required("Property Type is required."),
    property_listing_type: Yup.object().required(
      "Property Listing Type is required."
    ),
    property_room_type: Yup.object().required(
      "Property Room Type is required."
    ),
    street_1: Yup.string().min(1).max(50).required("Please enter valid street"),
    city: Yup.string().required("Please select city"),
    country: Yup.string().required("Please select a country"),
    description: Yup.string().required("Description is required"),
    nightlyBasePrice: Yup.number()
      .min(1, "Nightly rate need to be higher than 0")
      .required("Nightly rate need to be higher than 0"),
    images: Yup.mixed().required("At least one Image is required"),
    property_specification: Yup.mixed().required(
      "At least one Amenity is required"
    ),
  });

  const propertyInitialValues = {
    user_id: userInfo?.user_id,
    property_name: "",
    property_type: undefined,
    property_listing_type: undefined,
    property_room_type: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
    street_1: "",
    street_2: "",
    zip_code: "",
    description: "",
    nightlyBasePrice: 0,
    securityDeposit: 0,
    cleaningFee: 0,
    minStay: 0,
    maxGuests: 0,
    extraGuestFee: 0,
    bedrooms: 0,
    bathrooms: 0,
    numOfBeds: 0,
    airbnbId: "",
    externalId: "",
    instant_booking: false,
    rules: "",
    extra: "",
    property_specification: undefined,
    images: undefined,
    wifi_name: "",
    wifi_password: "",
  };

  const checkForm = (formValues: any, formErrors: any) => {
    if (activeStep === steps.length - 1) {
      const errorkeys = Object.keys(formErrors);

      if (errorkeys.length > 0) {
        if (errorkeys.includes("property_name")) {
          setErrorMessage(formErrors.property_name);
        } else if (errorkeys.includes("description")) {
          setErrorMessage(formErrors.description);
        } else if (errorkeys.includes("property_type")) {
          setErrorMessage(formErrors.property_type);
        } else if (errorkeys.includes("property_listing_type")) {
          setErrorMessage(formErrors.property_listing_type);
        } else if (errorkeys.includes("property_room_type")) {
          setErrorMessage(formErrors.property_room_type);
        } else if (errorkeys.includes("country")) {
          setErrorMessage(formErrors.country);
        } else if (errorkeys.includes("city")) {
          setErrorMessage(formErrors.city);
        } else if (errorkeys.includes("street_1")) {
          setErrorMessage(formErrors.street_1);
        } else if (errorkeys.includes("nightlyBasePrice")) {
          setErrorMessage(formErrors.nightlyBasePrice);
        } else if (errorkeys.includes("images")) {
          setErrorMessage(formErrors.images);
        } else if (errorkeys.includes("property_specification")) {
          setErrorMessage(formErrors.property_specification);
        } else if (formValues?.wifi) {
          if (formValues?.wifi_name === "") {
            setErrorMessage("Wifi SSID Name is required");
          } else if (formValues?.wifi_password === "") {
            setErrorMessage("Wifi Password is required");
          } else {
            setErrorMessage("");
          }
        } else {
          setErrorMessage("");
        }
      } else {
        if (
          formValues?.property_name === "" ||
          formValues?.property_name.length < 5 ||
          formValues?.property_name.length > 100
        ) {
          setErrorMessage(
            "Property Name is required, or too short or too long"
          );
        } else if (!formValues?.property_type) {
          setErrorMessage("Property Type is required");
        } else if (!formValues?.property_listing_type) {
          setErrorMessage("Listing Type is required");
        } else if (!formValues.property_room_type) {
          setErrorMessage("Room Type is required");
        } else if (!formValues.country) {
          setErrorMessage("Country is required");
        } else if (!formValues.city) {
          setErrorMessage("City is required");
        } else if (formValues?.street_1 === "") {
          setErrorMessage("Address 1 is required");
        } else if (formValues?.nightlyBasePrice <= 0) {
          setErrorMessage("Nightly rate must be greater than 0");
        } else if (formValues?.images.length === 0) {
          setErrorMessage("Minimum of one image is required");
        } else if (formValues?.property_specification.length === 0) {
          setErrorMessage("At least One amenitie is requires");
        } else if (formValues?.wifi) {
          if (formValues?.wifi_name === "") {
            setErrorMessage("Wifi SSID Name is required");
          } else if (formValues?.wifi_password === "") {
            setErrorMessage("Wifi password is required");
          } else {
            setErrorMessage("");
          }
        } else {
          setErrorMessage("");
        }
      }
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => `${option.label}`,
  });

  const handlePredictions = (predictions: any, status: any) => {
    if (status === "OK") {
      const autocompleteSuggestions = predictions.map((prediction: any) => {
        return {
          value: prediction,
          label: prediction.description,
        };
      });
      setSearchResult({
        autocompleteSuggestions: autocompleteSuggestions,
        status: "OK",
      });
    } else {
      setSearchResult({
        autocompleteSuggestions: [],
        status: status,
      });
    }
  };

  const handleAddressChange = (filterValue: string) => {
    if (!filterValue || filterValue === "") {
      setSelectedAddress(null);
      setSearchResult({
        autocompleteSuggestions: [],
        status: "",
      });
    } else {
      service.getPlacePredictions(
        {
          input: filterValue,
          sessionToken: sessionToken,
        },
        handlePredictions
      );
    }
  };

  const [specificationColumn, setSpecificationColumn] = useState<any[]>([]);

  useEffect(() => {
    let tempColumn: any[] = [];
    if (propertySpecificationCulumn) {
      propertySpecificationCulumn?.forEach((element: any) => {
        if (
          !["property_specification_id", "property_id"].includes(
            element?.column_name
          )
        ) {
          tempColumn.push({
            label: dbKeyConverterToLabel(element?.column_name),
            value: element?.column_name,
          });
        }
      });
      setSpecificationColumn(tempColumn);
    } else {
      setSpecificationColumn([]);
    }
  }, [propertySpecificationCulumn]);

  return (
    <Formik
      initialValues={propertyInitialValues}
      validateOnChange={true}
      validationSchema={propertySchema}
      onSubmit={(values: any) => {
        if (errorMessage !== "") {
          return;
        }

        dispatch(addOwnerProperty({ values }))
          .then((propertyResult: any) => {
            if (propertyResult?.payload?.status) {
              dispatch(
                uploadPropertyImages({
                  images: values.images,
                  property_id: propertyResult?.payload?.property_id,
                })
              )
                .then((imageUploadResult: any) => {
                  closeCreateListing();
                })
                .catch((err) => displayConsoleError("addOwnerProperty", err));
            } else {
              displayConsoleError("addOwnerProperty", propertyResult?.payload);
              setAddErrorMessage(
                `${propertyResult?.payload?.msg} : ${propertyResult?.payload?.error?.detail}`
              );
            }
          })
          .catch((err) => {
            displayConsoleError("addOwnerProperty", err);
            setAddErrorMessage("Server Error Please try again later");
          });
      }}
    >
      {(formik) => {
        checkForm(formik.values, formik.errors);
        const updateFilterOptions = (filterValue: any) => {
          if (filterValue) {
            const address_details = filterValue?.value?.description
              ?.split(",")
              .map(function (value: any) {
                return value.trim();
              });
            const address_data =
              filterValue?.value?.structured_formatting?.secondary_text
                ?.split(",")
                .map(function (value: any) {
                  return value.trim();
                });

            if (address_details.length === 3) {
              if (address_details[2].length === 2) {
                formik.setFieldValue(
                  "street_1",
                  filterValue?.value?.structured_formatting?.main_text
                    ? filterValue?.value?.structured_formatting?.main_text
                    : address_details[0]
                );
                formik.setFieldValue(
                  "city",
                  filterValue?.value?.terms?.length > 3
                    ? filterValue?.value?.terms[2]?.value
                    : address_data[0]
                );
                formik.setFieldValue(
                  "state",
                  filterValue?.value?.terms?.length >= 4
                    ? filterValue?.value?.terms[3]?.value
                    : address_data[2]
                );
                formik.setFieldValue("country", "US");
              } else {
                formik.setFieldValue(
                  "street_1",
                  filterValue?.value?.structured_formatting?.main_text
                    ? filterValue?.value?.structured_formatting?.main_text
                    : address_details[0]
                );
                formik.setFieldValue(
                  "city",
                  address_data === 2 ? address_data[0] : address_details[1]
                );
                formik.setFieldValue("state", "");
                formik.setFieldValue(
                  "country",
                  address_data === 2 ? address_data[1] : address_details[2]
                );
              }
            } else {
              formik.setFieldValue(
                "street_1",
                filterValue?.value?.structured_formatting?.main_text
                  ? filterValue?.value?.structured_formatting?.main_text
                  : address_details[0]
                  ? address_details[0]
                  : filterValue?.value?.description
              );
              formik.setFieldValue("city", address_data[0]);
              formik.setFieldValue(
                "state",
                address_data?.length === 3 ? address_data[1] : ""
              );
              formik.setFieldValue(
                "country",
                address_data?.length === 3 ? address_data[2] : address_data[1]
              );
            }
          }
          setSelectedAddress(filterValue);
          formik.setFieldValue("address", filterValue?.value);
        };
        return (
          <form onSubmit={formik.handleSubmit}>
            <Spinner loading={addPropertyLoading} />
            <Box sx={{ height: "100%" }}>
              <Stepper activeStep={activeStep} sx={{ marginBottom: "25px" }}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {activeStep === steps.length - 1 ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pt: 2,
                      m: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <label>Property Images</label>
                      <input
                        accept="image/*"
                        type="file"
                        multiple
                        onChange={(event: any) => {
                          const filelist: any[] = Array.from(
                            event.target.files
                          );
                          setImageList(filelist);
                          formik.setFieldValue("images", event.target.files);
                        }}
                      />
                    </Typography>
                    {imageList.length > 0 && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            overflowX: "auto",
                            maxWidth: "100%",
                          }}
                        >
                          {imageList.map((image: any, index: number) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(image)}
                              style={{
                                width: "150px",
                                height: "150px",
                                marginRight: "10px",
                              }}
                            />
                          ))}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            margin: "15px 0 0 0",
                          }}
                        >
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            There are {imageList.length} files ready to be
                            uploaded
                          </Typography>
                          <LVLpmThemeButton
                            onClick={() => {
                              setImageList([]);
                              formik.setFieldValue("images", []);
                            }}
                            sx={{ mr: 1 }}
                            variant="contained"
                          >
                            Remove
                          </LVLpmThemeButton>
                        </Box>
                      </>
                    )}
                  </Box>
                  {/* need to check for wifi */}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      pt: 2,
                      m: 2,
                    }}
                  >
                    <TextField
                      id="wifi_name"
                      label="WIFI SSID Name"
                      name="wifi_name"
                      value={formik.values.wifi_name}
                      onChange={formik.handleChange}
                      sx={{
                        width: "100%",
                        marginBottom: "5px",
                      }}
                      error={
                        formik.errors.wifi_name && formik.touched.wifi_name
                          ? true
                          : false
                      }
                      helperText={
                        formik.errors.wifi_name && formik.touched.wifi_name
                          ? "Wifi SSID Name is required."
                          : ""
                      }
                    />
                    <TextField
                      id="wifi_password"
                      label="WIFI Password"
                      name="wifi_password"
                      value={formik.values.wifi_password}
                      onChange={formik.handleChange}
                      sx={{
                        width: "100%",
                        marginBottom: "5px",
                      }}
                      error={
                        formik.errors.wifi_password &&
                        formik.touched.wifi_password
                          ? true
                          : false
                      }
                      helperText={
                        formik.errors.wifi_password &&
                        formik.touched.wifi_password
                          ? "Wifi Password is required."
                          : ""
                      }
                    />
                  </Box>

                  {errorMessage !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        pt: 2,
                        m: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    </Box>
                  )}
                  {addErrorMessage !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        pt: 2,
                        m: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {addErrorMessage}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                    }}
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <LVLpmThemeButton type="submit" variant="contained">
                      Finish
                    </LVLpmThemeButton>
                  </Box>
                </>
              ) : (
                <>
                  <Grid
                    container
                    spacing={8}
                    sx={{ marginLeft: "0", width: "100%" }}
                  >
                    {activeStep === 0 ? (
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ paddingLeft: "0px!important" }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            marginBottom: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="property_name"
                              label="Property Name"
                              name="property_name"
                              value={formik.values.property_name}
                              onChange={formik.handleChange}
                              sx={{
                                width: "100%",
                                marginBottom: "5px",
                              }}
                              error={
                                formik.errors.property_name &&
                                formik.touched.property_name
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.errors.property_name &&
                                formik.touched.property_name
                                  ? "Property Name is required."
                                  : ""
                              }
                            />
                            <FormControl
                              control="checkbox"
                              type="instant_booking"
                              label="Instant Booking"
                              name="instant_booking"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              options={allPropertyTypes ? allPropertyTypes : []}
                              onChange={(_, value: any) =>
                                formik.setFieldValue("property_type", value)
                              }
                              value={formik.values.property_type}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Property Type"}
                                  id="property_type"
                                  name="property_type"
                                  error={
                                    formik.errors.property_type &&
                                    formik.touched.property_type
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    formik.errors.property_type &&
                                    formik.touched.property_type
                                      ? "Property Type is required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            marginBottom: "25px",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              options={allPropertyListingTypes}
                              onChange={(_, value: any) =>
                                formik.setFieldValue(
                                  "property_listing_type",
                                  value
                                )
                              }
                              value={formik.values.property_listing_type}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Listing Type"}
                                  id="property_listing_type"
                                  name="property_listing_type"
                                  error={
                                    formik.errors.property_listing_type &&
                                    formik.touched.property_listing_type
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    formik.errors.property_listing_type &&
                                    formik.touched.property_listing_type
                                      ? "Listing Type is required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Autocomplete
                              options={allPropertyRoomTypes}
                              onChange={(_, value: any) =>
                                formik.setFieldValue(
                                  "property_room_type",
                                  value
                                )
                              }
                              value={formik.values.property_room_type}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Room Type"}
                                  id="property_room_type"
                                  name="property_room_type"
                                  error={
                                    formik.errors.property_room_type &&
                                    formik.touched.property_room_type
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    formik.errors.property_room_type &&
                                    formik.touched.property_room_type
                                      ? "Room Type is required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              marginBottom: "25px",
                            }}
                          >
                            <Grid item xs={12} md={6}>
                              <Autocomplete
                                fullWidth
                                disablePortal
                                options={searchResult.autocompleteSuggestions}
                                onChange={(_, option) =>
                                  updateFilterOptions(option)
                                }
                                onInputChange={(_, value) =>
                                  handleAddressChange(value)
                                }
                                getOptionLabel={(option) => option.label}
                                filterOptions={filterOptions}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="address"
                                    name="Search Address"
                                    label="Search Address"
                                  />
                                )}
                                value={selectedAddress}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                id="street_2"
                                label="Apt/Unit"
                                name="street_2"
                                value={formik.values.street_2}
                                onChange={formik.handleChange}
                                sx={{
                                  width: "100%",
                                }}
                              />
                            </Grid>
                          </Grid>
                          {selectedAddress && (
                            <>
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginBottom: "25px" }}
                              >
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="street_1"
                                    label="Address 1"
                                    name="street_1"
                                    value={formik.values.street_1}
                                    sx={{ width: "100%" }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="city"
                                    label="City"
                                    name="city"
                                    variant="outlined"
                                    value={formik.values.city}
                                    sx={{ width: "100%" }}
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{ marginBottom: "25px" }}
                              >
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="state"
                                    label="State"
                                    name="state"
                                    variant="outlined"
                                    value={formik.values.state}
                                    sx={{ width: "100%" }}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="country"
                                    label="Country"
                                    name="country"
                                    variant="outlined"
                                    value={formik.values.country}
                                    sx={{ width: "100%" }}
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </>
                      </Grid>
                    ) : activeStep === 1 ? (
                      <SpecificInfoForm />
                    ) : (
                      // <PropertyAmenities />
                      <Grid item xs={12} sx={{ padding: "1%" }}>
                        <Autocomplete
                          multiple
                          options={specificationColumn}
                          onChange={(_, value: any) =>
                            formik.setFieldValue(
                              "property_specification",
                              value
                            )
                          }
                          filterSelectedOptions={true}
                          slotProps={{}}
                          ListboxProps={{}}
                          value={formik.values.property_specification}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ textTransform: "capitalize" }}
                              label={"Select Property Amenities"}
                              id="property_specification"
                              name="property_specification"
                              error={
                                formik.errors.property_specification &&
                                formik.touched.property_specification
                                  ? true
                                  : false
                              }
                              helperText={
                                formik.errors.property_specification &&
                                formik.touched.property_specification
                                  ? "At Least One Property Amenity is required."
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: "auto",
                    }}
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <LVLpmThemeButton onClick={handleNext} variant="contained">
                      Next
                    </LVLpmThemeButton>
                  </Box>
                </>
              )}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}
